import {
    CLEAN_IPB_DIRECTORY_DETAIL,
    CLEAN_IPB_DIRECTORY_DETAIL_ERROR,
} from "_models/redux/types";

export const cleanIpbDirectoryDetailAction = () => async (dispatch) => {
    try {
        dispatch({
            payload: undefined,
            type: CLEAN_IPB_DIRECTORY_DETAIL,
        });
    } catch (e) {
        dispatch({
            payload: undefined,
            type: CLEAN_IPB_DIRECTORY_DETAIL_ERROR,
        });
    }
};
