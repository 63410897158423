import { useEffect, useState } from "react";

// React Hook Form:
import { useForm } from "react-hook-form";

// Helpers:
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";

export const usePortabilityPageHook = ({
    atnPortabilityAction,
    cleanAtnPortabilityAction,
    atnPortability,
}) => {
    const { payload } = atnPortability || {};
    const { rows, pagination } = payload || {};

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (Object.keys(searchParams).length !== 0) {
            setLoading(true);
            atnPortabilityAction(page, size, searchParams)
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        }
        return () => cleanAtnPortabilityAction();
    }, [
        atnPortabilityAction,
        cleanAtnPortabilityAction,
        searchParams,
        page,
        size,
    ]);

    useEffect(() => {
        if (atnPortability?.status === 200) {
            setLoading(false);
        } else if (atnPortability?.payload?.response?.status > 200) {
            setDialogMessage(atnPortability?.payload?.response?.data?.detail);
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [atnPortability]);

    const onSubmit = (data) => {
        const {
            from_date,
            to_date,
            authorization_code,
            masked_pan_termination,
        } = data;

        const areDatesProvided = from_date !== "" && to_date !== "";
        const isAuthorizationOrCardProvided =
            authorization_code !== "" || masked_pan_termination !== "";

        if (areDatesProvided || isAuthorizationOrCardProvided) {
            setLoading(true);
            setPage(1);
            /* eslint-disable no-unused-vars */
            const filteredData = Object.fromEntries(
                Object.entries(data).filter(([_, value]) => value !== "")
            );
            /* eslint-enable no-unused-vars */

            setSearchParams(filteredData);
            setErrorMessage("");
        } else {
            setErrorMessage(
                "Por favor, complete al menos uno de los siguientes campos: autorización, tarjeta o ambas fechas."
            );
        }
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangePageSize = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setSize(newSize);
        setPage(1);
    };

    const mappedRows =
        rows?.map((row) => ({
            ...row,
            id: `${row.id}`,
        })) || [];

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    const Columns = [
        {
            field: "created",
            headerName: "Fecha",
            width: 250,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => {
                return convertToMexicoTime(params.row.created);
            },
        },
        {
            field: "carrier",
            headerName: "Carrier",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "full_name",
            headerName: "Nombre",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "contact_phone",
            headerName: "Teléfono de contacto",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "email",
            headerName: "Correo de contacto",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "current_direct_number",
            headerName: "Número actual",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "number_to_port_in",
            headerName: "Número a portar",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "nip",
            headerName: "NIP",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "imsi_number",
            headerName: "IMSI",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "icc_number",
            headerName: "ICC",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "sim_type",
            headerName: "Tipo de SIM",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "source",
            headerName: "Portal",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
    ];

    return {
        register,
        handleSubmit,
        errors,
        onSubmit,
        loading,
        page,
        size,
        handleChangePage,
        pagination: {
            ...pagination,
            handleChangePage,
            handleChangePageSize,
        },
        rows,
        mappedRows,
        Columns,
        isDialogOpen,
        setIsDialogOpen,
        dialogMessage,
        handleClose,
        errorMessage,
        setErrorMessage,
    };
};
