import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Chip } from "@mui/material";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";
import ActionLabels from "utils/ActionLabelsDatagrid";
import { align } from "@progress/kendo-popup-common";
import EditButton from "utils/ComponentGenericEdit/EditButton";

export const useIpbDirectoryHook = ({
    ipbDirectoryListingAction,
    responseIpbDirectoryListing,
    cleanIpbDirectoryListingAction,
    ipbDirectoryDetailAction,
    cleanIpbDirectoryDetailAction,
    responseIpbDirectoryDetail,
    transactionId,
    onClose,
}) => {
    const { payload } = responseIpbDirectoryListing || {};
    const { response } = responseIpbDirectoryDetail || {};
    const { directory_stores, pagination } = payload || {};

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState(false);
    const [isDialogOpenDetails, setIsDialogOpenDetails] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [action, setAction] = useState(null);
    const [error, setError] = useState(false);
    const [mappedRows, setMappedRows] = useState([]);
    const [submitTriggered, setSubmitTriggered] = useState(false);

    useEffect(() => {
        if (transactionId) {
            // setLoading(true);
            setIsDialogOpenDetails(true);
            ipbDirectoryDetailAction(transactionId);
        }
    }, [transactionId, ipbDirectoryDetailAction, setIsDialogOpenDetails]);

    useEffect(() => {
        if (response?.response?.status > 200) {
            setIsDialogOpenDetails(true);
            setDialogMessage("Error");
            setDialogMessage(response?.response?.data?.detail);
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [response]);

    useEffect(() => {
        // Verificar si searchParams tiene algún valor
        if (Object.keys(searchParams).length !== 0 || submitTriggered) {
            setLoading(true);
            ipbDirectoryListingAction(page, size, searchParams)
                .then(() => setLoading(false))
                .catch(() => {
                    setLoading(false);
                });
        }
        return () => {
            cleanIpbDirectoryListingAction();
        };
    }, [
        page,
        size,
        searchParams,
        ipbDirectoryListingAction,
        cleanIpbDirectoryListingAction,
        submitTriggered,
    ]);

    useEffect(() => {
        if (responseIpbDirectoryListing?.status === 200) {
            setLoading(false);
        } else if (
            responseIpbDirectoryListing?.payload?.response?.status > 200
        ) {
            setDialogMessage(
                responseIpbDirectoryListing?.payload?.response?.data?.detail
            );
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [responseIpbDirectoryListing]);

    const onSubmit = (data) => {
        const {
            from_date,
            to_date,
            authorization_code,
            masked_pan_termination,
        } = data;

        const areDatesProvided = from_date !== "" && to_date !== "";
        const isAuthorizationOrCardProvided =
            authorization_code !== "" || masked_pan_termination !== "";

        if (areDatesProvided || isAuthorizationOrCardProvided) {
            setLoading(true);
            setPage(1);
            const filteredData = Object.fromEntries(
                // eslint-disable-next-line no-unused-vars
                Object.entries(data).filter(([_, value]) => value !== "")
            );
            setSearchParams(filteredData);
            setErrorMessage("");
            setSubmitTriggered(true); // Actualiza el estado para activar el useEffect
        } else {
            setErrorMessage(
                "Por favor, complete al menos uno de los siguientes campos: autorización, tarjeta o ambas fechas."
            );
        }
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangePageSize = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setSize(newSize);
        setPage(1);
    };

    useEffect(() => {
        const updatedRows = directory_stores?.map((row) => ({
            ...row,
            id: `${row.id}`,
        }));
        setMappedRows(updatedRows);
    }, [directory_stores]);

    const handleIdButtonClick = (id) => {
        setIsDialogOpenDetails(true);
        if (id) {
            setIsDialogOpenDetails(true);
            ipbDirectoryDetailAction(id)
                .then(() => setLoading(false))
                .catch(() => {
                    setDialogMessage("Error");
                    setDialogMessage(
                        "Error al cargar los detalles de la transferencia."
                    );
                    setIsDialogOpen(true);
                    setLoading(false);
                });

            return () => {
                cleanIpbDirectoryDetailAction();
            };
        }
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setIsDialogOpenDetails(false);
        cleanIpbDirectoryDetailAction();
        if (transactionId) {
            onClose();
        }
    };

    const getStatusColor = (paymentStatus) => {
        switch (paymentStatus) {
            case "Aprobado":
                return "green";
            case "Rechazada":
                return "red";
            case "Pendiente":
                return "Orange";
            default:
                return "purple";
        }
    };

    const translationMap = {
        Requested: "Transacción registrada",
        Success: "Transacción exitosa",
        Fail: "Falló al procesar el pago",
        Review: "En espera de revisión",
        Error: "Error al procesar el pago",
    };

    const handleOpenPopUp = (action) => {
        setAction(action);
    };

    const handleEdit = (row) => {
        // console.log("Editando fila:", row);
    };

    const Columns = [
        {
            field: " ",
            headerName: " ",
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Button onClick={() => handleIdButtonClick(params.row.id)}>
                    {"Ver detalle"}
                </Button>
            ),
        },
        {
            field: "external_id",
            headerName: "ID externo",
            width: 200,
            align: "right",
            headerAlign: "center",
        },
        {
            field: "store_name",
            headerName: "Nombre de la tienda",
            width: 200,
            headerAlign: "center",
            align: "center",
        },

        {
            field: "state",
            headerName: "Estado",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "municipality",
            headerName: "Municipio",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "review_status",
            headerName: "Estatus",
            width: 220,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Chip
                    label={
                        translationMap[params.row.review_status] ||
                        params.row.review_status
                    }
                    style={{
                        backgroundColor: getStatusColor(
                            params.row.review_status
                        ),
                        color: "white",
                    }}
                />
            ),
        },
        {
            field: "created",
            headerName: "Fecha de Alta",
            width: 250,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                return convertToMexicoTime(params.row.transaction_time);
            },
        },

        {
            field: "actions",
            headerName: "Editar",
            width: 200,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => (
                <EditButton onClick={() => handleEdit(params.row)} />
            ),
        },
    ];

    return {
        register,
        handleSubmit,
        errors,
        onSubmit,
        loading,
        page,
        size,
        handleChangePage,
        pagination: {
            ...pagination,
            handleChangePage,
            handleChangePageSize,
        },
        rows: directory_stores,
        mappedRows,
        Columns,
        isDialogOpen,
        setIsDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        setErrorMessage,
        setError,
        error,
        handleOpenPopUp,
        setLoading,
        action,
    };
};
