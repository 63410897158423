import React from "react";
import { connect } from "react-redux";
import { Grid, CircularProgress } from "@mui/material";
import { municipalityAction } from "_models/redux/sepomex-municipality/action";
import { neighborhoodAction } from "_models/redux/sepomex-neighborhood/action";
import { getOffers } from "_models/redux/offers/action";
import { TranferSaleHook } from "./hooks/tranferSaleHook";
import { transferSalesAction } from "_models/redux/transfer-sales/action";
import { offersForSimPurchaseAction } from "_models/redux/offers-for-sim-purchase/action";
import { transferSalesClean } from "_models/redux/transfer-sales/clean-action";
import {
    loaderContainerStyle,
    containerStyle,
} from "./style/PageTransferSaleStyle";
import Header from "./components/Header";
import TransferSaleForm from "./components/TransferSaleForm";
import MessageDialog from "./components/MessageDialog";

const PageTransferSale = (props) => {
    const {
        registerOptions,
        errors,
        nameShrink,
        contactPhoneShrink,
        emailShrink,
        zipcodeShrink,
        register,
        states,
        municipalities,
        neighborhoods,
        simCount,
        setSimCount,
        eSimCount,
        setESimCount,
        selectedValues,
        setSelectedValues,
        handleAddESim,
        handleRemoveESim,
        handleAddSim,
        handleRemoveSim,
        handleESimChange,
        handleSimChange,
        handleFieldChange,
        generateFields,
        inputChange,
        handleNeighborhoodChange,
        calculateTotal,
        formattedTotal,
        offersData,
        handleAddItem,
        hasSim,
        loading,
        setDialogOpen,
        messageModal,
        dialogOpen,
        isESimOrSimAdded,
        setIsESimOrSimAdded,
        showErrorMessage,
        handleEnviarClick,
        handleGoBack,
        handleAccept,
        referenceInput,
        setReference,
    } = TranferSaleHook({ ...props });

    return (
        <Grid style={containerStyle} xs={12} sm={12} md={12} lg={12}>
            {loading ? (
                <Grid style={loaderContainerStyle}>
                    <CircularProgress />
                </Grid>
            ) : (
                <>
                    <Header handleGoBack={handleGoBack} />
                    <TransferSaleForm
                        handleAddItem={handleAddItem}
                        offersData={offersData}
                        setSimCount={setSimCount}
                        simCount={simCount}
                        eSimCount={eSimCount}
                        setESimCount={setESimCount}
                        selectedValues={selectedValues}
                        setSelectedValues={setSelectedValues}
                        handleAddESim={handleAddESim}
                        handleRemoveESim={handleRemoveESim}
                        handleAddSim={handleAddSim}
                        handleRemoveSim={handleRemoveSim}
                        handleESimChange={handleESimChange}
                        handleSimChange={handleSimChange}
                        handleFieldChange={handleFieldChange}
                        generateFields={generateFields}
                        calculateTotal={calculateTotal}
                        formattedTotal={formattedTotal}
                        inputChange={inputChange}
                        register={register}
                        registerOptions={registerOptions}
                        errors={errors}
                        nameShrink={nameShrink}
                        contactPhoneShrink={contactPhoneShrink}
                        emailShrink={emailShrink}
                        hasSim={hasSim}
                        states={states}
                        municipalities={municipalities}
                        neighborhoods={neighborhoods}
                        zipcodeShrink={zipcodeShrink}
                        handleNeighborhoodChange={handleNeighborhoodChange}
                        referenceInput={referenceInput}
                        setReference={setReference}
                        handleEnviarClick={handleEnviarClick}
                        showErrorMessage={showErrorMessage}
                        isESimOrSimAdded={isESimOrSimAdded}
                        setIsESimOrSimAdded={setIsESimOrSimAdded}
                    />
                    <MessageDialog
                        dialogOpen={dialogOpen}
                        setDialogOpen={setDialogOpen}
                        messageModal={messageModal}
                        handleAccept={handleAccept}
                    />
                </>
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    municipality: state?.municipality,
    neighborhood: state?.neighborhood,
    offers: state?.offers,
    transferSales: state?.transferSales,
    ofersSimPurchase: state?.ofersSimPurchase,
});

const mapDispatchToProps = {
    municipalityAction,
    neighborhoodAction,
    getOffers,
    transferSalesAction,
    offersForSimPurchaseAction,
    transferSalesClean,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageTransferSale);
