import React from "react";
import { TextField, MenuItem } from "@mui/material";

const SelectInput = ({
    label,
    placeholder,
    id,
    name,
    onChange,
    shrink,
    options,
    register,
    registerOptions,
    sx,
}) => {
    return (
        <TextField
            {...register(name, registerOptions)}
            select
            fullWidth
            variant="outlined"
            label={label}
            placeholder={placeholder}
            id={id}
            name={name}
            onChange={onChange}
            InputLabelProps={{
                shrink: shrink,
            }}
            sx={sx}
        >
            <MenuItem key={0} value={0}>
                {"Seleccione un estado"}
            </MenuItem>
            {options?.map((option) => (
                <MenuItem key={option?.id} value={option?.id || 0}>
                    {option?.name}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default SelectInput;
