import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { getLoginDataHelper } from "_controllers/helpers/getLoginData";

export const useDailySalesManagerPageHook = () => {
    const history = useNavigate();

    const permission = "MANAGEMENT_REPORTS";
    // const permission = "MARKETING_REPORTS";
    const { userPermissions } = getLoginDataHelper();

    useEffect(() => {
        if (
            typeof userPermissions
                ?.split(",")
                ?.find((element) => element === permission) === "undefined"
        ) {
            history("/");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { permission, userPermissions };
};
