import { useContext } from "react";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { WeeklySalesContext } from "../data/context";
import { useWeeklySalesReportTableHook } from "../hooks/table-hook";

const WeeklySalesReportTable = () => {
    const weeklySalesContext = useContext(WeeklySalesContext);
    const { states } = weeklySalesContext || {};
    const {
        comparativeHeaders,
        comparativeColumns,
        comparativeRows,
        headers,
        columns,
        rows,
        data,
    } = states;

    const lclStr = "es-MX";
    const rws = data ? Object.keys(data).map((key) => data[key]) : [];

    const {
        StyledTableCell,
        GreenStyledTableCell,
        RedStyledTableCell,
        CustomStyledTableCell1,
        CustomStyledTableCell2,
        StyledTableRow,
    } = useWeeklySalesReportTableHook();

    return (
        <Grid container>
            <Grid xs={12} md={12}>
                <TableContainer className="reportsTableCLS" component={Paper}>
                    <Table
                        stickyHeader
                        sx={{ minWidth: 250 }}
                        aria-label="simple table"
                    >
                        <TableHead>
                            <TableRow key={0}>
                                <CustomStyledTableCell2
                                    key={0}
                                    align="center"
                                    colSpan={1}
                                ></CustomStyledTableCell2>
                                {headers?.map((header, headerIndex) => (
                                    <>
                                        <CustomStyledTableCell2
                                            key={headerIndex}
                                            align="center"
                                            colSpan={3}
                                        >
                                            <nobr>
                                                {header.split("_")[1] +
                                                    " a " +
                                                    header.split("_")[2]}
                                            </nobr>
                                        </CustomStyledTableCell2>
                                    </>
                                ))}

                                {comparativeColumns?.map(
                                    (
                                        comparativeColumns,
                                        comparativeColumnsIndex
                                    ) => (
                                        <CustomStyledTableCell2
                                            key={
                                                "comparative-header-" +
                                                comparativeColumnsIndex
                                            }
                                            align="center"
                                        >
                                            {comparativeHeaders || ""}
                                        </CustomStyledTableCell2>
                                    )
                                )}
                            </TableRow>
                            <TableRow key={1}>
                                <StyledTableCell
                                    key={0}
                                    align={"center"}
                                    style={{ top: "57px" }}
                                >
                                    {"Product"}
                                </StyledTableCell>
                                {headers?.map(() => (
                                    <>
                                        {columns?.map((column, columnIndex) => {
                                            return column.headerName ===
                                                "Total" ? (
                                                <StyledTableCell
                                                    key={columnIndex}
                                                    align={column.headerAlign}
                                                    style={{ top: "57px" }}
                                                >
                                                    {column.headerName}
                                                </StyledTableCell>
                                            ) : (
                                                <StyledTableCell
                                                    key={columnIndex}
                                                    align={column.headerAlign}
                                                    style={{ top: "57px" }}
                                                >
                                                    {column.headerName}
                                                </StyledTableCell>
                                            );
                                        })}
                                    </>
                                ))}

                                {comparativeColumns?.map(
                                    (
                                        comparativeColumn,
                                        comparativeColumnIndex
                                    ) => {
                                        return (
                                            <StyledTableCell
                                                key={
                                                    "comparative-column-" +
                                                    comparativeColumnIndex
                                                }
                                                align={"center"}
                                                style={{
                                                    top: "57px",
                                                    width: "500px",
                                                }}
                                            >
                                                <nobr>
                                                    {
                                                        comparativeColumn
                                                            .split(" / ")[0]
                                                            .split("_")[1]
                                                    }
                                                    {" / "}
                                                    {
                                                        comparativeColumn
                                                            .split(" / ")[1]
                                                            .split("_")[1]
                                                    }
                                                </nobr>
                                            </StyledTableCell>
                                        );
                                    }
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row, rowIndex) => (
                                <StyledTableRow
                                    key={rowIndex}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <CustomStyledTableCell1
                                        component="th"
                                        scope="row"
                                        key={1 + rowIndex}
                                    >
                                        {row}
                                    </CustomStyledTableCell1>

                                    {headers?.map((header) => (
                                        <>
                                            {columns?.map(
                                                (column, columnIndex) => {
                                                    return column.headerName ===
                                                        "eSIM" ? (
                                                        <StyledTableCell
                                                            key={
                                                                1 +
                                                                (rowIndex *
                                                                    columnIndex +
                                                                    columnIndex)
                                                            }
                                                            align={
                                                                column?.align
                                                            }
                                                        >
                                                            {!isNaN(
                                                                parseFloat(
                                                                    rws?.filter(
                                                                        (
                                                                            entry
                                                                        ) =>
                                                                            entry.hName ===
                                                                                header &&
                                                                            entry.rName ===
                                                                                row.replaceAll(
                                                                                    " ",
                                                                                    "_"
                                                                                )
                                                                    )[0]?.esim
                                                                )
                                                            ) ? (
                                                                parseFloat(
                                                                    rws?.filter(
                                                                        (
                                                                            entry
                                                                        ) =>
                                                                            entry.hName ===
                                                                                header &&
                                                                            entry.rName ===
                                                                                row.replaceAll(
                                                                                    " ",
                                                                                    "_"
                                                                                )
                                                                    )[0]?.esim
                                                                ).toLocaleString(
                                                                    lclStr
                                                                )
                                                            ) : (
                                                                <span className="zero-number">
                                                                    0
                                                                </span>
                                                            )}
                                                        </StyledTableCell>
                                                    ) : column.headerName ===
                                                      "SIM" ? (
                                                        <StyledTableCell
                                                            key={
                                                                1 +
                                                                rowIndex +
                                                                columnIndex
                                                            }
                                                            align={
                                                                column?.align
                                                            }
                                                        >
                                                            {!isNaN(
                                                                parseFloat(
                                                                    rws?.filter(
                                                                        (
                                                                            entry
                                                                        ) =>
                                                                            entry.hName ===
                                                                                header &&
                                                                            entry.rName ===
                                                                                row.replaceAll(
                                                                                    " ",
                                                                                    "_"
                                                                                )
                                                                    )[0]?.sim
                                                                )
                                                            ) ? (
                                                                parseFloat(
                                                                    rws?.filter(
                                                                        (
                                                                            entry
                                                                        ) =>
                                                                            entry.hName ===
                                                                                header &&
                                                                            entry.rName ===
                                                                                row.replaceAll(
                                                                                    " ",
                                                                                    "_"
                                                                                )
                                                                    )[0]?.sim
                                                                ).toLocaleString(
                                                                    lclStr
                                                                )
                                                            ) : (
                                                                <span className="zero-number">
                                                                    0
                                                                </span>
                                                            )}
                                                        </StyledTableCell>
                                                    ) : (
                                                        <CustomStyledTableCell1
                                                            key={
                                                                1 +
                                                                rowIndex +
                                                                columnIndex
                                                            }
                                                            align={
                                                                column?.align
                                                            }
                                                        >
                                                            {!isNaN(
                                                                parseFloat(
                                                                    rws?.filter(
                                                                        (
                                                                            entry
                                                                        ) =>
                                                                            entry.hName ===
                                                                                header &&
                                                                            entry.rName ===
                                                                                row.replaceAll(
                                                                                    " ",
                                                                                    "_"
                                                                                )
                                                                    )[0]?.total
                                                                )
                                                            ) ? (
                                                                parseFloat(
                                                                    rws?.filter(
                                                                        (
                                                                            entry
                                                                        ) =>
                                                                            entry.hName ===
                                                                                header &&
                                                                            entry.rName ===
                                                                                row.replaceAll(
                                                                                    " ",
                                                                                    "_"
                                                                                )
                                                                    )[0]?.total
                                                                ).toLocaleString(
                                                                    lclStr
                                                                )
                                                            ) : (
                                                                <span className="zero-number">
                                                                    0
                                                                </span>
                                                            )}
                                                        </CustomStyledTableCell1>
                                                    );
                                                }
                                            )}
                                        </>
                                    ))}

                                    {comparativeRows[rowIndex]?.map(
                                        (item, index) =>
                                            parseFloat(item) > 0 ? (
                                                <GreenStyledTableCell
                                                    key={"comparative-" + index}
                                                    align={"center"}
                                                >
                                                    <ArrowDropUpIcon />
                                                    <b>
                                                        {parseFloat(
                                                            item
                                                        ).toLocaleString(
                                                            lclStr,
                                                            {
                                                                maximumFractionDigits: 1,
                                                            }
                                                        )}
                                                        %
                                                    </b>
                                                </GreenStyledTableCell>
                                            ) : parseFloat(item) === 0 ? (
                                                <StyledTableCell
                                                    key={"comparative-" + index}
                                                    align={"center"}
                                                >
                                                    <b>{parseFloat(item)}%</b>
                                                </StyledTableCell>
                                            ) : (
                                                <RedStyledTableCell
                                                    key={"comparative-" + index}
                                                    align={"center"}
                                                >
                                                    <ArrowDropDownIcon />
                                                    <b>
                                                        {parseFloat(
                                                            item
                                                        ).toLocaleString(
                                                            lclStr,
                                                            {
                                                                maximumFractionDigits: 1,
                                                            }
                                                        )}
                                                        %
                                                    </b>
                                                </RedStyledTableCell>
                                            )
                                    )}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default WeeklySalesReportTable;
