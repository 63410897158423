import React from "react";
import { Card, CardContent, Typography, Box, Grid } from "@mui/material";
import {
    cardStyles,
    gridItemStyles,
    typographyTitleStyles,
} from "../styles/styles";

const PhotoSection = ({ storeData, handleImageClick }) => (
    <Grid item xs={12} sm={6} sx={gridItemStyles}>
        <Card sx={cardStyles}>
            <CardContent>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={typographyTitleStyles}
                >
                    <strong>Fotografía</strong>
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 250,
                        backgroundColor: "#f0f0f0",
                        borderRadius: 2,
                        cursor: "pointer",
                    }}
                    onClick={() => handleImageClick(storeData.media_url)}
                >
                    {storeData.media_url ? (
                        <img
                            src={storeData.media_url}
                            alt="Fotografía de la tienda"
                            style={{
                                maxHeight: "100%",
                                maxWidth: "100%",
                            }}
                        />
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            Sin fotografía
                        </Typography>
                    )}
                </Box>
            </CardContent>
        </Card>
    </Grid>
);

export default PhotoSection;
