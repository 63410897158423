import React from "react";
import { Grid, Typography } from "@mui/material";
import { getTransactionDetailsSTP } from "./data/DetailsSTP";
import { getTransactionDetailsToku } from "./data/DetailsToku";
import { getSecurityDetails } from "./data/DetailsNetPay";
import { getAdvancedTransactionDetails } from "./data/DetailsHistoryPrepaid";
import { getDateDetails } from "./data/DetailsSimIpb";
import { getDeviceDetails } from "./data/DetailPurchase";
import SimTypeChip from "utils/SimTypeChip";
import { getTransactionDetails } from "./data/DetailsOpenPay";

const titleStyle = { color: "#7A3C92" };
const spanStyle = { fontSize: 15, wordWrap: "break-word" };
const valueStyle = {
    textAlign: "right",
    marginTop: "8px",
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

const OrderAdditionalInfo = ({ orderDetails, title, type, labelSimType }) => {
    let items = [];

    if (type === "detailsSTP") {
        items = getTransactionDetailsSTP(orderDetails);
    } else if (type === "detailsToku") {
        items = getTransactionDetailsToku(orderDetails);
    } else if (type === "detailsNetPay") {
        items = getSecurityDetails(orderDetails);
    } else if (type === "detailsHistoryPrepaid") {
        items = getAdvancedTransactionDetails(orderDetails);
    } else if (type === "detailsSimIpb") {
        items = getDateDetails(orderDetails);
    } else if (type === "detailPurchase") {
        items = getDeviceDetails(orderDetails);
    } else if (type === "detailsOpenPay") {
        items = getTransactionDetails(orderDetails);
    }

    return (
        <Grid container className="device-info">
            <Grid item xs={12}>
                <span style={titleStyle}>{title}</span>
            </Grid>
            {items.map((item, index) => (
                <Grid container key={index}>
                    <Grid item xs={6}>
                        <span style={spanStyle}>{item.label}:</span>
                    </Grid>
                    <Grid item xs={6} style={valueStyle}>
                        <Typography variant="body1">
                            {item.value || "N/A"}
                        </Typography>
                    </Grid>
                </Grid>
            ))}
            {type === "detailPurchase" && (
                <Grid container>
                    <Grid item xs={6}>
                        <span style={spanStyle}>Tipo de SIM:</span>
                    </Grid>
                    <Grid item xs={6} style={valueStyle}>
                        <Typography variant="body1">
                            <SimTypeChip
                                simTypeName={Number(labelSimType?.sim_type_id)}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default OrderAdditionalInfo;
