import { useContext } from "react";

import { Grid } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";

import WeeklySalesReportContent from "./content-component";

import { WeeklySalesContext } from "../data/context";
import { useWeeklySalesReportHook } from "../hooks/hook";

const WeeklySalesReportComponent = () => {
    useWeeklySalesReportHook();
    const weeklySalesContext = useContext(WeeklySalesContext);
    const { redux, states } = weeklySalesContext || {};
    const { getSalesWeekly } = redux || {};
    const { noDataForGrid } = states;

    return (
        <Grid container spacing={5}>
            {getSalesWeekly.loaded === true && noDataForGrid === false ? (
                <WeeklySalesReportContent />
            ) : noDataForGrid ? (
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    height={345}
                >
                    <div className="kpi-empty">
                        <p>
                            <BarChartIcon />{" "}
                        </p>
                        <p>No contiene datos, por favor intenta otra fecha</p>
                    </div>
                </Grid>
            ) : (
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    height={363}
                >
                    <div className="kpi-empty">
                        <p>
                            <BarChartIcon />{" "}
                        </p>
                        <p>Selecciona una fecha para búsqueda de datos</p>
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

export default WeeklySalesReportComponent;
