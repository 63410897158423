import React from "react";
import { Box, Link } from "@mui/material";
import pdfIcon from "assets/images/generate-invoice/pdf.png";
import xmlIcon from "assets/images/generate-invoice/xml.png";

const DownloadLinks = ({ pdf_url, xml_url }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row", // Cambiado de "column" a "row"
                gap: 2, // Ajusta el espacio entre los enlaces si es necesario
                justifyContent: "center", // Centra los enlaces horizontalmente
            }}
        >
            <Link
                href={pdf_url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                }}
            >
                <img src={pdfIcon} alt="PDF Icon" width={80} />
                Descargar PDF
            </Link>
            <Link
                href={xml_url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                }}
            >
                <img src={xmlIcon} alt="XML Icon" width={80} />
                Descargar XML
            </Link>
        </Box>
    );
};

export default DownloadLinks;
