import React from "react";
import CustomDialog from "widgets/component/CustomDialog";
import { Typography } from "@mui/material";

const CustomDialogComponent = ({
    isCustomDialogOpen,
    dialogMessage,
    setIsCustomDialogOpen,
    onClose,
}) => {
    const handleClose = () => {
        setIsCustomDialogOpen(false);
        if (typeof onClose === "function") {
            onClose();
        }
    };

    return (
        isCustomDialogOpen && (
            <CustomDialog
                content={<Typography>{dialogMessage}</Typography>}
                onClose={handleClose}
                open={isCustomDialogOpen}
                title="Información"
            />
        )
    );
};

export default CustomDialogComponent;
