import {
    CLEAN_IPB_DIRECTORY_MODERATION_DETAIL,
    CLEAN_IPB_DIRECTORY_MODERATION_DETAIL_ERROR,
} from "_models/redux/types";

export const cleanIpbDirectoryModerationDetailAction =
    () => async (dispatch) => {
        try {
            dispatch({
                payload: undefined,
                type: CLEAN_IPB_DIRECTORY_MODERATION_DETAIL,
            });
        } catch {
            dispatch({
                payload: undefined,
                type: CLEAN_IPB_DIRECTORY_MODERATION_DETAIL_ERROR,
            });
        }
    };
