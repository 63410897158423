import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Header = ({ handleGoBack }) => (
    <Grid container xs={12} sm={12} md={12} lg={12}>
        <Grid item>
            <IconButton onClick={handleGoBack}>
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5">Agregar Venta de Transferencia</Typography>
        </Grid>
    </Grid>
);

export default Header;
