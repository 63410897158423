import React from "react";
import { Grid, Card, CardContent, Button, Typography } from "@mui/material";
import SimForm from "./SimForm";
import ClientForm from "./ClientForm";
import AddressForm from "./AddressForm";
import { cardStyle } from "../../detail-transfer-sales/style/style";

const TransferSaleForm = ({
    handleAddItem,
    offersData,
    setSimCount,
    simCount,
    eSimCount,
    setESimCount,
    selectedValues,
    setSelectedValues,
    handleAddESim,
    handleRemoveESim,
    handleAddSim,
    handleRemoveSim,
    handleESimChange,
    handleSimChange,
    handleFieldChange,
    generateFields,
    calculateTotal,
    formattedTotal,
    inputChange,
    register,
    registerOptions,
    errors,
    nameShrink,
    contactPhoneShrink,
    emailShrink,
    hasSim,
    states,
    municipalities,
    neighborhoods,
    zipcodeShrink,
    handleNeighborhoodChange,
    referenceInput,
    setReference,
    handleEnviarClick,
    showErrorMessage,
    isESimOrSimAdded,
    setIsESimOrSimAdded,
}) => (
    <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={7}>
                <Card variant="outlined" sx={cardStyle}>
                    <CardContent>
                        <SimForm
                            handleAddItem={(type) => {
                                handleAddItem(type);
                                setIsESimOrSimAdded(true);
                            }}
                            offersData={offersData}
                            setSimCount={setSimCount}
                            simCount={simCount}
                            eSimCount={eSimCount}
                            setESimCount={setESimCount}
                            selectedValues={selectedValues}
                            setSelectedValues={setSelectedValues}
                            handleAddESim={handleAddESim}
                            handleRemoveESim={handleRemoveESim}
                            handleAddSim={handleAddSim}
                            handleRemoveSim={handleRemoveSim}
                            handleESimChange={handleESimChange}
                            handleSimChange={handleSimChange}
                            handleFieldChange={handleFieldChange}
                            generateFields={generateFields}
                            calculateTotal={calculateTotal}
                            totalCost={formattedTotal}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={5}>
                <Card variant="outlined" sx={cardStyle}>
                    <CardContent>
                        <ClientForm
                            inputChange={inputChange}
                            register={register}
                            registerOptions={registerOptions}
                            errors={errors}
                            nameShrink={nameShrink}
                            contactPhoneShrink={contactPhoneShrink}
                            emailShrink={emailShrink}
                        />
                    </CardContent>
                </Card>
                {hasSim && (
                    <Card
                        variant="outlined"
                        sx={cardStyle}
                        style={{ marginTop: 20 }}
                    >
                        <CardContent>
                            <AddressForm
                                inputChange={inputChange}
                                register={register}
                                registerOptions={registerOptions}
                                errors={errors}
                                states={states}
                                municipalities={municipalities}
                                neighborhoods={neighborhoods}
                                zipcodeShrink={zipcodeShrink}
                                handleNeighborhoodChange={
                                    handleNeighborhoodChange
                                }
                                referenceInput={referenceInput}
                                setReference={setReference}
                            />
                        </CardContent>
                    </Card>
                )}
            </Grid>
        </Grid>
        <Grid container justifyContent="center" style={{ marginTop: 20 }}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleEnviarClick}
            >
                Enviar
            </Button>
        </Grid>
        {showErrorMessage && !isESimOrSimAdded && (
            <Typography
                variant="body2"
                color="error"
                style={{ marginLeft: 10 }}
            >
                Es necesario agregar al menos una eSIM o SIM
            </Typography>
        )}
    </>
);

export default TransferSaleForm;
