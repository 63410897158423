import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { cardStyles, typographyTitleStyles } from "../styles/styles";

const ContactInfo = ({ storeData }) => (
    <Grid item xs={12} sm={6}>
        <Card sx={cardStyles}>
            <CardContent>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={typographyTitleStyles}
                >
                    <strong>Contacto</strong>
                </Typography>
                <Typography variant="body1">
                    <strong>Teléfono:</strong> {storeData.phone_number}
                </Typography>
                <Typography variant="body1">
                    <strong>Correo:</strong> {storeData.email}
                </Typography>
            </CardContent>
        </Card>
    </Grid>
);

export default ContactInfo;
