import React from "react";
import { connect } from "react-redux";
import { Box, Grid } from "@mui/material";
import { useIpbDirectoryModeration } from "./hooks/useIpbDirectoryModeration";
import { ipbDirectoryModerationListingAction } from "_models/redux/ipb-moderacion-listing/action";
import { cleanIpbDirectoryModerationListingAction } from "_models/redux/ipb-moderacion-listing/clean";
import { ipbDirectoryModerationDetailgAction } from "_models/redux/ipb-moderacion-detail/action";
import { cleanIpbDirectoryModerationDetailAction } from "_models/redux/ipb-moderacion-detail/clean";
import { handleStoreModerationClean } from "_models/redux/handle-store-moderation/clean";
import { handleStoreModerationAction } from "_models/redux/handle-store-moderation/action";
import DataTableSection from "./components/DataTableSection";
import StoreDetailsDialog from "./components/StoreDetailsDialog";
import ConfirmationDialog from "./components/ConfirmationDialog";
import MessageConfirmationDialog from "./components/MessageConfirmationDialog";

const IpbModerationListingPage = ({
    ipbDirectoryModerationListingAction,
    responseIpbDirectoryModerationListing,
    cleanIpbDirectoryModerationListingAction,
    ipbDirectoryModerationDetailgAction,
    cleanIpbDirectoryModerationDetailAction,
    responseIpbDirectoryModerationDetail,
    handleStoreModerationAction,
    responseIpbDirectoryModeration,
    handleStoreModerationClean,
    transactionId,
    onClose,
    showExtraButtons = true,
}) => {
    const {
        loading,
        page,
        size,
        pagination,
        mappedRows,
        handleRowClick,
        Columns,
        handleClose,
        isDialogOpenDetails,
        handleRejectStore,
        handleAcceptStore,
        isCustomDialogOpen,
        dialogContent,
        dialogTitle,
        handleConfirm,
        setIsCustomDialogOpen,
        dialogButtonLabel,
        dialogButtonColor,
        rejectionReason,
        isRejecting,
        dialogLoading,
        control,
        handleSubmit,
        isOpenSuccesOrError,
        setOpenSuccesOrError,
        setRejectionReason,
        reset,
    } = useIpbDirectoryModeration({
        ipbDirectoryModerationListingAction,
        responseIpbDirectoryModerationListing,
        cleanIpbDirectoryModerationListingAction,
        ipbDirectoryModerationDetailgAction,
        cleanIpbDirectoryModerationDetailAction,
        responseIpbDirectoryModerationDetail,
        handleStoreModerationAction,
        responseIpbDirectoryModeration,
        handleStoreModerationClean,
        transactionId,
        onClose,
    });

    return (
        <Box>
            <Grid>
                <h4>Moderación IPB</h4>
            </Grid>
            <DataTableSection
                loading={loading}
                mappedRows={mappedRows}
                Columns={Columns}
                page={page}
                size={size}
                pagination={pagination}
                handleRowClick={handleRowClick}
            />
            <StoreDetailsDialog
                handleStoreModerationClean={handleStoreModerationClean}
                isRejecting={isRejecting}
                ActionStore={responseIpbDirectoryModeration?.payload?.message}
                loading={loading}
                responseIpbDirectoryModerationDetail={
                    responseIpbDirectoryModerationDetail
                }
                isDialogOpenDetails={isDialogOpenDetails}
                handleClose={handleClose}
                showExtraButtons={showExtraButtons}
                handleRejectStore={handleRejectStore}
                handleAcceptStore={handleAcceptStore}
            />
            <ConfirmationDialog
                dialogTitle={dialogTitle}
                dialogContent={dialogContent}
                isRejecting={isRejecting}
                control={control}
                rejectionReason={rejectionReason}
                dialogLoading={dialogLoading}
                isCustomDialogOpen={isCustomDialogOpen}
                setIsCustomDialogOpen={setIsCustomDialogOpen}
                dialogButtonLabel={dialogButtonLabel}
                dialogButtonColor={dialogButtonColor}
                handleSubmit={handleSubmit}
                handleConfirm={handleConfirm}
                setRejectionReason={setRejectionReason}
                reset={reset}
            />
            {responseIpbDirectoryModeration?.payload?.message === "success" && (
                <MessageConfirmationDialog
                    isRejecting={isRejecting}
                    storeName={
                        responseIpbDirectoryModerationDetail?.response
                            ?.store_name
                    }
                    message={
                        responseIpbDirectoryModeration?.payload?.response?.data
                            ?.detail
                    }
                    setOpenSuccesOrError={setOpenSuccesOrError}
                    dialogTitle={dialogTitle}
                    dialogContent={dialogContent}
                    isOpenSuccesOrError={isOpenSuccesOrError}
                    status={responseIpbDirectoryModeration.state}
                />
            )}
        </Box>
    );
};

const mapStateToProps = (state) => ({
    responseIpbDirectoryModerationListing:
        state?.responseIpbDirectoryModerationListing,
    responseIpbDirectoryModerationDetail:
        state?.responseIpbDirectoryModerationDetail,
    responseIpbDirectoryModeration: state.responseIpbDirectoryModeration,
});

const mapDispatchToProps = {
    ipbDirectoryModerationListingAction,
    cleanIpbDirectoryModerationListingAction,
    ipbDirectoryModerationDetailgAction,
    cleanIpbDirectoryModerationDetailAction,
    handleStoreModerationAction,
    handleStoreModerationClean,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IpbModerationListingPage);
