import axiosTokenInstance from "services/api/backoffice";
import {
    IPB_DIRECTORY_DETAIL,
    IPB_DIRECTORY_DETAIL_ERROR,
} from "_models/redux/types";

// Action function
export const ipbDirectoryDetailAction = (id) => async (dispatch) => {
    try {
        const response = await axiosTokenInstance.get(
            `/api/ipb/directory-store/details/${id}`
        );

        const { data, status } = response || {};

        dispatch({
            type: IPB_DIRECTORY_DETAIL,
            payload: data || {},
            status: status || {},
            method: "get",
        });
    } catch (e) {
        dispatch({
            type: IPB_DIRECTORY_DETAIL_ERROR,
            payload: e || {},
        });
    }
};
