import SummarySection from "modules/my-sales/submodules/orders/list/components/SummarySection";
import React from "react";
const SummarySectionWrapper = ({ type, controllerProps }) => (
    <>
        {type === "detailPurchase" && (
            <SummarySection
                purchase={controllerProps.purchase}
                onClose={controllerProps.onClose}
                product={controllerProps.product}
                payment={controllerProps.payment}
                order_details={controllerProps.order_details}
                getEsimQr={controllerProps.getEsimQr}
                handleOpenDialog={controllerProps.handleOpenDialog}
                handleRequest={controllerProps.handleRequest}
                loading={controllerProps.loading}
                isDialogOpen={controllerProps.isDialogOpen}
                handleCloseDialog={controllerProps.handleCloseDialog}
                handleSubmit={controllerProps.handleSubmit}
                handleCopyToClipboard={controllerProps.handleCopyToClipboard}
                register={controllerProps.register}
                registerOptions={controllerProps.registerOptions}
                description={controllerProps.description}
                handleDescriptionChange={
                    controllerProps.handleDescriptionChange
                }
                errors={controllerProps.errors}
                newOne={controllerProps.newOne}
                newTwo={controllerProps.newTwo}
                setValue={controllerProps.setValue}
                inputChange={controllerProps.inputChange}
                stateShrink={controllerProps.stateShrink}
                municipalityShrink={controllerProps.municipalityShrink}
                neighborhoodShrink={controllerProps.neighborhoodShrink}
                states={controllerProps.states}
                municipalities={controllerProps.municipalities}
                neighborhoods={controllerProps.neighborhoods}
                zipcodeShrink={controllerProps.zipcodeShrink}
                addressShrink={controllerProps.addressShrink}
                referenceInput={controllerProps.referenceInput}
                contactPhoneShrink={controllerProps.contactPhoneShrink}
                isCustomDialogOpen={controllerProps.isCustomDialogOpen}
                dialogMessage={controllerProps.dialogMessage}
                setIsCustomDialogOpen={controllerProps.setIsCustomDialogOpen}
                getSaleOrderDetailAction={
                    controllerProps.getSaleOrderDetailAction
                }
                simOrderId={controllerProps.simOrderId}
            />
        )}
    </>
);
export default SummarySectionWrapper;
