import axiosTokenInstance from "services/api/backoffice";
import { EDIT_LEGAL_DATA, EDIT_LEGAL_DATA_ERROR } from "_models/redux/types";

// Action function for editing legal data
export const postEditLegalData = (params) => async (dispatch) => {
    try {
        const response = await axiosTokenInstance.patch(
            `api/backoffice/accounting/edit-legal-data`,
            params
        );

        const { data, status } = response || {};

        dispatch({
            type: EDIT_LEGAL_DATA,
            payload: data || {},
            status: status || {},
            method: "patch",
        });
    } catch (e) {
        dispatch({
            type: EDIT_LEGAL_DATA_ERROR,
            payload: e || {},
        });
    }
};
