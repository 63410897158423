import React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";

const Filters = ({ options, dropdownValue, inputChange }) => (
    <Grid container className="my-sales container-1">
        <Grid item xs={12} className={"filters-container"}>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    xl={6}
                    className={"filter-container"}
                >
                    <TextField
                        variant="standard"
                        select
                        fullWidth
                        label="*Fecha"
                        placeholder="Seleccionar"
                        id="monthFilter"
                        name="monthFilter"
                        value={dropdownValue || ""}
                        onChange={(e) => inputChange(e)}
                        className="m-tb-20 form-control customDropdown"
                        sx={{
                            mb: 2,
                            "& .MuiOutlinedInput-root:hover": {
                                "& > fieldset": {
                                    borderColor: "#6C63FF",
                                },
                            },
                            background: "white",
                        }}
                    >
                        {options?.map((option) => (
                            <MenuItem
                                key={option?.id}
                                value={option?.id}
                                className="customDropdownItem"
                            >
                                {option?.name} {option?.year}
                            </MenuItem>
                        ))}
                        <MenuItem key={1} value={1}>
                            {"Personalizado"}
                        </MenuItem>
                    </TextField>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);

export default Filters;
