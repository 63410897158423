import React from "react";
import {
    SupervisorAccountOutlined as SupervisorAccountOutlinedIcon,
    CreditCardOutlined as CreditCardOutlinedIcon,
    CurrencyExchangeOutlined as CurrencyExchangeOutlinedIcon,
    AccountBalanceOutlined as AccountBalanceOutlinedIcon,
} from "@mui/icons-material";
import GenericCollapse from "./GenericCollapse";
import GenericNavListItem from "./GenericNavListItem";
import GenericListItemButton from "./MenuItemWithSubmenu";

const MtcenterMenu = ({
    userPermissions,
    openMenuMTCenter,
    handleOpenMTCenter,
    activeStyle,
}) => {
    return (
        <>
            {userPermissions?.includes("LIST_PAYMENTS") && (
                <>
                    <GenericListItemButton
                        onClick={handleOpenMTCenter}
                        className={"layout-menu-submenu"}
                        title="Directorio IPB"
                        IconComponent={SupervisorAccountOutlinedIcon}
                        openMenu={openMenuMTCenter}
                    />
                    <GenericCollapse
                        className={"layout-menu-submenu-options"}
                        openMenu={openMenuMTCenter}
                        userPermissions={userPermissions}
                    >
                        {userPermissions?.includes("LIST_PAYMENTS") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="LIST_PAYMENTS"
                                to="/listado/tiendas"
                                title="Tiendas IPB"
                                IconComponent={AccountBalanceOutlinedIcon}
                                activeStyle={activeStyle}
                            />
                        )}

                        {userPermissions?.includes("LIST_PAYMENTS") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="LIST_PAYMENTS"
                                to="/listado/moderacion"
                                title="Moderación"
                                IconComponent={AccountBalanceOutlinedIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                    </GenericCollapse>
                </>
            )}
        </>
    );
};

export default MtcenterMenu;
