import React from "react";
import OrderInfoGrid from "./OrderInfo";

const OrderDetailsSection = ({ type, orderDetails }) => (
    <OrderInfoGrid
        title={
            type === "detailsHistoryPrepaid"
                ? "Detalles del Producto"
                : type === "detailsSimIpb"
                  ? "Detalles del Producto"
                  : "Beneficiario"
        }
        type={type}
        orderDetails={orderDetails}
    />
);

export default OrderDetailsSection;
