import React, { useEffect } from "react";
import { connect } from "react-redux";
import HelpdeskHeader from "./widgets/header";
import HelpdeskDatagrid from "./widgets/datagrid";

const HelpdeskListPage = () => {
    useEffect(() => {}, []);

    return (
        <div>
            <HelpdeskHeader />
            <HelpdeskDatagrid />
        </div>
    );
};

const mapStateToProps = (state) => ({ tickets: state.tickets });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HelpdeskListPage);
