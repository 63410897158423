// Import: Middleware Action & Custom Types
import {
    CLEAN_GENERAL_TICKETS,
    CLEAN_GENERAL_TICKETS_ERROR,
} from "_models/redux/types";

// Action function
export const cleanGeneralTicketsAction = () => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        dispatch({
            payload: undefined,
            type: CLEAN_GENERAL_TICKETS,
        });
    } catch (e) {
        dispatch({
            payload: undefined,
            type: CLEAN_GENERAL_TICKETS_ERROR,
        });
    }
};
