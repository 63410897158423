export const getLoginDataHelper = () => {
    const userToken = localStorage.getItem("user_token");
    const userExpiresAt = localStorage.getItem("user_expires_at");
    const userRefreshToken = localStorage.getItem("user_refresh_token");
    const userId = localStorage.getItem("user_id");
    const userLogged = localStorage.getItem("user_logged");
    const userMessage = localStorage.getItem("user_message");
    const userPermissions = localStorage.getItem("user_permissions");

    const params = {
        token: userToken,
        expires_at: userExpiresAt,
        refresh_token: userRefreshToken,
        user_id: userId,
        userMessage,
        userLogged,
        userPermissions,
    };

    return params;
};
