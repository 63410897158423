import React from "react";
import {
    TextField,
    MenuItem,
    Grid,
    Select,
    InputLabel,
    FormControl,
} from "@mui/material";
import GenericAddButton from "widgets/GenericAddButton/GenericAddButton";
import SearchIcon from "@mui/icons-material/Search";
import { getStaticStates } from "_models/data/states";

const FiltroMTCenter = ({ handleSubmit, onSubmit, register, errors }) => {
    const { states } = getStaticStates();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} xl={1}>
                    <TextField
                        fullWidth
                        label="Desde"
                        type="date"
                        {...register("from_date")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={Boolean(errors.from_date)}
                        helperText={errors.from_date?.message}
                    />
                </Grid>
                <Grid item xs={12} xl={1}>
                    <TextField
                        fullWidth
                        label="Hasta"
                        type="date"
                        {...register("to_date")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={Boolean(errors.to_date)}
                        helperText={errors.to_date?.message}
                    />
                </Grid>

                <Grid item xs={12} xl={2}>
                    <TextField
                        fullWidth
                        label="Nombre de la tienda"
                        {...register("store_name")}
                        error={Boolean(errors.authorization_code)}
                        helperText={errors.authorization_code?.message}
                    />
                </Grid>
                <Grid item xs={12} xl={1}>
                    <TextField
                        fullWidth
                        label="Correo electrónico"
                        type="email"
                        {...register("email")}
                        error={Boolean(errors.amount)}
                        helperText={errors.amount?.message}
                        onWheel={(e) => e.target.blur()}
                    />
                </Grid>
                <Grid item xs={12} xl={1}>
                    <TextField
                        fullWidth
                        label="Teléfono"
                        type="number"
                        {...register("phone_number")}
                        error={Boolean(errors.amount)}
                        helperText={errors.amount?.message}
                        onWheel={(e) => e.target.blur()}
                    />
                </Grid>
                <Grid item xs={12} xl={1}>
                    <TextField
                        fullWidth
                        label="ID externo"
                        {...register("external_id")}
                        error={Boolean(errors.authorization_code)}
                        helperText={errors.authorization_code?.message}
                    />
                </Grid>
                <Grid item xs={12} xl={1}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Status
                        </InputLabel>
                        <Select
                            fullWidth
                            defaultValue=""
                            {...register("review_status_id")}
                            error={Boolean(errors.status_id)}
                            helperText={errors.status_id?.message}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={1}>Pendiente</MenuItem>
                            <MenuItem value={2}>Aprobado</MenuItem>
                            <MenuItem value={3}>Rechazado</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} xl={1}>
                    <FormControl fullWidth>
                        <InputLabel id="estado-select-label">Estado</InputLabel>
                        <Select
                            fullWidth
                            defaultValue=""
                            {...register("state")}
                            error={Boolean(errors.estado_id)}
                            helperText={errors.estado_id?.message}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {states.map((state) => (
                                <MenuItem key={state.id} value={state.name}>
                                    {state.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} xl={1}>
                    <GenericAddButton
                        buttonText="Buscar"
                        backgroundColor="#4C2484"
                        size="medium"
                        isSubmit
                        icon={<SearchIcon />}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default FiltroMTCenter;
