import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

export const TicketStatusHook = (props) => {
    const { getStatusList, ticketById, statusList, changeStatusTicket } = props;
    const [value, setValue] = useState(2);
    const [customerStatus, setCustomerStatus] = useState([]);
    const [ticketId, setTicketId] = useState();
    const params = useParams();

    useEffect(() => {
        getStatusList();
        setTicketId(params.ticketId);
    }, [getStatusList, params.ticketId]);

    useEffect(() => {
        const response = ticketById.response || undefined;
        if (typeof response !== "undefined") {
            setValue(response.status_id);
        }
    }, [ticketById]);

    useEffect(() => {
        const { response } = statusList || { response: undefined };
        const status = response.status || undefined;

        if (typeof status !== "undefined") {
            setCustomerStatus(status);
        }
    }, [statusList]);

    const handleChange = (event) => {
        setValue(event.target.value);
        changeStatusTicket({
            ticketId: ticketId,
            statusId: event.target.value,
        });
    };

    return {
        value,
        customerStatus,
        handleChange,
    };
};
