import { useContext } from "react";
import { GiftOrdersContext } from "../../data/giftOrdersContexts";
import { useGiftOrdersListEffect } from "./useGiftOrdersListEffect";
import { giftOrdersDatagridColumns } from "modules/support-gift-orders/data/giftOrdersDatagridColumns";

export const useGiftOrdersList = () => {
    // Obtener el contexto del módulo y sus propiedades
    const giftOrdersContext = useContext(GiftOrdersContext);

    // Desestructuración de las propiedades del contexto
    const { reduxProps, reducerProps } = giftOrdersContext || {};

    // Desestructuración de las propiedades del Redux
    const { getGiftOrders, getGiftOrdersDetailAction } = reduxProps || {};
    const { response } = getGiftOrders || {};
    const { result } = response || {};

    // Desestructuración de las propiedades del Reducer
    const { giftOrdersReducer, dispatchGiftOrdersReducer } = reducerProps || {};
    const {
        rows,
        rowCount,
        columns,

        datagridPage,
        datagridPageSize,
        datagridPageCounter,

        loadingDatagrid,
    } = giftOrdersReducer;

    // LIST Datagrid Methods:

    // Función que cambia la página del datagrid
    const changePage = (newPage) => {
        dispatchGiftOrdersReducer({
            property: "datagridPage",
            value: newPage,
        });
    };

    // Función que cambia el tamaño de la página (número de rows x pág.) del datagrid
    const changePageSize = (newPageSize) => {
        dispatchGiftOrdersReducer({
            property: "datagridPageCounter",
            value: {
                dpCounter: datagridPageCounter?.dpCounter + 1,
                npSize: newPageSize,
            },
        });
    };

    // Función que se llamará al dar clic al detalle de una orden de regalo
    const handleActionOrderDetail = (order_id) => {
        getGiftOrdersDetailAction({ order_id });

        dispatchGiftOrdersReducer({
            property: "loadingDatagrid",
            value: false,
        });
    };

    // Función que genera las columnas del datagrid
    const generateDatagridColumns = () => {
        const giftOrdersCols = giftOrdersDatagridColumns({
            handleActionOrderDetail,
        });

        dispatchGiftOrdersReducer({
            property: "columns",
            value: giftOrdersCols,
        });
    };

    // Función que genera las filas del datagrid
    const generateDatagridRows = () => {
        const giftOrdersRows = [];

        if (result) {
            result?.forEach((element) => {
                giftOrdersRows.push(element);
            });
        }

        dispatchGiftOrdersReducer({
            property: "rows",
            value: giftOrdersRows,
        });
    };

    // Objeto con las propiedades de paginación del datagrid
    const giftOrdersDatagridValues = {
        changePage,
        changePageSize,
        datagridPageSize,
        page: datagridPage,
        paginationState: {
            paginationModel: {
                page: 0,
                pages: 3,
                pageSize: datagridPageSize,
                rowCount: rowCount,
            },
        },
    };

    // LIST Datagrid UseEffects:
    // Hook que se encarga de manejar la funcionalidad con base a los cambios de estados:
    // @param {Object} generateDatagridColumns - Función para definir las columnas del datagrid
    // @param {Object} generateDatagridRows - Función para definir las filas del datagrid
    useGiftOrdersListEffect({
        generateDatagridColumns,
        generateDatagridRows,
    });

    // Propiedades / variables / estados y métodos / funciones que se usarán en los componentes
    return {
        rows,
        rowCount,
        columns,

        datagridPage,
        datagridPageSize,
        datagridPageCounter,

        loadingDatagrid,

        changePage,
        changePageSize,

        giftOrdersDatagridValues,
    };
};
