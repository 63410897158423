import React from "react";
import { Grid } from "@mui/material";
import PreventionSection from "modules/my-sales/submodules/orders/detailOrderPurchase/components/PreventionSection";
import FraudDialog from "modules/my-sales/submodules/orders/list/components/FraudDialog";
import FraudPrevention from "modules/my-sales/submodules/orders/list/components/FraudPrevention";

const FraudSection = ({
    type,
    orderDetails,
    controllerProps,
    fraudStyle,
    chargebackStyle,
    handleOpenPopUp,
}) => (
    <>
        {type !== "detailsSimIpb" && type !== "detailPurchase" && (
            <PreventionSection
                type={type}
                title={"Prevención"}
                orderDetails={orderDetails}
                handleOpenPopUp={handleOpenPopUp}
                fraudStyle={fraudStyle}
                chargebackStyle={chargebackStyle}
            />
        )}
        {type === "detailPurchase" && (
            <Grid item xs={6}>
                <FraudPrevention
                    order_details={orderDetails}
                    handleOpenPopUp={controllerProps.handleOpenPopUp}
                    fraudStyle={fraudStyle}
                    chargebackStyle={chargebackStyle}
                />
                <FraudDialog
                    openActionFraud={controllerProps.openActionFraud}
                    handleClose={controllerProps.handleClose}
                    fraudChargebackPopupTitle={
                        controllerProps.fraudChargebackPopupTitle
                    }
                    setDescriptionTransaction={
                        controllerProps.setDescriptionTransaction
                    }
                    setError={controllerProps.setError}
                    error={controllerProps.error}
                    loadingFraudAndChargeback={
                        controllerProps.loadingFraudAndChargeback
                    }
                    dialogMessage={controllerProps.dialogMessage}
                    isError={controllerProps.isError}
                    handleConfirm={controllerProps.handleConfirm}
                    detailTransactionChargeback={
                        controllerProps.detailTransactionChargeback
                    }
                    detailTransactionFraud={
                        controllerProps.detailTransactionFraud
                    }
                />
            </Grid>
        )}
    </>
);
export default FraudSection;
