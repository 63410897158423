import { useContext } from "react";
import { DailySalesContext } from "../data/context";

export const useDailySalesManagerChartHook = () => {
    const dailySalesContext = useContext(DailySalesContext);
    const { states } = dailySalesContext || {};
    const { data } = states;

    const dataArray = data ? Object.keys(data).map((key) => data[key]) : [];

    const productChart = [];
    const dateChart = [];

    productChart.push("fecha");

    dataArray?.map((item) => {
        if (productChart.indexOf(item?.rName) === -1) {
            productChart.push(item?.rName);
        }

        if (dateChart.indexOf(item?.hName) === -1) {
            dateChart.push(item?.hName);
        }

        return true;
    });

    const resultChart = [];
    const productChartLabels = productChart?.map((element) =>
        element.replaceAll("_", " ")
    );

    resultChart.push(productChartLabels);
    dateChart?.map((dItem) => {
        const rowChart = [];

        rowChart.push(dItem);
        productChart?.map((pItem, pIndex) => {
            if (pIndex > 0) {
                const rowChartItem = dataArray?.find(
                    (element) =>
                        element.rName === pItem && element.hName === dItem
                );

                rowChart.push(rowChartItem?.total || 0);
            }

            return true;
        });

        resultChart.push(rowChart);
        return true;
    });

    const optionsGrid = {
        height: 500,
        title: "Ventas por día",
    };

    return { resultChart, optionsGrid };
};
