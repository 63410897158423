// styles.js
export const containerStyle = {
    maxWidth: "sm",
};

export const loadingBoxStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
};

export const formBoxStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    padding: 3,
    border: "1px solid #ccc",
    borderRadius: "10px",
    backgroundColor: "#f9f9f9",
    marginTop: 4,
};

export const successBoxStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    padding: 3,
    border: "1px solid #ccc",
    borderRadius: "10px",
    backgroundColor: "#f9f9f9",
    marginTop: 4,
};

export const errorMessageStyle = {
    marginTop: 2,
    color: "red",
};
