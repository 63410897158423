import React from "react";
import TextFieldWithError from "./TextFieldWithError";

const AddressFields = ({
    register,
    registerOptions,
    newOne,
    newTwo,
    setValue,
    inputChange,
    errors,
    states,
    municipalities,
    neighborhoods,
    stateShrink,
    municipalityShrink,
    neighborhoodShrink,
    zipcodeShrink,
    addressShrink,
    referenceInput,
    contactPhoneShrink,
}) => (
    <>
        <TextFieldWithError
            register={register}
            registerOptions={{ required: "El estado es requerido" }}
            label="Seleccione un estado"
            variant="outlined"
            fullWidth
            select
            value={newOne}
            onChange={(e) => {
                setValue("stateInput", e.target.value || undefined);
                inputChange(e);
            }}
            errors={errors}
            errorKey="stateInput"
            options={states}
            placeholder="Estado"
            id="stateInput"
            name="stateInput"
            InputLabelProps={{ shrink: stateShrink }}
            className="m-tb-20 form-control data-input"
            sx={{
                mb: 2,
                "& .MuiOutlinedInput-root:hover > fieldset": {
                    borderColor: "#6C63FF",
                },
            }}
        />
        <TextFieldWithError
            register={register}
            registerOptions={{ required: "El municipio es requerido" }}
            label="Seleccione un municipio"
            variant="outlined"
            fullWidth
            select
            value={newTwo}
            onChange={(e) => {
                setValue("municipalityInput", e.target.value || undefined);
                inputChange(e);
            }}
            errors={errors}
            errorKey="municipalityInput"
            options={municipalities}
            placeholder="Municipio"
            id="municipalityInput"
            name="municipalityInput"
            InputLabelProps={{ shrink: municipalityShrink }}
            className="m-tb-20 form-control data-input"
            sx={{
                mb: 2,
                "& .MuiOutlinedInput-root:hover > fieldset": {
                    borderColor: "#6C63FF",
                },
            }}
        />
        <TextFieldWithError
            register={register}
            registerOptions={{ required: "La colonia es requerida" }}
            label="Seleccione una colonia"
            variant="outlined"
            fullWidth
            select
            onChange={(e) => {
                setValue("neighborhoodInput", e.target.value || undefined);
            }}
            errors={errors}
            errorKey="neighborhoodInput"
            options={neighborhoods}
            placeholder="Colonia"
            id="neighborhoodInput"
            name="neighborhoodInput"
            InputLabelProps={{ shrink: neighborhoodShrink }}
            className="m-tb-20 form-control data-input"
            sx={{
                mb: 2,
                "& .MuiOutlinedInput-root:hover > fieldset": {
                    borderColor: "#6C63FF",
                },
            }}
        />
        <TextFieldWithError
            register={register}
            registerOptions={registerOptions?.zipcodeInput}
            label="Código postal"
            variant="outlined"
            fullWidth
            type="text"
            onChange={inputChange}
            errors={errors}
            errorKey="zipcodeInput"
            placeholder="Código postal"
            id="zipcodeInput"
            name="zipcodeInput"
            InputLabelProps={{ shrink: zipcodeShrink }}
            className="m-tb-20 form-control data-input"
            sx={{
                mb: 2,
                "& .MuiOutlinedInput-root:hover > fieldset": {
                    borderColor: "#6C63FF",
                },
            }}
        />
        <TextFieldWithError
            register={register}
            registerOptions={registerOptions?.addressInput}
            label="Calle y número int/ext"
            variant="outlined"
            fullWidth
            type="text"
            onChange={inputChange}
            errors={errors}
            errorKey="addressInput"
            placeholder="Calle y número int/ext"
            id="addressInput"
            name="addressInput"
            InputLabelProps={{ shrink: addressShrink }}
            className="m-tb-20 form-control data-input"
            sx={{
                mb: 2,
                "& .MuiOutlinedInput-root:hover > fieldset": {
                    borderColor: "#6C63FF",
                },
            }}
        />
        <TextFieldWithError
            register={register}
            registerOptions={registerOptions?.referenceInput}
            label="Referencia de dirección"
            variant="outlined"
            fullWidth
            type="text"
            onChange={inputChange}
            errors={errors}
            errorKey="referenceInput"
            placeholder="Referencia de dirección"
            id="referenceInput"
            name="referenceInput"
            InputLabelProps={{ shrink: referenceInput }}
            className="m-tb-20 form-control data-input"
            sx={{
                mb: 2,
                "& .MuiOutlinedInput-root:hover > fieldset": {
                    borderColor: "#6C63FF",
                },
            }}
        />
        <TextFieldWithError
            register={register}
            registerOptions={registerOptions?.contactPhoneInput}
            label="Número de contacto"
            variant="outlined"
            fullWidth
            type="text"
            onChange={inputChange}
            errors={errors}
            errorKey="contactPhoneInput"
            placeholder="Número de contacto"
            id="contactPhoneInput"
            name="contactPhoneInput"
            InputLabelProps={{ shrink: contactPhoneShrink }}
            className="m-tb-20 form-control data-input"
            sx={{
                mb: 2,
                "& .MuiOutlinedInput-root:hover > fieldset": {
                    borderColor: "#6C63FF",
                },
            }}
        />
    </>
);
export default AddressFields;
