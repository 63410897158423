import React from "react";
import { Grid } from "@mui/material";
import { gridContainerStyles } from "./styles/styles";
import StoreInfo from "./components/StoreInfo";
import ContactInfo from "./components/ContactInfo";
import AddressInfo from "./components/AddressInfo.jsx";
import PhotoSection from "./components/PhotoSection";
import MapDialog from "./components/MapDialog";
import ImageDialog from "./components/ImageDialog";
import { useStoreDetails } from "./hooks/useStoreDetails";
import TimeStore from "./components/timeStore";

const StoreDetails = ({ storeData, ActionStore, isRejecting }) => {
    const {
        isImageDialogOpen,
        selectedImage,
        handleImageClick,
        handleCloseImageDialog,
        isMapDialogOpen,
        handleOpenMapDialog,
        handleCloseMapDialog,
        googleMapsApiKey,
        mapContainerStyle,
    } = useStoreDetails({ storeData });

    if (!storeData) {
        return <div>Cargando...</div>;
    }

    const latitude = storeData?.latitude ?? 0;
    const longitude = storeData?.longitude ?? 0;

    return (
        <Grid container spacing={3} sx={gridContainerStyles}>
            <StoreInfo
                storeData={storeData}
                ActionStore={ActionStore}
                isRejecting={isRejecting}
            />
            <ContactInfo storeData={storeData} />
            <AddressInfo
                storeData={storeData}
                handleOpenMapDialog={handleOpenMapDialog}
            />

            <PhotoSection
                storeData={storeData}
                handleImageClick={handleImageClick}
            />

            <TimeStore
                storeData={storeData}
                handleOpenMapDialog={handleOpenMapDialog}
            />
            {/* Dialogs */}
            <ImageDialog
                open={isImageDialogOpen}
                onClose={handleCloseImageDialog}
                selectedImage={selectedImage}
            />
            <MapDialog
                open={isMapDialogOpen}
                onClose={handleCloseMapDialog}
                googleMapsApiKey={googleMapsApiKey}
                latitude={latitude}
                longitude={longitude}
                mapContainerStyle={mapContainerStyle}
            />
        </Grid>
    );
};

export default StoreDetails;
