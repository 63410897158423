import React from "react";
import { IconButton } from "@mui/material";
import { ReactComponent as EditSvgIcon } from "assets/icons/editar.svg";

const EditButton = ({ onClick }) => {
    return (
        <IconButton onClick={onClick} aria-label="edit">
            <EditSvgIcon style={{ width: 24, height: 24 }} />
        </IconButton>
    );
};

export default EditButton;
