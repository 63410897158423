// Import: Middleware Action & Custom Types
import { IMEI_CLEAN, IMEI_CLEAN_ERROR } from "_models/redux/types";

// Action function
export const cleanInfoImei = () => async (dispatch) => {
    // Endpoint: Try & Catch

    try {
        dispatch({
            type: IMEI_CLEAN,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: IMEI_CLEAN_ERROR,
            payload: [],
        });
    }
};
