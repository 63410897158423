// FraudConfirmationDialog.js
import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    Button,
    DialogActions,
    CircularProgress,
} from "@mui/material";

const FraudConfirmationDialog = ({
    open,
    onClose,
    setDescriptionTransaction,
    setError,
    error,
    handleConfirm,
    loadingFraudAndChargeback,
    dialogMessage,
    isError,
}) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>
            {"¿Estás seguro de que quieres ejecutar esta acción?"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {"Una vez que ejecutes esta acción, no podrás deshacerla."}
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="description"
                label="Descripción"
                type="text"
                fullWidth
                onChange={(event) => {
                    setDescriptionTransaction(event.target.value);
                    setError(event.target.value.length < 5);
                }}
                error={error}
                helperText={
                    error ? "Este campo requiere al menos 5 caracteres" : ""
                }
            />
            {loadingFraudAndChargeback && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                </div>
            )}
            {dialogMessage && (
                <DialogContentText style={{ color: isError ? "red" : "green" }}>
                    {dialogMessage}
                </DialogContentText>
            )}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Cerrar
            </Button>
            <Button
                onClick={handleConfirm}
                color="primary"
                autoFocus
                disabled={error}
            >
                Aceptar
            </Button>
        </DialogActions>
    </Dialog>
);

export default FraudConfirmationDialog;
