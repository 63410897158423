import axiosTokenInstance from "services/api/backoffice";
import {
    HANDLE_STORE_MODERATION,
    HANDLE_STORE_MODERATION_ERROR,
} from "_models/redux/types";

// Action function
export const handleStoreModerationAction = (params) => async (dispatch) => {
    const { storeId, status_id, reason } = params || {};
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.patch(
            `/api/ipb/directory-stores/${storeId}/approve-reject`,
            {
                status_id,
                reason,
            }
        );

        const { data, status } = response || {};

        dispatch({
            type: HANDLE_STORE_MODERATION,
            payload: data || {},
            status: status || {},
            method: "patch",
        });
    } catch (e) {
        dispatch({
            type: HANDLE_STORE_MODERATION_ERROR,
            payload: e || {},
        });
    }
};
