import axiosTokenInstance from "services/api/backoffice";
import {
    IPB_DIRECTORY_LISTING,
    IPB_DIRECTORY_LISTING_ERROR,
} from "_models/redux/types";

// Action function
export const ipbDirectoryListingAction =
    (page, size, filters = {}) =>
    async (dispatch) => {
        try {
            const params = { page, size, ...filters };
            const response = await axiosTokenInstance.get(
                `/api/ipb/directory-stores`,
                { params }
            );

            const { data, status } = response || {};

            dispatch({
                type: IPB_DIRECTORY_LISTING,
                payload: data || {},
                status: status || {},
                method: "get",
            });
        } catch (e) {
            dispatch({
                type: IPB_DIRECTORY_LISTING_ERROR,
                payload: e || {},
            });
        }
    };
