import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { connect } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import ListItem from "@mui/material/ListItem";
import { getStaticStates } from "_models/data/states";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const FormLeadDetail = (childrenProps) => {
    const userId = localStorage.getItem("user_id");

    const { states } = getStaticStates();

    const { parentProps } = childrenProps || {};
    const { props, formProps, navProps, urlProps } = parentProps || {};

    const { setIsScreenLoading } = urlProps || {};

    const { params, history } = navProps || {};
    const { leadId } = params || {};

    const { register, setValue, getValues, errors } = formProps || {};

    const {
        lead,
        getLead,
        municipality,
        municipalityAction,
        neighborhood,
        neighborhoodAction,
        offers,
        getOffers,
        cleanOffersAction,
        checkImei,
        patchCheckImei,
        cleanCheckImeiAction,
    } = props || {};

    const { payload, state } = lead || {};
    const {
        name,
        contact_phone,
        contact_for_service,
        email,
        carrier_product_id,
        sim_type_id,
        municipality_id,
        neighborhood_id,
        state_id,
        address,
        zipcode,
        backoffice_user_id,
    } = payload || {};

    const { products } = offers?.payload || {};

    const { is_valid, supports_esim } = checkImei?.payload || {};

    const { municipalities } = municipality?.payload || {};
    const { neighborhoods } = neighborhood?.payload || {};

    const SimpleDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiDialogContent-root": {
            padding: theme.spacing(2),
        },
        "& .MuiDialogActions-root": {
            padding: theme.spacing(1),
        },
    }));

    const DialogTitleInfo = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    const registerOptions = {
        // simTypeInput: {
        //     // required: "El tipo de SIM o eSIM es requerido",
        // },

        nameInput: {
            required: "El nombre es requerido",
        },
        contactPhoneInput: {
            required: "El número de contacto es requerido",
        },
        contactForServiceInput: {
            required:
                "El número de contacto para servicio de mensajería es requerido",
        },

        addressInput: {
            required: "La dirección es requerida",
        },
        zipcodeInput: {
            required: "El código postal es requerido",
        },

        idInput: {
            required: "El paquete es requerido",
        },
        imeiInput: {
            required: "El imei es requerido",
        },

        stateInput: {
            required: "El estado es requerido",
        },
        municipalityInput: {
            required: "El municipio es requerido",
        },
        neighborhoodInput: {
            required: "La colonia es requerida",
        },

        offerInput: {
            required: "La oferta es requerida",
        },

        checkImeiInput: {
            // required: "El IMEI es requerido",
        },
        backofficeStatusInput: {
            required: "El estatus es requerido",
        },
        referenceInput: {
            minLength: { value: 10, message: "Mínimo 10 caracteres" },
            required: "Este campo es requerido",
        },
    };

    const [nameShrink, setNameShrink] = useState(false);
    const [contactPhoneShrink, setContactPhoneShrink] = useState(false);
    const [contactForServiceShrink, setContactForServiceShrink] =
        useState(false);

    const [emailShrink, setEmailShrink] = useState();

    const [addressShrink, setAddressShrink] = useState(false);
    const [zipcodeShrink, setZipcodeShrink] = useState(false);
    const [referenceShrink, setReferenceShrink] = useState(false);

    const [stateShrink, setStateShrink] = useState(false);
    const [municipalityShrink, setMunicipalityShrink] = useState(false);
    const [neighborhoodShrink, setNeighborhoodShrink] = useState(false);

    const [open, setOpen] = useState(false);

    const [offerShrink, setOfferShrink] = useState(false);

    const [checkImeiShrink, setCheckImeiShrink] = useState(false);

    const [newOne, setNewOne] = useState();
    const [newTwo, setNewTwo] = useState();
    const [newThree, setNewThree] = useState();

    const [newFour, setNewFour] = useState(0);

    const [newFive, setNewFive] = useState();

    const checkImeiMethod = (e) => {
        setIsScreenLoading(true);

        if (
            typeof patchCheckImei === "function" &&
            typeof e?.target?.value !== "undefined"
        ) {
            patchCheckImei({
                lead_id: leadId,
                imei: getValues("checkImeiInput"),
            });
        }
    };

    const handleClose = () => {
        if (
            lead?.payload?.response?.data?.context ===
            "lead_assigned_to_another_user"
        ) {
            setOpen(false);
            history("/leads");
        } else {
            setOpen(false);
        }
    };

    const inputChange = (e) => {
        const inputValue = e?.target?.value;
        const inputName = e?.target?.name;

        if (inputValue === "") {
            switch (inputName) {
                case "nameInput":
                    setNameShrink(false);
                    break;
                case "contactPhoneInput":
                    setContactPhoneShrink(false);
                    break;
                case "contactForServiceInput":
                    setContactForServiceShrink(false);
                    break;

                case "emailInput":
                    setEmailShrink(false);
                    break;

                case "addressInput":
                    setAddressShrink(false);
                    break;
                case "referenceInput":
                    setReferenceShrink(false);
                    break;

                case "zipcodeInput":
                    setZipcodeShrink(false);
                    break;
                case "checkImeiInput":
                    setCheckImeiShrink(false);
                    break;
                default:
                    break;
            }
        } else {
            switch (inputName) {
                case "nameInput":
                    setNameShrink(true);
                    break;
                case "contactPhoneInput":
                    setContactPhoneShrink(true);
                    break;
                case "contactForServiceInput":
                    setContactForServiceShrink(true);
                    break;

                case "emailInput":
                    setEmailShrink(true);
                    break;

                case "addressInput":
                    setAddressShrink(true);
                    break;
                case "referenceInput":
                    setReferenceShrink(true);
                    break;
                case "zipcodeInput":
                    setZipcodeShrink(true);
                    break;
                case "checkImeiInput":
                    setCheckImeiShrink(true);
                    break;

                case "stateInput":
                    setNewOne(inputValue);
                    setValue(inputName, inputValue);
                    setStateShrink(true);
                    municipalityAction({ state_id: inputValue });
                    neighborhoodAction({ municipality_id: inputValue });
                    break;
                case "municipalityInput":
                    setNewTwo(inputValue);
                    setValue(inputName, inputValue);
                    setMunicipalityShrink(true);
                    neighborhoodAction({ municipality_id: inputValue });
                    break;
                case "neighborhoodInput":
                    setNewThree(inputValue);
                    setValue(inputName, inputValue);
                    setNeighborhoodShrink(true);
                    break;

                case "offerInput":
                    setNewFive(inputValue);
                    setValue(inputName, inputValue);
                    setOfferShrink(true);
                    break;

                case "backofficeStatusInput":
                    setValue(inputName, inputValue);

                    break;

                default:
                    break;
            }
        }
    };

    useEffect(() => {
        if (leadId) {
            getLead({ lead_id: leadId });
            getOffers({ lead_id: leadId });
        }

        return () => {
            cleanOffersAction();
            cleanCheckImeiAction();

            parentProps?.props?.cleanLeadsAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsScreenLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkImei]);

    useEffect(() => {
        if (is_valid) {
            if (supports_esim) {
                // setValue("checkImeiInput", 2);
                setNewFour(2);
                setValue("simTypeInput", 2);
            } else {
                // setValue("checkImeiInput", 1);
                setNewFour(1);
                setValue("simTypeInput", 1);
            }
        } else {
            setNewFour(0);
            setValue("simTypeInput", 0);
        }

        setIsScreenLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_valid, supports_esim]);

    useEffect(() => {
        setValue("simTypeInput", newFour);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newFour]);

    useEffect(() => {
        if (state_id && municipalityAction) {
            municipalityAction({ state_id: state_id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state_id]);

    useEffect(() => {
        if (municipality_id && neighborhoodAction) {
            neighborhoodAction({ municipality_id: municipality_id });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [municipality_id]);

    useEffect(() => {
        setIsScreenLoading(false);
        // setValue("neighborhoodInput", 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [neighborhoods]);

    useEffect(() => {
        if (state !== "error") {
            if (userId && backoffice_user_id) {
                if (parseInt(userId) !== parseInt(backoffice_user_id)) {
                    history("/leads");
                }
            }

            if (name) {
                setValue("nameInput", name);
                setNameShrink(true);
            }
            if (contact_phone) {
                setValue("contactPhoneInput", contact_phone);
                setContactPhoneShrink(true);
            }
            if (contact_for_service) {
                setValue("contactForServiceInput", contact_for_service);
                setContactForServiceShrink(true);
            }

            if (email) {
                setValue("emailInput", email);
                setEmailShrink(true);
            }

            if (address) {
                setValue("addressInput", address);
                setAddressShrink(true);
            }
            if (zipcode) {
                setValue("zipcodeInput", zipcode);
                setZipcodeShrink(true);
            }

            if (state_id) {
                setNewOne(state_id);
                setValue("stateInput", state_id);
                setStateShrink(true);
            }
            if (municipality_id) {
                setNewTwo(municipality_id);
                setValue("municipalityInput", municipality_id);
                setMunicipalityShrink(true);
            }
            if (neighborhood_id) {
                setNewThree(neighborhood_id);
                setValue("neighborhoodInput", neighborhood_id);
                setNeighborhoodShrink(true);
            }

            if (sim_type_id) {
                setNewFour(sim_type_id);
                setValue("simTypeInput", sim_type_id);
            }

            if (carrier_product_id) {
                setNewFive(carrier_product_id);
                setValue("offerInput", carrier_product_id);
                setOfferShrink(true);
            }

            // if (imei) {
            //     setValue("checkImeiInput", imei);
            //     setCheckImeiShrink(true);
            // }
            setIsScreenLoading(false);
        } else {
            if (lead?.payload?.response?.status >= 400) {
                setIsScreenLoading(false);
                setOpen(true);
            } else {
                history("/leads");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lead]);

    const PopUpInfoImei = () => {
        return (
            <div>
                <SimpleDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth
                >
                    <DialogTitleInfo
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        IMEI
                    </DialogTitleInfo>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            <Grid container className="lead-imei-container">
                                <Grid item xs={12} className="lead-imei">
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <p className="modal-imei-label">
                                                Homologado
                                            </p>
                                            <span className="modal-imei-info">
                                                {
                                                    checkImei?.payload?.imei
                                                        ?.homologated
                                                }
                                            </span>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            className="imei-blocked"
                                        >
                                            <p className="modal-imei-label">
                                                Bloqueado
                                            </p>
                                            <span className="modal-imei-info">
                                                {
                                                    checkImei?.payload?.imei
                                                        ?.blocked
                                                }
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="d-flex">
                                    <Grid
                                        item
                                        xs={4}
                                        className="lead-imei column-imei"
                                    >
                                        <p className="modal-imei-label">
                                            Marca
                                        </p>

                                        <span className="modal-imei-info">
                                            {
                                                checkImei?.payload
                                                    ?.deviceFeatures?.brand
                                            }
                                        </span>

                                        <p className="modal-imei-label m-t-10">
                                            Modelo
                                        </p>
                                        <span className="modal-imei-info">
                                            {
                                                checkImei?.payload
                                                    ?.deviceFeatures?.model
                                            }
                                        </span>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={8}
                                        className="lead-imei column-imei column-right"
                                    >
                                        <ListItem disableGutters>
                                            <span>
                                                Compatible con band 28:
                                                {"   "}
                                                <span className="modal-imei-info">
                                                    {
                                                        checkImei?.payload
                                                            ?.deviceFeatures
                                                            ?.band28
                                                    }
                                                </span>
                                            </span>
                                        </ListItem>
                                        <ListItem disableGutters>
                                            <span>
                                                Soporta VolTE:{"   "}
                                                <span className="modal-imei-info">
                                                    {
                                                        checkImei?.payload
                                                            ?.deviceFeatures
                                                            ?.volteCapable
                                                    }
                                                </span>
                                            </span>
                                        </ListItem>
                                        <ListItem disableGutters>
                                            <span>
                                                Soporta ESIM:{"   "}
                                                <span className="modal-imei-info">
                                                    {
                                                        checkImei?.payload?.imei
                                                            ?.soportaESIM
                                                    }
                                                </span>
                                            </span>
                                        </ListItem>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cerrar</Button>
                    </DialogActions>
                </SimpleDialog>
            </div>
        );
    };

    const PopModalError = () => {
        return (
            <div>
                <SimpleDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <DialogTitleInfo
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        Error
                    </DialogTitleInfo>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            {checkImei?.payload?.response?.data?.detail ||
                                payload?.response?.data?.detail}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cerrar</Button>
                    </DialogActions>
                </SimpleDialog>
            </div>
        );
    };

    useEffect(() => {
        if (checkImei?.state === "error") {
            setIsScreenLoading(false);
            setOpen(true);
        }
        if (checkImei?.payload?.is_valid === false) {
            setOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkImei]);

    const OffersComponent = () => {
        return parseInt(newFour) === 1 || parseInt(newFour) === 2 ? (
            <Grid item xs={12} md={newFour === 1 ? 12 : 6}>
                <Grid container>
                    <Grid item xs={12} className="lead-form">
                        <h1>Paquete</h1>

                        <Grid container className={"lead-form-input"}>
                            <Grid
                                item
                                xs={12}
                                className={"lead-form-input-component"}
                            >
                                <TextField
                                    select
                                    fullWidth
                                    variant="outlined"
                                    // label="Oferta"
                                    placeholder="Oferta"
                                    id="offerInput"
                                    name="offerInput"
                                    {...register(
                                        "offerInput",
                                        registerOptions?.offerInput
                                    )}
                                    value={newFive}
                                    // defaultValue={0}
                                    onChange={(e) => inputChange(e)}
                                    InputLabelProps={{ shrink: offerShrink }}
                                    className="m-tb-20 form-control data-input"
                                    sx={{
                                        mb: 2,
                                        "& .MuiOutlinedInput-root:hover": {
                                            "& > fieldset": {
                                                borderColor: "#6C63FF",
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem key={0} value={0}>
                                        {"Seleccione una oferta"}
                                    </MenuItem>
                                    {products?.map((option) => (
                                        <MenuItem
                                            key={option?.carrier_product_id}
                                            value={
                                                option?.carrier_product_id || 0
                                            }
                                        >
                                            {option?.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                className={"lead-form-input-error"}
                            >
                                <span>
                                    {errors?.offerInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.offerInput.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        ) : null;
    };

    ////

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <Grid container>
                    <Grid item xs={12} className="lead-form m-b-20">
                        <h1>Cliente</h1>

                        <Grid container className={"lead-form-input"}>
                            <Grid
                                item
                                xs={12}
                                className={"lead-form-input-component"}
                            >
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Nombre completo"
                                    placeholder="Nombre completo"
                                    id="nameInput"
                                    name="nameInput"
                                    {...register(
                                        "nameInput",
                                        registerOptions?.nameInput
                                    )}
                                    onChange={(e) => inputChange(e)}
                                    InputLabelProps={{ shrink: nameShrink }}
                                    className="m-tb-20 form-control data-input"
                                    sx={{
                                        mb: 2,
                                        "& .MuiOutlinedInput-root:hover": {
                                            "& > fieldset": {
                                                borderColor: "#6C63FF",
                                            },
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                className={"lead-form-input-error"}
                            >
                                <span>
                                    {errors?.nameInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.nameInput.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>
                        </Grid>

                        <Grid container className={"lead-form-input"}>
                            <Grid
                                item
                                xs={12}
                                className={"lead-form-input-component"}
                            >
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Número de contacto"
                                    placeholder="Número de contacto"
                                    id="contactPhoneInput"
                                    name="contactPhoneInput"
                                    {...register(
                                        "contactPhoneInput",
                                        registerOptions?.contactPhoneInput
                                    )}
                                    onChange={(e) => inputChange(e)}
                                    InputLabelProps={{
                                        shrink: contactPhoneShrink,
                                    }}
                                    className="m-tb-20 form-control data-input"
                                    sx={{
                                        mb: 2,
                                        "& .MuiOutlinedInput-root:hover": {
                                            "& > fieldset": {
                                                borderColor: "#6C63FF",
                                            },
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                className={"lead-form-input-error"}
                            >
                                <span>
                                    {errors?.contactPhoneInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.contactPhoneInput.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>
                        </Grid>

                        {/* <TextField
                            id="outlined-basic"
                            label="Correo electrónico"
                            variant="outlined"
                            sx={{
                                mb: 2,
                                "& .MuiOutlinedInput-root:hover": {
                                    "& > fieldset": {
                                        borderColor: "#6C63FF",
                                    },
                                },
                            }}
                            fullWidth
                        /> */}

                        <Grid container className={"lead-form-input"}>
                            <Grid
                                item
                                xs={12}
                                className={"lead-form-input-component"}
                            >
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="Correo electrónico (opcional)"
                                    placeholder="Correo electrónico  (opcional) "
                                    id="emailInput"
                                    name="emailInput"
                                    {...register("emailInput")}
                                    onChange={(e) => inputChange(e)}
                                    InputLabelProps={{ shrink: emailShrink }}
                                    className="m-tb-20 form-control data-input"
                                    sx={{
                                        mb: 2,
                                        "& .MuiOutlinedInput-root:hover": {
                                            "& > fieldset": {
                                                borderColor: "#6C63FF",
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className="inline m-t-0">
                            <Grid item xs={12} lg={9}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    label="IMEI"
                                    placeholder="IMEI"
                                    id="checkImeiInput"
                                    name="checkImeiInput"
                                    {...register(
                                        "checkImeiInput",
                                        registerOptions?.checkImeiInput
                                    )}
                                    onChange={(e) => inputChange(e)}
                                    InputLabelProps={{
                                        shrink: checkImeiShrink,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {parseInt(newFour) === 1 ? (
                                                    <span>Compatible SIM</span>
                                                ) : parseInt(newFour) === 2 ? (
                                                    <span>Compatible eSIM</span>
                                                ) : (
                                                    <span className="imei-input-null">
                                                        X
                                                    </span>
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                    className="m-tb-20 form-control data-input imei-input"
                                    sx={{
                                        "& .MuiOutlinedInput-root:hover": {
                                            "& > fieldset": {
                                                borderColor: "#6C63FF",
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <button
                                    type="button"
                                    className="imei-btn"
                                    onClick={checkImeiMethod}
                                >
                                    Consultar
                                </button>
                            </Grid>
                        </Grid>

                        {/* <FormControl>
                            <RadioGroup
                                row
                                value={newFour}
                                {...register(
                                    "simTypeInput",
                                    registerOptions?.simTypeInput
                                )}
                                name="simTypeInput"
                                // defaultValue="1"
                                // onChange={newFunction}
                                InputLabelProps={{ shrink: stateShrink }}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <FormControlLabel
                                    value="1"
                                    control={
                                        <Radio
                                            sx={{
                                                color: "#848484",
                                                "&.Mui-checked": {
                                                    color: "#750DDE",
                                                },
                                            }}
                                        />
                                    }
                                    label="SIM"
                                />
                                <FormControlLabel
                                    value="2"
                                    control={
                                        <Radio
                                            sx={{
                                                color: "#848484",
                                                "&.Mui-checked": {
                                                    color: "#750DDE",
                                                },
                                            }}
                                        />
                                    }
                                    label="eSIM"
                                />
                            </RadioGroup>
                        </FormControl> */}

                        {/* <Grid item xs={12} className={"lead-form-input-error"}>
                            <span>
                                {errors?.simTypeInput && (
                                    <p className="required-incidence m-0">
                                        {" "}
                                        {errors?.simTypeInput.message}
                                    </p>
                                )}
                            </span>
                        </Grid> */}
                    </Grid>
                    {newFour === 1 ? <OffersComponent /> : null}
                </Grid>
            </Grid>

            {newFour === 1 ? (
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={12} className="lead-form">
                            <h1>Dirección de envío</h1>

                            {/* <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="postal-code"
                                    name="radio-buttons-group"
                                    className="m-b-30"
                                >
                                    <FormControlLabel
                                        value="postal-code-true"
                                        control={
                                            <Radio
                                                sx={{
                                                    color: "#848484",
                                                    "&.Mui-checked": {
                                                        color: "#750DDE",
                                                    },
                                                }}
                                            />
                                        }
                                        label="Con código postal"
                                    />
                                    <FormControlLabel
                                        value="postal-code-false"
                                        control={
                                            <Radio
                                                sx={{
                                                    color: "#848484",
                                                    "&.Mui-checked": {
                                                        color: "#750DDE",
                                                    },
                                                }}
                                            />
                                        }
                                        label="Sin código postal"
                                    />
                                </RadioGroup> 
                            </FormControl> */}

                            <Grid container spacing={2} className="inline">
                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        {...register(
                                            "zipcodeInput",
                                            registerOptions?.zipcodeInput
                                        )}
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="Código postal"
                                        placeholder="Código postal"
                                        id="zipcodeInput"
                                        name="zipcodeInput"
                                        onChange={(e) => inputChange(e)}
                                        InputLabelProps={{
                                            shrink: zipcodeShrink,
                                        }}
                                        className="m-tb-20 form-control data-input"
                                        sx={{
                                            mb: 2,
                                            "& .MuiOutlinedInput-root:hover": {
                                                "& > fieldset": {
                                                    borderColor: "#6C63FF",
                                                },
                                            },
                                        }}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} lg={3}>
                                    <button
                                        variant="outlined"
                                        type="button"
                                        className="postal-code-btn"
                                    >
                                        Buscar
                                    </button>
                                </Grid> */}
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                className={"lead-form-input-error"}
                            >
                                <span>
                                    {errors?.zipcodeInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.zipcodeInput.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>

                            <TextField
                                {...register(
                                    "stateInput",
                                    registerOptions?.stateInput
                                )}
                                select
                                fullWidth
                                variant="outlined"
                                // label="Estado"
                                placeholder="Estado"
                                id="stateInput"
                                name="stateInput"
                                value={newOne}
                                // defaultValue={0}
                                onChange={(e) => inputChange(e)}
                                InputLabelProps={{ shrink: stateShrink }}
                                className="m-tb-20 form-control data-input"
                                sx={{
                                    mb: 2,
                                    "& .MuiOutlinedInput-root:hover": {
                                        "& > fieldset": {
                                            borderColor: "#6C63FF",
                                        },
                                    },
                                }}
                            >
                                <MenuItem key={0} value={0}>
                                    {"Seleccione un estado"}
                                </MenuItem>
                                {states?.map((option) => (
                                    <MenuItem
                                        key={option?.id}
                                        value={option?.id || 0}
                                    >
                                        {option?.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <Grid
                                item
                                xs={12}
                                className={"lead-form-input-error"}
                            >
                                <span>
                                    {errors?.stateInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.stateInput.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>

                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                // label="Municipio"
                                placeholder="Municipio"
                                id="municipalityInput"
                                name="municipalityInput"
                                {...register(
                                    "municipalityInput",
                                    registerOptions?.municipalityInput
                                )}
                                value={newTwo}
                                // defaultValue={0}
                                onChange={(e) => inputChange(e)}
                                InputLabelProps={{ shrink: municipalityShrink }}
                                className="m-tb-20 form-control data-input"
                                sx={{
                                    mb: 2,
                                    "& .MuiOutlinedInput-root:hover": {
                                        "& > fieldset": {
                                            borderColor: "#6C63FF",
                                        },
                                    },
                                }}
                            >
                                <MenuItem key={0} value={0}>
                                    {"Seleccione un municipio"}
                                </MenuItem>
                                {municipalities?.map((option) => (
                                    <MenuItem
                                        key={option?.id}
                                        value={option?.id || 0}
                                    >
                                        {option?.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <Grid
                                item
                                xs={12}
                                className={"lead-form-input-error"}
                            >
                                <span>
                                    {errors?.municipalityInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.municipalityInput.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>

                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                // label="Colonia"
                                placeholder="Colonia"
                                id="neighborhoodInput"
                                name="neighborhoodInput"
                                {...register(
                                    "neighborhoodInput",
                                    registerOptions?.neighborhoodInput
                                )}
                                value={newThree}
                                // defaultValue={0}
                                onChange={(e) => inputChange(e)}
                                InputLabelProps={{ shrink: neighborhoodShrink }}
                                className="m-tb-20 form-control data-input"
                                sx={{
                                    mb: 2,
                                    "& .MuiOutlinedInput-root:hover": {
                                        "& > fieldset": {
                                            borderColor: "#6C63FF",
                                        },
                                    },
                                }}
                            >
                                <MenuItem key={0} value={0}>
                                    {"Seleccione una colonia"}
                                </MenuItem>
                                {neighborhoods?.map((option) => (
                                    <MenuItem
                                        key={option?.id}
                                        value={option?.id || 0}
                                    >
                                        {option?.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <Grid
                                item
                                xs={12}
                                className={"lead-form-input-error"}
                            >
                                <span>
                                    {errors?.neighborhoodInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.neighborhoodInput.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>

                            <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                label="Calle y número int/ext"
                                placeholder="Calle y número int/ext"
                                id="addressInput"
                                name="addressInput"
                                {...register(
                                    "addressInput",
                                    registerOptions?.addressInput
                                )}
                                onChange={(e) => inputChange(e)}
                                InputLabelProps={{
                                    shrink: addressShrink,
                                }}
                                className="m-tb-20 form-control data-input"
                                sx={{
                                    mb: 2,
                                    "& .MuiOutlinedInput-root:hover": {
                                        "& > fieldset": {
                                            borderColor: "#6C63FF",
                                        },
                                    },
                                }}
                            />

                            <Grid
                                item
                                xs={12}
                                className={"lead-form-input-error"}
                            >
                                <span>
                                    {errors?.addressInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.addressInput.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>

                            <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                label="Referencias de dirección"
                                placeholder="Referencias de dirección"
                                id="referenceInput"
                                name="referenceInput"
                                {...register(
                                    "referenceInput",
                                    registerOptions?.referenceInput
                                )}
                                onChange={(e) => inputChange(e)}
                                InputLabelProps={{
                                    shrink: referenceShrink,
                                }}
                                className="m-tb-20 form-control data-input"
                                sx={{
                                    mb: 2,
                                    "& .MuiOutlinedInput-root:hover": {
                                        "& > fieldset": {
                                            borderColor: "#6C63FF",
                                        },
                                    },
                                }}
                            />

                            <Grid
                                item
                                xs={12}
                                className={"lead-form-input-error"}
                            >
                                <span>
                                    {errors?.referenceInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {errors?.referenceInput.message}
                                        </p>
                                    )}
                                </span>
                            </Grid>

                            <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                label="Núm. de contacto para servicio de mensajería"
                                placeholder="Núm. de contacto para servicio de mensajería"
                                id="contactForServiceInput"
                                name="contactForServiceInput"
                                {...register(
                                    "contactForServiceInput",
                                    registerOptions?.contactForServiceInput
                                )}
                                onChange={(e) => inputChange(e)}
                                InputLabelProps={{
                                    shrink: contactForServiceShrink,
                                }}
                                className="m-tb-20 form-control data-input"
                                sx={{
                                    mb: 2,
                                    "& .MuiOutlinedInput-root:hover": {
                                        "& > fieldset": {
                                            borderColor: "#6C63FF",
                                        },
                                    },
                                }}
                            />
                            <Grid
                                item
                                xs={12}
                                className={"lead-form-input-error"}
                            >
                                <span>
                                    {errors?.contactForServiceInput && (
                                        <p className="required-incidence m-0">
                                            {" "}
                                            {
                                                errors?.contactForServiceInput
                                                    .message
                                            }
                                        </p>
                                    )}
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <OffersComponent />
            )}

            {checkImei?.payload?.is_valid !== false ? (
                <PopModalError />
            ) : (
                <PopUpInfoImei />
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    lead: state?.lead,
});

////

const mapDispatchToProps = {};

////

export default connect(mapStateToProps, mapDispatchToProps)(FormLeadDetail);
