import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { getCustomerProfile } from "_models/redux/customer-profile/action";
import { connect } from "react-redux";
import { getTicketById } from "_models/redux/get-ticket-by-id/action";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../../clientTickets/modal/new-inicidence";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import AvatarComponent from "./AvatarComponent";

const CustomerProfileHeaderUser = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const { customerProfileReducer } = props;
    const { customerProfile } = customerProfileReducer || {};
    const statusLine = customerProfile?.status?.subStatus;
    const { profile } = customerProfile || {};
    const {
        name,
        last_name,
        mothers_last_name,
        carrier_name,
        imsi,
        imei,
        icc_number,
        activated_at,
    } = profile || {};

    useEffect(() => {
        if (profile !== undefined) setIsLoading(false);
    }, [profile]);

    const location = useLocation();
    const navigate = useNavigate();

    const { state } = location || {};
    const { directNumber } = state || {};

    useEffect(() => {
        navigate(<Modal />, {
            state: { name, last_name, mothers_last_name, directNumber },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    useEffect(() => {
        if (directNumber !== undefined) {
            props.getCustomerProfile({
                directNumber: directNumber,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const seeLineStatus = () => {
        switch (statusLine?.toUpperCase()) {
            case "ACTIVE":
                return <p className="line-status">Línea activa</p>;
            case "IDLE":
                return <p className="line-status-Idle">Inactivo</p>;
            case "AOV":
                return (
                    <p className="line-status">
                        Activo con producto facturable
                    </p>
                );
            case "ACT":
                return (
                    <p className="line-status">
                        Activo sin producto facturable
                    </p>
                );
            case "SUP":
                return <p className="line-status-Suspendida">Suspendido</p>;
            case "BAR":
                return <p className="line-status-Barring">Barring</p>;
            case "PRE":
                return (
                    <p className="line-status-Predeactivate">Predesactivo</p>
                );
            case "DAC":
                return <p className="line-status-Suspendida">Desactivado</p>;
            case "PREACT":
                return <p className="line-status-Predeactivate">Pre activo </p>;
            default:
                return (
                    <h1 className="line-status">{statusLine?.toUpperCase()}</h1>
                );
        }
    };

    return (
        <Grid container mt={2} spacing={3}>
            <Grid item xs={3}>
                <Grid
                    container
                    display={"flex"}
                    flexDirection={"row"}
                    className="basic"
                >
                    <Grid item xs={12} className="ticket-data">
                        <Grid container>
                            <Grid item xs={1} mr={2}>
                                <AvatarComponent />
                            </Grid>
                            <Grid item xs={10}>
                                <h4>
                                    {isLoading ? (
                                        <CircularProgress />
                                    ) : name &&
                                      last_name &&
                                      mothers_last_name !== "undefined" ? (
                                        name +
                                        " " +
                                        last_name +
                                        " " +
                                        mothers_last_name
                                    ) : null}
                                </h4>
                                {/* TO DO agregar boton para editar nombre  */}
                                <p>
                                    {isLoading ? (
                                        <CircularProgress />
                                    ) : (
                                        directNumber
                                    )}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <Grid container className="ticket-data">
                    <Grid item xs={6}>
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <h4> {carrier_name}</h4>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        display={"flex"}
                        justifyContent={"flex-end"}
                    >
                        <p className="status">{seeLineStatus()}</p>
                    </Grid>
                    <Grid item xs={6}>
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <p className="data">
                                {" Se activó el "}
                                {isLoading
                                    ? moment
                                          .unix(activated_at)
                                          .format("DD/MM/YYYY")
                                    : "N/A"}
                            </p>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container className="numeric ticket-data">
                    <Grid item xs={12} md={4}>
                        <h4>IMSI</h4>
                        {isLoading ? <CircularProgress /> : <p> {imsi}</p>}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <h4>IMEI</h4>
                        {isLoading ? <CircularProgress /> : <p> {imei}</p>}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <h4>ICC</h4>
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <p> {icc_number}</p>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
const mapStateToProps = (state) => ({
    customerProfileReducer: state.customerProfile,
    ticketById: state.ticketById,
    incidence: state.incidence,
});
const mapDispatchToProps = {
    getCustomerProfile,
    getTicketById,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerProfileHeaderUser);
