import React from "react";
import { Box } from "@mui/material";
import CustomDialog from "widgets/component/CustomDialog";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

const MapDialog = ({
    open,
    onClose,
    googleMapsApiKey,
    latitude = 0, // Valor por defecto
    longitude = 0, // Valor por defecto
    mapContainerStyle,
}) => {
    const center = { lat: latitude, lng: longitude };

    return (
        <CustomDialog
            open={open}
            onClose={onClose}
            title="Dirección de la tienda"
            content={
                open && (
                    <Box sx={{ height: "400px", width: "100%" }}>
                        <APIProvider apiKey={googleMapsApiKey}>
                            <Map
                                mapContainerStyle={mapContainerStyle}
                                defaultCenter={center}
                                defaultZoom={15}
                            >
                                <Marker position={center} />
                            </Map>
                        </APIProvider>
                    </Box>
                )
            }
            fullWidth
            maxWidth="md"
        />
    );
};

export default MapDialog;
