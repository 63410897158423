import React from "react";
import { TextField, Button, Container, Typography, Box } from "@mui/material";
import Lottie from "react-lottie";

import DownloadLinks from "./DownloadLinks";
import { useInvoiceForm } from "../hooks/useInvoiceForm";
import {
    containerStyle,
    loadingBoxStyle,
    formBoxStyle,
    successBoxStyle,
    errorMessageStyle,
} from "../style/style";

const InvoiceForm = ({
    postGenerateInvoices,
    generateInvoices,
    cleanGenerateInvoices,
}) => {
    const {
        loading,
        responseMessage,
        invoiceLinks,
        showForm,
        register,
        handleSubmit,
        errors,
        onSubmit,
        handleNegativeValue,
        defaultOptions,
        handleNewInvoice,
    } = useInvoiceForm({
        postGenerateInvoices,
        generateInvoices,
        cleanGenerateInvoices,
    });

    return (
        <Container sx={containerStyle}>
            {loading ? (
                <Box sx={loadingBoxStyle}>
                    <Lottie options={defaultOptions} height={200} width={200} />
                </Box>
            ) : showForm ? (
                <Box
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    sx={formBoxStyle}
                >
                    <Typography variant="h5" component="h2" gutterBottom>
                        Facturación
                    </Typography>
                    <TextField
                        label="Cantidad"
                        type="number"
                        {...register("quantity", {
                            required: "Quantity is required",
                            min: {
                                value: 0,
                                message: "Quantity must be non-negative",
                            },
                        })}
                        error={Boolean(errors.quantity)}
                        helperText={
                            errors.quantity ? errors.quantity.message : ""
                        }
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => handleNegativeValue(e)}
                    />
                    <TextField
                        label="Monto total"
                        type="number"
                        inputProps={{ step: "any" }}
                        {...register("total_amount", {
                            required: "Total Amount is required",
                            min: {
                                value: 0,
                                message: "Total Amount must be non-negative",
                            },
                        })}
                        error={Boolean(errors.total_amount)}
                        helperText={
                            errors.total_amount
                                ? errors.total_amount.message
                                : ""
                        }
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => handleNegativeValue(e)}
                    />
                    <TextField
                        label="Folio"
                        type="text"
                        {...register("folio", {
                            required: "Folio is required",
                        })}
                        error={Boolean(errors.folio)}
                        helperText={errors.folio ? errors.folio.message : ""}
                        onWheel={(e) => e.target.blur()}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                    >
                        Generar Factura
                    </Button>
                    {responseMessage && (
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={errorMessageStyle}
                        >
                            {responseMessage}
                        </Typography>
                    )}
                </Box>
            ) : (
                <Box sx={successBoxStyle}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Facturación generada exitosamente
                    </Typography>
                    {invoiceLinks.map((invoice, index) => (
                        <DownloadLinks
                            key={index}
                            pdf_url={invoice.pdf_url}
                            xml_url={invoice.xml_url}
                        />
                    ))}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNewInvoice}
                    >
                        Generar nueva factura
                    </Button>
                </Box>
            )}
        </Container>
    );
};

export default InvoiceForm;
