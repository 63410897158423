import { CLEAN_LEADS, CLEAN_LEADS_ERROR } from "_models/redux/types";

export const cleanLeadsAction = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAN_LEADS,
            payload: undefined,
        });
    } catch (e) {
        dispatch({
            type: CLEAN_LEADS_ERROR,
            payload: undefined,
        });
    }
};
