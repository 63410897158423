import React from "react";
import { connect } from "react-redux";
import DashboardHeader from "./header";
import SalesDataTable from "./datatable";

const SaleDashboard = () => {
    return (
        <div>
            <DashboardHeader />
            <SalesDataTable />
        </div>
    );
};

const mapStateToProps = (state) => ({ tickets: state.tickets });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SaleDashboard);
