import axiosTokenInstance from "services/api/backoffice";
import {
    GENERATE_INVOICES,
    GENERATE_INVOICES_ERROR,
} from "_models/redux/types";

// Action function
export const postGenerateInvoices = (params) => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.post(
            `/api/backoffice/accounting/generate-invoices-gp`,
            params
        );

        const { data, status } = response || {};

        dispatch({
            type: GENERATE_INVOICES,
            payload: data || {},
            status: status || {},
            method: "post",
        });
    } catch (e) {
        dispatch({
            type: GENERATE_INVOICES_ERROR,
            payload: e || {},
        });
    }
};
