import React from "react";
import { connect } from "react-redux";
import { getMyTickets } from "_models/redux/my-tickets/action";
import CustomerProfileHeaderUser from "../userTickets/components/customer-profile-header-user";
import ClientsTicketsDatagrid from "./widgets/datagrid";
import RowButton from "./row-button";

const ClientTicketsPage = () => {
    return (
        <div>
            <CustomerProfileHeaderUser />
            <RowButton />
            <ClientsTicketsDatagrid />
        </div>
    );
};

const mapStateToProps = (state) => ({ tickets: state.tickets });

const mapDispatchToProps = { getMyTickets };

export default connect(mapStateToProps, mapDispatchToProps)(ClientTicketsPage);
