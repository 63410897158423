import LinksHeader from "./widgets/header";
import LinksContent from "./widgets/content";

const LinksListPage = () => {
    return (
        <div>
            <LinksHeader />
            <LinksContent />
        </div>
    );
};

export default LinksListPage;
