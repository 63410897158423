import React from "react";
import {
    TextField,
    MenuItem,
    Grid,
    Select,
    InputLabel,
    FormControl,
} from "@mui/material";
import GenericAddButton from "widgets/GenericAddButton/GenericAddButton";
import SearchIcon from "@mui/icons-material/Search";

const FilterListing = ({
    handleSubmit,
    onSubmit,
    register,
    errors,
    fields,
}) => {
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} alignItems="center">
                {fields?.map((field, index) => (
                    <Grid item xs={12} xl={field.xl || 1} key={index}>
                        {field.type === "select" ? (
                            <FormControl fullWidth>
                                <InputLabel id={`${field.name}-label`}>
                                    {field.label}
                                </InputLabel>
                                <Select
                                    fullWidth
                                    {...register(field.name)}
                                    error={Boolean(errors[field.name])}
                                    helperText={errors[field.name]?.message}
                                >
                                    {field.options.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <TextField
                                fullWidth
                                label={field.label}
                                type={field.type}
                                {...register(field.name)}
                                InputLabelProps={
                                    field.type === "date"
                                        ? { shrink: true }
                                        : {}
                                }
                                error={Boolean(errors[field.name])}
                                helperText={errors[field.name]?.message}
                                onWheel={
                                    field.type === "number"
                                        ? (e) => e.target.blur()
                                        : undefined
                                }
                            />
                        )}
                    </Grid>
                ))}
                <Grid item xs={12} xl={1}>
                    <GenericAddButton
                        buttonText="Buscar"
                        backgroundColor="#4C2484"
                        size="medium"
                        isSubmit
                        icon={<SearchIcon />}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default FilterListing;
