import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from "@mui/material";

const MessageDialog = ({
    dialogOpen,
    setDialogOpen,
    messageModal,
    handleAccept,
}) => (
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Mensaje</DialogTitle>
        <DialogContent>
            <Typography variant="body1">{messageModal}</Typography>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => {
                    setDialogOpen(false);
                    handleAccept();
                }}
                color="primary"
            >
                Aceptar
            </Button>
        </DialogActions>
    </Dialog>
);

export default MessageDialog;
