import React from "react";
import { Grid, Button, Box, CircularProgress, Typography } from "@mui/material";
const titleStyle = { color: "#7A3C92" };
const valueStyle = {
    textAlign: "right",
    marginTop: "8px",
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
};
const Actions = ({
    order_details,
    getEsimQr,
    handleOpenDialog,
    handleRequest,
    loading,
}) => (
    <Grid container className="device-info">
        <Grid item xs={12}>
            <div>
                <span style={titleStyle}>Acciones</span>
            </div>
        </Grid>
        {order_details?.sim_type_id === 2 && (
            <>
                <Grid container alignItems="center" mb={2}>
                    <Grid item xs={6}>
                        <Typography variant="body1">URL del QR</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                onClick={() =>
                                    getEsimQr(order_details?.sim_order_id)
                                }
                                style={{
                                    backgroundColor: "#ede7f6",
                                    color: "#6a1b9a",
                                    textTransform: "none",
                                    width: "100px",
                                }}
                            >
                                Copiar
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container alignItems="center">
                    <Grid item xs={6}>
                        <Typography variant="body1">
                            Cambio eSIM a SIM
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                onClick={handleOpenDialog}
                                style={{
                                    backgroundColor: "#ede7f6",
                                    color: "#6a1b9a",
                                    textTransform: "none",
                                    width: "100px",
                                }}
                            >
                                Cambiar
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </>
        )}

        {order_details?.sim_type_id === 1 && (
            <Grid container alignItems="center">
                <Grid item xs={6}>
                    <Typography variant="body1">
                        Cambio de SIM a eSIM
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            onClick={handleOpenDialog}
                            style={{
                                backgroundColor: "#ede7f6",
                                color: "#6a1b9a",
                                textTransform: "none",
                                width: "100px",
                            }}
                        >
                            Cambiar
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        )}

        <Grid container alignItems="center" marginTop={3}>
            <Grid item xs={6}>
                <Typography variant="body1">Activación de SIM</Typography>
            </Grid>
            <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            style={{
                                backgroundColor: "#ede7f6",
                                color: "#6a1b9a",
                                textTransform: "none",
                                width: "100px",
                            }}
                            onClick={() =>
                                handleRequest(order_details?.sim_order_id)
                            }
                        >
                            Activar
                        </Button>
                    )}
                </Box>
            </Grid>
        </Grid>
    </Grid>
);

export default Actions;
