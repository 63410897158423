import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Container, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Chart } from "react-google-charts";

const ChartsPage = () => {
    const history = useNavigate();
    const data = [
        ["Flujo Compra", "Pasos"],
        ["Confirmacion de datos de pago", 3],
        ["Pago no exitoso", 26],
        ["Confirmacion direccion para envio de SIM", 36],
        ["Consulta de ofertas", 90],
        ["Informacion Basica", 100],
        ["Lead Creado", 952],
        ["Confirmacion de oferta", 97],
        ["Pago exitoso", 80],
        ["IMEI no compatible", 33],
        ["IMEI compatible", 22],
    ];

    const options = {
        height: 300,
        vAxis: { title: "Cantidad" },
    };

    const dataASD = [
        ["Tipo de SIM", "SIM", "eSIM", { role: "annotation" }],
        ["Dale 30", 1, 2, ""],
        ["Dale 50", 6, 4, ""],
        ["Dale 100", 1, 2, ""],
        ["Dale 120", 6, 2, ""],
        ["Dale 150", 5, 2, ""],
        ["Dale 200", 1, 2, ""],
        ["Dale 300", 4, 7, ""],
        ["Dale 500", 1, 2, ""],
        ["Dale 3 M", 5, 2, ""],
        ["Dale 6 M", 1, 12, ""],
        ["Dale 12 M", 7, 2, ""],
    ];

    const optionsASD = {
        height: 300,
        legend: { position: "top", maxLines: 3 },
        vAxis: { title: "Cantidad" },
        isStacked: "absolute",
    };

    useEffect(() => {
        return () => {};
    }, []);

    const onClickHandler = (link = "/samples") => {
        history(link);
    };

    return (
        <Container>
            <Grid container>
                <Grid item xs={12}>
                    <Button onClick={(e) => onClickHandler(e, "/samples")}>
                        <ArrowBackIcon />
                        Regresar
                    </Button>
                    <h4>Sample Charts</h4>
                </Grid>
            </Grid>

            <Grid container>
                <h5>SIM & eSIM</h5>

                <Grid item xs={12}>
                    <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="auto"
                        data={dataASD}
                        options={optionsASD}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <h5>Flujo Compra</h5>

                <Grid item xs={12}>
                    <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="auto"
                        data={data}
                        options={options}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChartsPage);
