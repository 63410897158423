import axiosTokenInstance from "../../../services/api/backoffice";
import { USERS, USERS_ERROR } from "_models/redux/types";

// Action function
export const getUsers = () => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(`/api/support/users`);

        const { data } = response || {};

        dispatch({
            type: USERS,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: USERS_ERROR,
            payload: e || {},
        });
    }
};
