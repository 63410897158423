import React from "react";
import { CircularProgress, Typography } from "@mui/material";
import CustomDialog from "widgets/component/CustomDialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTheme } from "@mui/material/styles";

const MessageConfirmationDialog = ({
    dialogTitle,
    dialogLoading,
    isOpenSuccesOrError,
    setOpenSuccesOrError,
    status,
    message,
    storeName,
    isRejecting,
}) => {
    const theme = useTheme();

    return (
        <CustomDialog
            title={dialogTitle}
            content={
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {status === "success" ? (
                        <>
                            <CheckCircleIcon
                                style={{
                                    fontSize: 50,
                                    color: "#724ECB",
                                }}
                            />
                            <Typography
                                variant="h6"
                                style={{ marginTop: 16, textAlign: "center" }}
                            >
                                {isRejecting
                                    ? `La tienda ${storeName} ha sido rechazada`
                                    : `La tienda ${storeName} ha sido aceptada`}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <CancelIcon
                                style={{
                                    fontSize: 50,
                                    color: theme.palette.error.main,
                                }}
                            />
                            <Typography
                                variant="h6"
                                style={{ marginTop: 16, textAlign: "center" }}
                            >
                                {message}
                            </Typography>
                        </>
                    )}

                    {dialogLoading && <CircularProgress />}
                </div>
            }
            open={isOpenSuccesOrError}
            onClose={() => setOpenSuccesOrError(false)}
            fullWidth
            maxWidth="sm"
            extraButtons={[
                {
                    label: "Aceptar",
                    onClick: () => setOpenSuccesOrError(false),
                    buttonColor: "primary",
                    textColor: "#595959",
                },
            ]}
            buttonPosition="center"
        />
    );
};

export default MessageConfirmationDialog;
