// TextFieldWithError.jsx
import React from "react";
import { TextField, MenuItem } from "@mui/material";

const TextFieldWithError = ({
    register,
    registerOptions,
    label,
    variant,
    fullWidth,
    multiline,
    rows,
    value,
    onChange,
    errors,
    errorKey,
    select,
    options,
    placeholder,
    id,
    name,
    InputLabelProps,
    className,
    sx,
    type,
}) => (
    <>
        <TextField
            {...(register && register(name, registerOptions))}
            label={label}
            variant={variant}
            fullWidth={fullWidth}
            multiline={multiline}
            rows={rows}
            value={value}
            onChange={onChange}
            select={select}
            placeholder={placeholder}
            id={id}
            name={name}
            InputLabelProps={InputLabelProps}
            className={className}
            sx={sx}
            type={type}
        >
            {select &&
                options?.map((option) => (
                    <MenuItem key={option?.id} value={option?.id || 0}>
                        {option?.name}
                    </MenuItem>
                ))}
        </TextField>
        <span>
            {errors?.[errorKey] && (
                <p className="required-incidence m-0">
                    {errors?.[errorKey].message}
                </p>
            )}
        </span>
    </>
);

export default TextFieldWithError;
