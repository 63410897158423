import React from "react";
import { Grid, Typography } from "@mui/material";
import { payerFieldsSTP } from "./data/DetailsSTP";
import { payerFieldsToku } from "./data/DetailsToku";
import { getRequestDetails } from "./data/DetailsNetPay";
import { getBasicTransactionDetails } from "./data/DetailsHistoryPrepaid";
import { getOrderDetails } from "./data/DetailsSimIpb";
import { getClientDetails } from "./data/DetailPurchase";
import { getAmountDetails } from "./data/DetailsOpenPay";

const spanStyle = { fontSize: 15, wordWrap: "break-word" };
const titleStyle = { color: "#7A3C92" };
const valueStyle = {
    textAlign: "right",
    marginTop: "8px",
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

const PayerInfo = ({ orderDetails, title, type }) => {
    let fields = [];

    if (type === "detailsSTP") {
        fields = payerFieldsSTP(orderDetails);
    } else if (type === "detailsToku") {
        fields = payerFieldsToku(orderDetails);
    } else if (type === "detailsNetPay") {
        fields = getRequestDetails(orderDetails);
    } else if (type === "detailsHistoryPrepaid") {
        fields = getBasicTransactionDetails(orderDetails);
    } else if (type === "detailsSimIpb") {
        fields = getOrderDetails(orderDetails);
    } else if (type === "detailPurchase") {
        fields = getClientDetails(orderDetails);
    } else if (type === "detailsOpenPay") {
        fields = getAmountDetails(orderDetails);
    }

    return (
        <Grid item xs={6} direction={"column"}>
            <Grid container className="device-info">
                <Grid item xs={12}>
                    <span style={titleStyle}>{title}</span>
                </Grid>
                {fields.map((field, index) => (
                    <React.Fragment key={index}>
                        <Grid item xs={6}>
                            <div>
                                <span style={spanStyle}>{field.label}</span>
                            </div>
                        </Grid>
                        <Grid item xs={6} style={valueStyle}>
                            <div>
                                <Typography variant="body1">
                                    {field.value || "N/A"}
                                </Typography>
                            </div>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Grid>
    );
};

export default PayerInfo;
