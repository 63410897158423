// Import: Middleware Action & Custom Types
import {
    CLEAN_GET_CAMPAIGN_CHANNEL,
    CLEAN_GET_CAMPAIGN_CHANNEL_ERROR,
} from "_models/redux/types";

// Action function
export const cleanGetCampaignChannelAction = () => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        dispatch({
            payload: undefined,
            type: CLEAN_GET_CAMPAIGN_CHANNEL,
        });
    } catch (e) {
        dispatch({
            payload: undefined,
            type: CLEAN_GET_CAMPAIGN_CHANNEL_ERROR,
        });
    }
};
