import React from "react";
import PaymentInfo from "./PaymentInfo";
const PaymentSection = ({ type, orderDetails }) => (
    <PaymentInfo
        type={type}
        title={
            type === "detailsHistoryPrepaid"
                ? "Detalles del Distribuidor y Descuento"
                : type === "detailsSimIpb"
                  ? "Dirección del Beneficiario"
                  : "Información del Pago"
        }
        orderDetails={orderDetails}
    />
);
export default PaymentSection;
