import React from "react";
import { Grid, Divider } from "@mui/material";

const titleStyle = { color: "#7A3C92" };
const valueStyle = {
    textAlign: "right",
    marginTop: "8px",
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

const PurchaseSummary = ({ product }) => (
    <Grid container className="device-info">
        <Grid item xs={12}>
            <span style={titleStyle}>Resumen de compra</span>
        </Grid>
        <Grid item xs={6}>
            <div>
                <span>Producto</span>
            </div>
            <div>
                <span>Costo</span>
            </div>
            <div>
                <span>Costo SIM/eSIM</span>
            </div>
            <div>
                <span>Costo envío</span>
            </div>
        </Grid>
        <Grid item xs={6}>
            <div style={valueStyle}>{product?.product_name}</div>
            <div style={valueStyle}>${product?.prepaid_cost}</div>
            <div style={valueStyle}>${product?.sim_esim_cost}</div>
            <div style={valueStyle}>${product?.shipping_cost}</div>
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid item xs={6}>
            <div>
                <p>Total</p>
            </div>
        </Grid>
        <Grid item xs={6}>
            <div style={valueStyle}>
                <p>${product?.total_amount}</p>
            </div>
        </Grid>
    </Grid>
);

export default PurchaseSummary;
