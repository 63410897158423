import React from "react";
import OrderAdditionalInfo from "./OrderAdditionalInfo";
const AdditionalInfoSection = ({ type, orderDetails, controllerProps }) => (
    <>
        <OrderAdditionalInfo
            labelSimType={controllerProps.purchase}
            type={type}
            title={
                type === "detailsHistoryPrepaid"
                    ? "Fechas de Transacción"
                    : type === "detailsSimIpb"
                      ? "Fechas de Transacción"
                      : "Información Adicional"
            }
            orderDetails={orderDetails}
        />
    </>
);
export default AdditionalInfoSection;
