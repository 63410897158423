import React from "react";
import { Chip } from "@mui/material";

const SimTypeChip = ({ simTypeName }) => {
    let backgroundColor = "inherit";
    let color = "inherit";

    switch (simTypeName) {
        case 1:
        case "SIM":
            backgroundColor = "#FFCCF8";
            color = "#67077A";
            simTypeName = "SIM";
            break;
        case "E-SIM":
        case "Esim":
        case "eSIM":
        case 2:
            backgroundColor = "#DBD8F5";
            color = "#261692";
            simTypeName = "eSIM";
            break;
        case 3:
        case "MiFi":
            backgroundColor = "#e17522";
            color = "#fff";
            simTypeName = "MiFi";
            break;
        case 4:
        case "IoT":
            backgroundColor = "#1863c7";
            color = "#fff";
            simTypeName = "IoT";
            break;
        default:
            simTypeName = "N/A";
            break;
    }

    const style = {
        backgroundColor,
        color,
        padding: "1px 6px",
        borderRadius: "20px",
        fontSize: "12px",
    };

    return <Chip label={simTypeName} style={style} />;
};

export default SimTypeChip;
