import React from "react";
import TransactionCard from "./TransactionCard";

const TransactionCardWrapper = ({
    namePayment,
    type,
    setTitle,
    title,
    response,
    idTransaction,
    onClose,
    isLoading,
    setIdReverso,
}) => (
    <TransactionCard
        response={response}
        namePayment={namePayment}
        type={type}
        setTitle={setTitle}
        title={title}
        transactionType={
            response?.transaction_type?.payment_type ||
            response?.payment_provider?.name ||
            response?.provider_type?.name
        }
        idTransaction={idTransaction}
        onClose={onClose}
        payment_type={
            response?.transaction_type?.payment_type ||
            response?.provider_type?.name
        }
        isLoading={isLoading}
        setIdReverso={setIdReverso}
    />
);
export default TransactionCardWrapper;
