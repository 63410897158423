import React from "react";
import OrderIdentifiersGrid from "./OrderIdentifiersGrid";

const IdentifiersSection = ({ type, orderDetails }) => (
    <OrderIdentifiersGrid
        type={type}
        title={
            type === "detailsHistoryPrepaid"
                ? "Detalles de la Respuesta"
                : type === "detailsSimIpb"
                  ? "Identificadores"
                  : type === "detailPurchase"
                    ? "Forma de Pago"
                    : "Identificadores"
        }
        orderDetails={orderDetails}
    />
);

export default IdentifiersSection;
