import {
    CLEAN_IPB_DIRECTORY_MODERATION_LISTING,
    CLEAN_IPB_DIRECTORY_MODERATION_LISTING_ERROR,
} from "_models/redux/types";

export const cleanIpbDirectoryModerationListingAction =
    () => async (dispatch) => {
        try {
            dispatch({
                payload: undefined,
                type: CLEAN_IPB_DIRECTORY_MODERATION_LISTING,
            });
        } catch {
            dispatch({
                payload: undefined,
                type: CLEAN_IPB_DIRECTORY_MODERATION_LISTING_ERROR,
            });
        }
    };
