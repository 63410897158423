import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    CircularProgress,
    DialogActions,
    Button,
} from "@mui/material";

const FraudDialog = ({
    openActionFraud,
    handleClose,
    fraudChargebackPopupTitle,
    setDescriptionTransaction,
    setError,
    error,
    loadingFraudAndChargeback,
    dialogMessage,
    isError,
    handleConfirm,
    detailTransactionChargeback,
    detailTransactionFraud,
}) => (
    <Dialog open={openActionFraud} onClose={handleClose}>
        <DialogTitle>
            ¿Estás seguro de reportar esta transacción{" "}
            <b>
                {fraudChargebackPopupTitle
                    ? "como " + fraudChargebackPopupTitle
                    : ""}
            </b>
            ?
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {"Una vez que ejecutes esta acción, no podrás deshacerla."}
            </DialogContentText>
            <br />
            <TextField
                autoFocus
                margin="dense"
                id="description"
                label="Descripción"
                type="text"
                fullWidth
                onChange={(event) => {
                    setDescriptionTransaction(event.target.value);
                }}
                onBlur={(event) => {
                    if (event.target.value.length > 1) {
                        setError(event.target.value.length < 5);
                    }
                }}
                error={error}
                helperText={
                    error ? "Este campo requiere al menos 5 caracteres" : ""
                }
            />
            {loadingFraudAndChargeback && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                </div>
            )}
            {dialogMessage && (
                <DialogContentText style={{ color: isError ? "red" : "green" }}>
                    {dialogMessage}
                </DialogContentText>
            )}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cerrar
            </Button>
            {(detailTransactionChargeback?.status !== 200 ||
                detailTransactionFraud?.status !== 200) && (
                <Button
                    onClick={handleConfirm}
                    color="primary"
                    autoFocus
                    disabled={error}
                >
                    Aceptar
                </Button>
            )}
        </DialogActions>
    </Dialog>
);

export default FraudDialog;
