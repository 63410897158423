import { CLEAN_OPENPAY, CLEAN_OPENPAY_ERROR } from "_models/redux/types";

export const cleanOpenPayAction = () => async (dispatch) => {
    try {
        dispatch({
            payload: undefined,
            type: CLEAN_OPENPAY,
        });
    } catch (e) {
        dispatch({
            payload: undefined,
            type: CLEAN_OPENPAY_ERROR,
        });
    }
};
