import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";

export const getOrderInfoDetails = (orderDetails, dateSale) => [
    {
        label: "Número Dalefon",
        value: orderDetails?.direct_number,
    },
    {
        label: "Lead ID",
        value: orderDetails?.lead_id,
    },
    {
        label: "Número de orden",
        value: orderDetails?.order_number,
    },
    {
        label: "Fecha de compra",
        value: convertToMexicoTime(orderDetails?.order_created),
    },
];

export const getClientDetails = (client) => [
    {
        label: "Nombre del cliente",
        value: client?.name ? client.name : "No disponible",
    },
    {
        label: "Teléfono",
        value: client?.phone ? client.phone : "No disponible",
    },
    {
        label: "Correo",
        value: client?.email ? client.email : "No disponible",
    },
    {
        label: "Dirección",
        value: client?.address ? client.address : "No disponible",
    },
    {
        label: "Correo del ticket",
        value: client?.ticket_email ? client.ticket_email : "No disponible",
    },
];

export const getDeviceDetails = (device, labelSimType) => [
    {
        label: "Marca",
        value: device?.brand ? device.brand : "No disponible",
    },
    {
        label: "Modelo",
        value: device?.model ? device.model : "No disponible",
    },
    {
        label: "IMEI",
        value: device?.imei ? device.imei : "No disponible",
    },
];

export const getPurchaseDetails = (product) => [
    {
        label: "Producto",
        value: product?.product_name ? product.product_name : "No disponible",
    },
    {
        label: "Costo",
        value: product?.prepaid_cost
            ? `$${product.prepaid_cost}`
            : "No disponible",
    },
    {
        label: "Costo SIM/eSIM",
        value: product?.sim_esim_cost
            ? `$${product.sim_esim_cost}`
            : "No disponible",
    },
    {
        label: "Costo envío",
        value: product?.shipping_cost
            ? `$${product.shipping_cost}`
            : "No disponible",
    },
    {
        label: "Total",
        value: product?.total_amount
            ? `$${product.total_amount}`
            : "No disponible",
    },
];

export const getPaymentDetails = (payment) => [
    {
        label: "Tarjeta",
        value: payment?.maked_card ? payment.maked_card : "No disponible",
    },
    {
        label: "Autorización",
        value: payment?.authorization_number
            ? payment.authorization_number
            : "No disponible",
    },
    {
        label: "Ejecutivo",
        value: payment?.backoffice_user_name
            ? payment.backoffice_user_name
            : "No disponible",
    },
];
