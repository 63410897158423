import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import animationData from "assets/images/generate-invoice/loader.json";

export const useInvoiceEditHook = ({
    postEditLegalData,
    cleanEditLegalData,
    responseEditLegalData,
}) => {
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (responseEditLegalData?.status === 200) {
            setLoading(false);
            setResponseMessage("Datos legales actualizados exitosamente");
            setIsError(false);
            cleanEditLegalData();
            reset();
        } else if (responseEditLegalData?.payload?.response?.status > 300) {
            setLoading(false);
            setResponseMessage(
                responseEditLegalData?.payload?.response?.data?.detail ||
                    "Hubo un error al actualizar los datos legales."
            );
            setIsError(true);
        }
    }, [responseEditLegalData, reset, cleanEditLegalData]);

    useEffect(() => {
        if (responseMessage) {
            setShowMessage(true);
            const timer = setTimeout(() => {
                setShowMessage(false);
                setResponseMessage("");
            }, 4000);

            return () => clearTimeout(timer);
        }
    }, [responseMessage]);

    const onSubmit = (data) => {
        console.log(data);
        setLoading(true);
        postEditLegalData(data);
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return {
        register,
        handleSubmit,
        errors,
        onSubmit,
        loading,
        responseMessage,
        isError,
        showMessage,
        defaultOptions,
    };
};
