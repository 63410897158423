import React from "react";
import ChangeSimDialog from "../components/ChangeSimDialog";

const ChangeSimDialogComponent = ({
    isDialogOpen,
    handleCloseDialog,
    handleSubmit,
    handleCopyToClipboard,
    register,
    registerOptions,
    description,
    handleDescriptionChange,
    errors,
    order_details,
    newOne,
    newTwo,
    setValue,
    inputChange,
    stateShrink,
    municipalityShrink,
    neighborhoodShrink,
    states,
    municipalities,
    neighborhoods,
    zipcodeShrink,
    addressShrink,
    referenceInput,
    contactPhoneShrink,
}) =>
    isDialogOpen && (
        <ChangeSimDialog
            isDialogOpen={isDialogOpen}
            handleCloseDialog={handleCloseDialog}
            handleSubmit={handleSubmit}
            handleCopyToClipboard={handleCopyToClipboard}
            register={register}
            registerOptions={registerOptions}
            description={description}
            handleDescriptionChange={handleDescriptionChange}
            errors={errors}
            order_details={order_details}
            newOne={newOne}
            newTwo={newTwo}
            setValue={setValue}
            inputChange={inputChange}
            stateShrink={stateShrink}
            municipalityShrink={municipalityShrink}
            neighborhoodShrink={neighborhoodShrink}
            states={states}
            municipalities={municipalities}
            neighborhoods={neighborhoods}
            zipcodeShrink={zipcodeShrink}
            addressShrink={addressShrink}
            referenceInput={referenceInput}
            contactPhoneShrink={contactPhoneShrink}
        />
    );

export default ChangeSimDialogComponent;
