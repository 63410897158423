import {
    CLEAN_DETAIL_TRANSACTIONS_OPENPAY,
    CLEAN_DETAIL_TRANSACTIONS_OPENPAY_ERROR,
} from "_models/redux/types";

export const cleanDetailOpenPayAction = () => async (dispatch) => {
    try {
        dispatch({
            payload: undefined,
            type: CLEAN_DETAIL_TRANSACTIONS_OPENPAY,
        });
    } catch (e) {
        dispatch({
            payload: undefined,
            type: CLEAN_DETAIL_TRANSACTIONS_OPENPAY_ERROR,
        });
    }
};
