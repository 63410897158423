import React from "react";
import { connect } from "react-redux";
import InvoiceForm from "./components/InvoiceForm";
import { postGenerateInvoices } from "_models/redux/generate-invoice/action";
import { cleanGenerateInvoices } from "_models/redux/generate-invoice/clean";

const PageInvoice = ({
    postGenerateInvoices,
    cleanGenerateInvoices,
    generateInvoices,
}) => {
    return (
        <div>
            <h1>Facturación</h1>
            <InvoiceForm
                postGenerateInvoices={postGenerateInvoices}
                generateInvoices={generateInvoices}
                cleanGenerateInvoices={cleanGenerateInvoices}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    generateInvoices: state?.generateInvoices,
});

const mapDispatchToProps = {
    postGenerateInvoices,
    cleanGenerateInvoices,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageInvoice);
