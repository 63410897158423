import React from "react";
import {
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorModal from "widgets/component/ErrorModal";

const Details = ({ rows, handleMySalesDetails }) => (
    <Grid container className="my-sales container-2">
        <Grid item xs={12} md={12}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Ver Detalle</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div
                        style={{
                            height: "auto",
                            width: "auto",
                        }}
                        className={"leads-list"}
                    >
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell align="left">
                                            Cantidad
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows?.result?.map((row) => (
                                        <TableRow
                                            key={row.date}
                                            sx={{
                                                "&:last-child td, &:last-child th":
                                                    {
                                                        border: 0,
                                                    },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {row.date}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    onClick={() =>
                                                        handleMySalesDetails(
                                                            row.date
                                                        )
                                                    }
                                                >
                                                    {row.total_sales}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <ErrorModal />
                    </div>
                </AccordionDetails>
            </Accordion>
        </Grid>
    </Grid>
);

export default Details;
