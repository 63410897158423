import React from "react";
import { connect } from "react-redux";
import LeadsHeader from "./widgets/header";
import LeadsDatagrid from "./widgets/datagrid";

const LeadsListPage = () => {
    return (
        <div>
            <LeadsHeader />
            <LeadsDatagrid />
        </div>
    );
};

const mapStateToProps = (state) => ({ tickets: state.tickets });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsListPage);
