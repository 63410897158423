import React from "react";
import { Box } from "@mui/material";
import CustomDialog from "widgets/component/CustomDialog";

const ImageDialog = ({ open, onClose, selectedImage }) => (
    <CustomDialog
        open={open}
        onClose={onClose}
        title="Fotografía de la tienda"
        content={
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {selectedImage && (
                    <img
                        src={selectedImage}
                        alt="Fotografía de la tienda"
                        style={{ maxWidth: "100%", maxHeight: "80vh" }}
                    />
                )}
            </Box>
        }
        fullWidth
        maxWidth="md"
    />
);

export default ImageDialog;
