import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";
import ActionLabels from "utils/ActionLabelsDatagrid";
import ActionsMenu from "utils/ActionsMenuDatagrid";

export const PageTokuHook = ({
    listTokuAction,
    cleanTransactionsTokuClean,
    detailsTransactionsToku,
    listTransactionsToku,
    detailTransactionTokuAction,
    cleanDetailTokuAction,
    actionReportTransactionsFraud,
    cleanReportTransactionsFraud,
    cleanReportPrepaidChargebackDetails,
    reportTransactionsFraud,
    actionReportTransactionsChargeback,
    reportTransactionsChargeback,
    transactionId,
    onClose,
}) => {
    const { payload } = listTransactionsToku || {};
    const { response } = detailsTransactionsToku || {};
    const { rows, pagination } = payload || {};

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState(false);
    const [isDialogOpenDetails, setIsDialogOpenDetails] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [action, setAction] = useState(null);
    const [openActionFraud, setOpenActionFraud] = useState(false);
    const [descriptionTransaction, setDescriptionTransaction] = useState("");
    const [loadingFraudAndChargeback, setLoadingFraudAndChargeback] =
        useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [error, setError] = useState(false);
    const [isError, setIsError] = useState(false);
    const [IdOrder, setIDorder] = useState("");
    const [mappedRows, setMappedRows] = useState([]);

    useEffect(() => {
        if (transactionId) {
            setLoading(true);
            setIsDialogOpenDetails(true);
            detailTransactionTokuAction(transactionId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionId, setIsDialogOpenDetails]);

    useEffect(() => {
        if (response?.response?.status > 200) {
            setDialogMessage("Error");
            setDialogMessage(response?.response?.data?.detail);
            setIsDialogOpen(true);
            setLoading(false);
            setIsDialogOpenDetails(true);
        }
    }, [response]);

    useEffect(() => {
        // Verificar si searchParams tiene algún valor
        if (Object.keys(searchParams).length !== 0) {
            setLoading(true);
            listTokuAction(page, size, searchParams)
                .then(() => setLoading(false))
                .catch(() => {
                    setLoading(false);
                });
        }
        return () => {
            cleanTransactionsTokuClean();
        };
    }, [page, size, searchParams, listTokuAction, cleanTransactionsTokuClean]);

    useEffect(() => {
        if (listTransactionsToku?.status === 200) {
            setLoading(false);
        } else if (listTransactionsToku?.payload?.response?.status > 200) {
            setDialogMessage(
                listTransactionsToku?.payload?.response?.data?.detail
            );
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [listTransactionsToku]);

    useEffect(() => {
        if (rows) {
            const updatedRows = rows.map((row) => ({
                ...row,
                id: `${row.id}`,
            }));
            setMappedRows(updatedRows);
        }
    }, [rows]);

    const onSubmit = (data) => {
        const { from_date, to_date, status_id, authorization_code } = data;

        const areDatesProvided = from_date !== "" && to_date !== "";
        const isAuthorizationOrCardProvided =
            status_id !== "" || authorization_code !== "";

        if (areDatesProvided || isAuthorizationOrCardProvided) {
            setLoading(true);
            setPage(1);
            const filteredData = Object.fromEntries(
                // eslint-disable-next-line no-unused-vars
                Object.entries(data).filter(([_, value]) => value !== "")
            );
            setSearchParams(filteredData);
            setErrorMessage("");
        } else {
            setErrorMessage(
                "Por favor, proporcione al menos un rango de fechas, estado, monto o autorización."
            );
        }
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangePageSize = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setSize(newSize);
        setPage(1);
    };

    const handleIdButtonClick = (id) => {
        setIDorder(id);
        if (id) {
            setIsDialogOpenDetails(true);
            detailTransactionTokuAction(id)
                .then(() => setLoading(false))
                .catch(() => {
                    setDialogMessage("Error");
                    setDialogMessage(
                        "Error al cargar los detalles de la transferencia."
                    );
                    setIsDialogOpen(true);
                    setLoading(false);
                });

            return () => {
                cleanDetailTokuAction();
            };
        }
    };

    const handleFraudPrevention = (data) => {
        setLoadingFraudAndChargeback(true);
        const payload = {
            txn_payment_id: data,
            descriptionTransaction,
            payment_provider_name: "toku",
        };
        actionReportTransactionsFraud({ ...payload });
    };

    const handleChargebackPrevention = (data) => {
        setLoadingFraudAndChargeback(true);
        const payload = {
            txn_payment_id: data,
            descriptionTransaction,
            payment_provider_name: "toku",
        };
        actionReportTransactionsChargeback({ ...payload });
    };

    const handleCloseFraudChargeback = () => {
        setOpenActionFraud(false);
        setDescriptionTransaction("");
        setError(false);
        setDialogMessage("");

        if (action === "fraud") {
            cleanReportTransactionsFraud();
        } else if (action === "chargeback") {
            cleanReportPrepaidChargebackDetails();
        }

        setLoading(true);
        listTokuAction(page, size, searchParams)
            .then(() => setLoading(false))

            .catch(() => {
                setLoading(false);
            });
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setIsDialogOpenDetails(false);
        cleanDetailTokuAction();
        if (transactionId) {
            onClose();
        }
    };

    const handleOpenPopUp = (action, rowId) => {
        setAction(action);
        setSelectedRowId(rowId);
        setOpenActionFraud(true);
    };

    const handleConfirm = () => {
        if (descriptionTransaction === "") {
            setError(true);
        } else {
            setError(false);
            if (action === "fraud") {
                handleFraudPrevention(selectedRowId);
            } else if (action === "chargeback") {
                handleChargebackPrevention(selectedRowId);
            }
        }
    };

    useEffect(() => {
        if (reportTransactionsChargeback?.status === 200) {
            setDialogMessage("La operación se completó con éxito.");
            setIsError(false);
            setLoadingFraudAndChargeback(false);
            detailTransactionTokuAction(IdOrder);
        } else if (
            reportTransactionsChargeback?.payload?.response?.status > 200
        ) {
            setLoadingFraudAndChargeback(false);
            setDialogMessage(
                reportTransactionsChargeback?.payload?.response?.data?.detail ||
                    "Ha ocurrido un error."
            );
            setIsError(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportTransactionsChargeback]);

    useEffect(() => {
        if (reportTransactionsFraud?.status === 200) {
            setDialogMessage("La operación se completó con éxito.");
            setIsError(false);
            setLoadingFraudAndChargeback(false);
            detailTransactionTokuAction(IdOrder);
        } else if (reportTransactionsFraud?.payload?.response?.status > 200) {
            setLoadingFraudAndChargeback(false);
            setDialogMessage(
                reportTransactionsFraud?.payload?.response?.data?.detail ||
                    "Ha ocurrido un error."
            );
            setIsError(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportTransactionsFraud]);

    const Columns = [
        {
            field: "id",
            headerName: "ID",
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    onClick={() => handleIdButtonClick(params.row.id)}
                >
                    {"Ver detalle"}
                </Button>
            ),
        },
        {
            field: "prevention",
            headerName: "Prevención",
            width: 200,
            headerAlign: "center",
            renderCell: (params) => (
                <ActionLabels
                    isFraud={params.row.is_fraud}
                    isChargeback={params.row.is_chargeback}
                />
            ),
        },
        {
            field: "created",
            headerName: "Fecha DB",
            width: 250,
            headerAlign: "center",
            valueGetter: (params) => {
                return convertToMexicoTime(params.row.created);
            },
        },
        {
            field: "toku_transaction_date",
            headerName: "Fecha Toku",
            width: 250,
            headerAlign: "center",
            valueGetter: (params) => {
                return convertToMexicoTime(params.row.toku_transaction_date);
            },
        },
        {
            field: "installments",
            headerName: "Mensualidades",
            width: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const value = params.value;
                return value === 1 ? "Contado" : `${value} meses`;
            },
        },
        {
            field: "customer_name",
            headerName: "Nombre del cliente",
            width: 220,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "gateway",
            headerName: "Gateway",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "payment_method",
            headerName: "Método de pago",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "payment_status",
            headerName: "Estado de pago",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        /* eslint-disable no-undef */
        {
            field: "amount",
            headerName: "Cantidad",
            width: 200,
            align: "right",
            headerAlign: "center",
            valueFormatter: (params) => {
                const formatter = new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 2,
                });
                return formatter.format(params.value);
            },
            renderCell: (params) => <div>{params.formattedValue}</div>,
        },
        /* eslint-enable no-undef */
        {
            field: "authorization_code",
            headerName: "Código de autorización",
            width: 200,
            align: "right",
            headerAlign: "center",
        },
        {
            field: "toku_payment_status",
            headerName: "Estado de pago Toku",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "buy_order",
            headerName: "Orden de compra",
            width: 200,
            align: "right",
            headerAlign: "center",
        },
        {
            field: "card_detail",
            headerName: "Detalle de la tarjeta",
            width: 200,
            align: "right",
            headerAlign: "center",
        },
        {
            field: "toku_response_code",
            headerName: "Código de respuesta Toku",
            width: 250,
            align: "right",
            headerAlign: "center",
        },
        {
            field: "actions",
            headerName: "Acciones",
            width: 200,
            headerAlign: "center",
            renderCell: (params) => (
                <ActionsMenu
                    onFraud={() => handleOpenPopUp("fraud", params.id)}
                    onChargeback={() =>
                        handleOpenPopUp("chargeback", params.id)
                    }
                />
            ),
        },
    ];

    return {
        register,
        handleSubmit,
        errors,
        onSubmit,
        loading,
        page,
        size,
        handleChangePage,
        pagination: {
            ...pagination,
            handleChangePage,
            handleChangePageSize,
        },
        rows,
        mappedRows,
        Columns,
        isDialogOpen,
        setIsDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        setErrorMessage,
        openActionFraud,
        setDescriptionTransaction,
        setError,
        error,
        handleConfirm,
        loadingFraudAndChargeback,
        isError,
        handleCloseFraudChargeback,
        handleOpenPopUp,
    };
};
