import axiosTokenInstance from "../../../services/api/backoffice";

import {
    INCIDENCE_CATEGORY,
    INCIDENCE_CATEGORY_ERROR,
} from "_models/redux/types";

// Action function
export const getIncidenceCategory = () => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/catalogs/support-tickets-categories`
        );

        const { data } = response || {};

        dispatch({
            type: INCIDENCE_CATEGORY,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: INCIDENCE_CATEGORY_ERROR,
            payload: e || {},
        });
    }
};
