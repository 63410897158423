import React from "react";
import { Grid } from "@mui/material";

const ShippingDetailHeaderComponent = () => {
    return (
        <Grid container className="page-header">
            <Grid item xs={12}>
                <Grid container direction={"row"}>
                    <Grid item xs={6} textAlign={"start"}>
                        <h3>Detalle de envíos</h3>
                    </Grid>
                    <Grid item xs={6} textAlign={"end"}>
                        {/* <button
                            className="right openModalBtn"
                            onClick={() => {
                                setModalOpen(true);
                            }}
                        >
                            <AddIcon className="svg" />
                            Nuevo Ticket
                        </button> */}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ShippingDetailHeaderComponent;
