import React from "react";
import {
    TextField,
    Button,
    Container,
    Typography,
    Card,
    CardContent,
    Box,
    Alert,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from "@mui/material";
import { useInvoiceEditHook } from "../hooks/useInvoiceEditHook";
import Lottie from "react-lottie";
import { taxRegimeList } from "utils/invoice-tax-regime-options/invoiceTaxRegimeOptions";

const InvoiceEditForm = ({
    postEditLegalData,
    cleanEditLegalData,
    responseEditLegalData,
}) => {
    const {
        loading,
        register,
        handleSubmit,
        errors,
        onSubmit,
        responseMessage,
        isError,
        showMessage,
        defaultOptions,
    } = useInvoiceEditHook({
        postEditLegalData,
        cleanEditLegalData,
        responseEditLegalData,
    });

    const { taxRegimeOptions } = taxRegimeList();

    return (
        <Container>
            <Box mt={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Editar Factura
                </Typography>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="h2" gutterBottom>
                            Información de Factura
                        </Typography>
                        {showMessage && (
                            <Box mb={2}>
                                <Alert severity={isError ? "error" : "success"}>
                                    {responseMessage}
                                </Alert>
                            </Box>
                        )}
                        {loading ? (
                            <Box display="flex" justifyContent="center" mt={4}>
                                <Lottie
                                    options={defaultOptions}
                                    height={300}
                                    width={300}
                                />
                            </Box>
                        ) : (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Box mb={2}>
                                    <TextField
                                        label="RFC"
                                        {...register("legal_id", {
                                            required: "RFC es requerido",
                                        })}
                                        error={Boolean(errors.legal_id)}
                                        helperText={
                                            errors.legal_id
                                                ? errors.legal_id.message
                                                : ""
                                        }
                                        fullWidth
                                        margin="normal"
                                    />
                                </Box>
                                <Box mb={2}>
                                    <FormControl
                                        fullWidth
                                        margin="normal"
                                        error={Boolean(errors.tax_regime)}
                                    >
                                        <InputLabel id="tax-regime-label">
                                            Régimen Fiscal
                                        </InputLabel>
                                        <Select
                                            labelId="tax-regime-label"
                                            label="Régimen Fiscal"
                                            {...register("tax_regime", {
                                                required:
                                                    "Régimen Fiscal es requerido",
                                            })}
                                        >
                                            {taxRegimeOptions.map((option) => (
                                                <MenuItem
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {`${option.id} - ${option.name}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.tax_regime && (
                                            <Typography color="error">
                                                {errors.tax_regime.message}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        label="Código Postal"
                                        {...register("zipcode", {
                                            required:
                                                "Código Postal es requerido",
                                        })}
                                        error={Boolean(errors.zipcode)}
                                        helperText={
                                            errors.zipcode
                                                ? errors.zipcode.message
                                                : ""
                                        }
                                        fullWidth
                                        margin="normal"
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField
                                        label="Dirección"
                                        {...register("address", {
                                            required: "Dirección es requerida",
                                        })}
                                        error={Boolean(errors.address)}
                                        helperText={
                                            errors.address
                                                ? errors.address.message
                                                : ""
                                        }
                                        fullWidth
                                        margin="normal"
                                    />
                                </Box>
                                <Box
                                    mt={3}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={loading}
                                    >
                                        Guardar
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
};

export default InvoiceEditForm;
