import axiosTokenInstance from "../../../services/api/backoffice";

import { TICKETS, TICKETS_ERROR } from "_models/redux/types";

// Action function
export const getMyTickets = () => async (dispatch) => {
    try {
        const response = await axiosTokenInstance.get(
            `/api/support/tickets/assigned-to-me`
        );

        const { data } = response || {};

        dispatch({
            type: TICKETS,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: TICKETS_ERROR,
            payload: e || {},
        });
    }
};
