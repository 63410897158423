import axiosTokenInstance from "../../../services/api/backoffice";

import { GENERAL_TICKETS, GENERAL_TICKETS_ERROR } from "_models/redux/types";

// Action function
export const getTickets = () => async (dispatch) => {
    try {
        const response = await axiosTokenInstance.get(
            `/api/support/tickets/in-progress`
        );

        const { data } = response || {};

        dispatch({
            type: GENERAL_TICKETS,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: GENERAL_TICKETS_ERROR,
            payload: e || {},
        });
    }
};
