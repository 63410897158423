import axiosTokenInstance from "services/api/backoffice";
import { SALE_ORDERS, SALE_ORDERS_ERROR } from "_models/redux/types";

export const getSaleOrdersAction = (params) => async (dispatch) => {
    const {
        number_order,
        phone_number_order,
        date_order,
        email_order,
        name_order,
    } = params || {};

    const object = {};

    if (date_order !== "") {
        object.date = date_order;
    }
    if (number_order !== "") {
        object.order_number = number_order;
    }
    if (name_order !== "") {
        object.name = name_order;
    }
    if (phone_number_order !== "") {
        object.phone = phone_number_order;
    }
    if (email_order !== "") {
        object.email = email_order;
    }

    const objString = "?" + new URLSearchParams(object).toString();

    const url = "/api/orders/search";

    try {
        const response = await axiosTokenInstance.get(`${url}${objString}`);

        const { data, status } = response || {};

        dispatch({
            method: "get",
            payload: data || {},
            status: status || null,
            type: SALE_ORDERS,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            method: "get",
            payload: e || {},
            status: e?.response?.status || null,
            type: SALE_ORDERS_ERROR,
        });
    }
};
