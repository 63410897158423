import React from "react";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DataTableDeviceInfo from "./dataTableDeviceInfo";
import { cleanInfoImei } from "_models/redux/request-imei/clean-action";
import { connect } from "react-redux";

function ModalDeviceInfo(props) {
    const handleClose = () => {
        props.setOpenModal(false);
        props.cleanInfoImei();
    };

    return (
        <Grid container className="modalBackground">
            <Grid container xs={10} className="modalContainer">
                <Grid container>
                    <Grid item xs={6} className="title" direction={"column"}>
                        <h3>Información del dispositivo</h3>
                    </Grid>
                    <Grid item xs={6} className="titleCloseBtn">
                        <button onClick={handleClose}>
                            <CloseIcon />
                        </button>
                    </Grid>
                </Grid>
                <DataTableDeviceInfo />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    cleanInfoImei,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeviceInfo);
