import { Grid } from "@mui/material";
import { useEffect } from "react";
import { connect } from "react-redux";

const DashboardHeader = () => {
    useEffect(() => {}, []);

    return (
        <Grid container className="page-header">
            <Grid item xs={12}>
                <Grid container direction={"row"}>
                    <Grid item xs={6} textAlign={"start"}>
                        <h3>Ventas por ejecutivo</h3>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

// export default Modal;
const mapStateToProps = () => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
