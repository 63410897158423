export const gridContainerStyles = {
    maxWidth: "100%",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    padding: 1,
};

export const gridItemStyles = {
    display: "flex",
    justifyContent: "center",
};

export const cardStyles = {
    p: 2,
    border: "1px solid #D8D8D8",
    borderRadius: 2,
    width: "100%",
};

export const typographyTitleStyles = {
    color: "#7A3C92",
};

export const boxStyles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const buttonStyles = {
    backgroundColor: "#ede7f6",
    color: "#6a1b9a",
    textTransform: "none",
    padding: "8px 16px",
    minWidth: "150px",
    maxWidth: "200px",
    whiteSpace: "nowrap",
};

export const typographyBodyStyles = {
    mb: 0.8,
};
