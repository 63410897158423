import React from "react";
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    Grid,
} from "@mui/material";
import {
    cardStyles,
    boxStyles,
    buttonStyles,
    typographyBodyStyles,
    typographyTitleStyles,
    gridItemStyles,
} from "../styles/styles";

const weekDays = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
];

const formatTime = (time) => time.slice(0, 5);

const AddressInfo = ({ storeData, handleOpenMapDialog }) => (
    <Grid item xs={12} sm={6} sx={gridItemStyles}>
        <Card sx={cardStyles}>
            <CardContent>
                <Box sx={boxStyles}>
                    <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={typographyTitleStyles}
                    >
                        <strong>Dirección</strong>
                    </Typography>
                    <Button style={buttonStyles} onClick={handleOpenMapDialog}>
                        Ver en el mapa
                    </Button>
                </Box>
                <Typography variant="body1" sx={typographyBodyStyles}>
                    <strong>Estado:</strong> {storeData.state}
                </Typography>
                <Typography variant="body1" sx={typographyBodyStyles}>
                    <strong>Código postal:</strong> {storeData.zipcode}
                </Typography>
                <Typography variant="body1" sx={typographyBodyStyles}>
                    <strong>Municipio:</strong> {storeData.municipality}
                </Typography>
                <Typography variant="body1" sx={typographyBodyStyles}>
                    <strong>Colonia:</strong> {storeData.neighborhood}
                </Typography>
                <Typography variant="body1" sx={typographyBodyStyles}>
                    <strong>Calle y número:</strong> {storeData.address}
                </Typography>
                <Typography variant="body1" sx={typographyBodyStyles}>
                    <strong>Referencia:</strong> {storeData.address_reference}
                </Typography>
            </CardContent>
        </Card>
    </Grid>
);

export default AddressInfo;
