import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const LineEventsHook = ({
    changeUser,
    getUsers,
    events,
    userList,
    customerProfile,
    getEvents,
    changeUser_,
    ticketById,
}) => {
    const [modalOpenSim, setModalOpenSim] = useState(false);
    const [eventsList, setEventsList] = useState([]);
    const [imeiNumber, setImeiNumber] = useState("");
    const [valueAgent, setValueAgent] = useState();
    const [agentList, setAgentList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [ticketId, setTicketId] = useState();
    const [directNumber, setDirectNumber] = useState("");
    const [loader, setLoader] = useState(false);
    const [statusProfile, setStatusProfile] = useState("");

    const params = useParams();

    const handleChange = (event) => {
        setValueAgent(event.target.value);
        changeUser({
            ticketId: ticketId,
            id: event.target.value,
        });
    };

    useEffect(() => {
        setTicketId(params.ticketId);
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const eventList = events?.events?.events || [];

        if (Array.isArray(eventList)) {
            setEventsList(eventList);
        } else {
            setEventsList([]);
        }
        setLoader(false);
    }, [events]);

    useEffect(() => {
        const { users } = userList.response || { users: undefined };
        if (typeof users !== "undefined") {
            setAgentList(users);
        }
    }, [userList]);

    useEffect(() => {
        const { customerProfile: profile } = customerProfile || {
            customerProfile: undefined,
        };

        if (typeof profile !== "undefined") {
            setDirectNumber(profile.profile.direct_number);
            setImeiNumber(profile.profile.imei);
            setStatusProfile(profile.status.subStatus);
        }
    }, [customerProfile]);

    useEffect(() => {
        getEvents({ ticket_id: params.ticketId });
        setLoader(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeUser_]);

    useEffect(() => {
        const response = ticketById.response || undefined;
        if (typeof response !== "undefined") {
            setIsLoading(false);
            setValueAgent(response.assigned_to_user_id);
        }
    }, [ticketById]);

    return {
        modalOpenSim,
        setModalOpenSim,
        eventsList,
        setEventsList,
        imeiNumber,
        setImeiNumber,
        valueAgent,
        setValueAgent,
        agentList,
        setAgentList,
        isLoading,
        setIsLoading,
        ticketId,
        setTicketId,
        directNumber,
        setDirectNumber,
        loader,
        setLoader,
        statusProfile,
        setStatusProfile,
        handleChange,
        params,
    };
};
