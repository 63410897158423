import React, { useEffect } from "react";

import { Grid, TextField } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";

const LeadsByCampaingCustomFilters = (props) => {
    const { parentProperties } = props || {};
    const { form, redux, states } = parentProperties || {};
    const {
        campaignId,
        channelId,
        setCampaignId,
        setChannelId,
        setLandingId,
        landingId,
    } = states || {};
    const { getCampaingObj, getChannelObj, landingListObj } = redux || {};

    const { payload } = getChannelObj || {};
    const { channels } = payload || {};

    useEffect(() => {}, [getChannelObj]);

    const { register } = form || {};

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={6} xl={4}>
                <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="*Landing"
                    placeholder="*Landing"
                    id="landingId"
                    name="landingId"
                    {...register("landingId")}
                    value={landingId}
                    onChange={(e) => setLandingId(e.target.value)}
                    className="m-tb-20 form-control "
                    sx={{
                        mb: 2,
                        "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                                borderColor: "#6C63FF",
                            },
                        },
                        background: "white",
                    }}
                >
                    <MenuItem value="0">{"Seleccionar"}</MenuItem>
                    {landingListObj?.payload?.landings?.map((option) => {
                        return (
                            <MenuItem key={option.id} value={option.id || 0}>
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={4}>
                <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Canal"
                    placeholder="Canal"
                    id="channelId"
                    name="channelId"
                    {...register("channelId")}
                    value={channelId}
                    onChange={(e) => setChannelId(e.target.value)}
                    className="m-tb-20 form-control"
                    sx={{
                        mb: 2,
                        "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                                borderColor: "#6C63FF",
                            },
                        },
                        background: "white",
                    }}
                >
                    <MenuItem value="0">{"Todos"}</MenuItem>
                    {channels?.map((option) => {
                        return (
                            <MenuItem key={option.id} value={option.id || 0}>
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Grid>
            <Grid item xs={12} md={12} lg={6} xl={4}>
                <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Campaña"
                    placeholder="Campaña"
                    id="campaignId"
                    name="campaignId"
                    {...register("campaignId")}
                    value={campaignId}
                    onChange={(e) => setCampaignId(e.target.value)}
                    className="m-tb-20 form-control "
                    sx={{
                        mb: 2,
                        "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                                borderColor: "#6C63FF",
                            },
                        },
                        background: "white",
                    }}
                >
                    <MenuItem value="0">{"Todos"}</MenuItem>
                    {getCampaingObj?.payload?.campaigns?.map((option) => {
                        return (
                            <MenuItem key={option.id} value={option.id || 0}>
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Grid>
        </Grid>
    );
};

export default LeadsByCampaingCustomFilters;
