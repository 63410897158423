import React from "react";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import { getCustomerProfile } from "_models/redux/customer-profile/action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { cleanGetProfile } from "../../../../../../_models/redux/customer-profile/clean";

const Modal = (props) => {
    const { setOpenModal } = props || {};

    const onInputChange = () => {};

    const {
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = () => {};

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="modalBackground">
                <Grid item xs={4} className="modalContainer">
                    <Grid container className="title">
                        <Grid item xs={9}>
                            <h4>Buscar envío por número de orden</h4>
                        </Grid>
                        <Grid item xs={3} className="titleCloseBtn">
                            <button
                                onClick={() => {
                                    setOpenModal(false);
                                }}
                            >
                                <CloseIcon />
                            </button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className="body">
                        <TextField
                            variant="standard"
                            fullWidth
                            type="text"
                            className="form-control"
                            placeholder="Número de rastreo"
                            onChange={onInputChange}
                        />
                    </Grid>
                    <span>
                        {errors?.directNumber && (
                            <p className="required-incidence">
                                {errors.directNumber.message}
                            </p>
                        )}
                    </span>
                    <center></center>

                    <Grid item xs={12} className="footer">
                        <button
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            id="cancelBtn"
                        >
                            Cancelar
                        </button>

                        <LoadingButton
                            size="small"
                            type="submit"
                            isLoading={true}
                            loadingPosition="end"
                            variant="contained"
                        >
                            Buscar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = (state) => ({
    customerProfileReducer: state.customerProfile,
});
const mapDispatchToProps = {
    getCustomerProfile,
    cleanGetProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
