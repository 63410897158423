import React, { useEffect, useState } from "react";
import { Grid, Button, TextField, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import SaveIcon from "@mui/icons-material/Save";
import CustomDialog from "../../../../../../widgets/component/CustomDialog";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

const ActionsLeadDetail = (childrenProps) => {
    const [shouldShowWarningDialog, setShouldShowWarningDialog] =
        useState(false);
    const [openPopDetails, setOpenPopDetails] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [labelDd, setLabelDd] = useState("");
    const [hiddenComponent, setHiddenComponent] = useState("hidden-element");

    const { parentProps } = childrenProps || {};
    const { props, formProps, navProps, urlProps } = parentProps || {};

    const {
        generatedUrl,
        copySuccess,
        setCopySuccess,
        setIsScreenLoading,
        setOpen,
        setResponseTitle,
        setResponseDescription,
        setResponseIcon,
        setResponseCls,
    } = urlProps || {};

    const { params } = navProps || {};
    const { leadId } = params || {};

    const { register, setValue, getValues } = formProps || {};

    const {
        lead,
        backofficeLeadStatus,
        patchBackofficeLeadStatus,
        backofficeStatus,
        getBackofficeStatus,
        cleanBackofficeStatusAction,
        checkPaymentStatus,
        getCheckPaymentStatus,
        cleanCheckPaymentStatusAction,
        patchLeadAction,
        patchLeadObj,
    } = props || {};

    const { lead_backoffice_status_id } = lead?.payload || {};

    ////

    const { lead_backoffice_status } = backofficeStatus?.payload || {};

    const [customDialogContent, setCustomDialogContent] = useState(null);
    const [leadBackofficeStatusSelected, setLeadBackofficeStatusSelected] =
        useState(0);
    const [backofficeStatusShrink, setBackofficeStatusShrink] = useState(false);
    const [leadBackofficeGlobalStatusId, setLeadBackofficeGlobalStatusId] =
        useState();
    const [disableTextField, setDisableTextField] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const registerOptions = {
        backofficeStatusInput: {
            required: "El estatus es requerido",
        },
    };

    const saveDraft = () => {
        const _body = {};

        if (
            getValues("nameInput") !== "" ||
            getValues("contactPhoneInput") !== "" ||
            getValues("emailInput") !== ""
        ) {
            _body["basic_info"] = {};
            if (getValues("nameInput") !== "") {
                _body["basic_info"]["name"] = getValues("nameInput");
            }
            if (getValues("contactPhoneInput") !== "") {
                _body["basic_info"]["contact_phone"] =
                    getValues("contactPhoneInput");
            }

            if (getValues("emailInput") !== "") {
                _body["basic_info"]["email"] = getValues("emailInput");
            }
        }

        if (getValues("offerInput") !== "" || undefined) {
            _body["carrier_product_id"] = parseInt(getValues("offerInput"));
        }

        if (
            getValues("simTypeInput") === 1 &&
            (getValues("addressInput") !== "" ||
                getValues("neighborhoodInput") !== "" ||
                getValues("contactForServiceInput") !== "" ||
                getValues("zipcodeInput") !== "")
        ) {
            _body["shipping_address"] = {};

            if (getValues("addressInput") !== "") {
                _body["shipping_address"]["address"] =
                    getValues("addressInput");
            } else {
                setShouldShowWarningDialog(true);
                setOpenPopDetails(true);

                return;
            }
            if (getValues("neighborhoodInput") !== "") {
                _body["shipping_address"]["neighborhood_id"] =
                    getValues("neighborhoodInput");
            } else {
                setShouldShowWarningDialog(true);
                setOpenPopDetails(true);

                return;
            }

            if (getValues("referenceInput") !== "") {
                _body["shipping_address"]["reference"] =
                    getValues("referenceInput");
            } else {
                setShouldShowWarningDialog(true);
                setOpenPopDetails(true);

                return;
            }

            if (getValues("contactForServiceInput") !== "") {
                _body["shipping_address"]["contact_phone"] = getValues(
                    "contactForServiceInput"
                );
            } else {
                setShouldShowWarningDialog(true);
                setOpenPopDetails(true);

                return;
            }

            if (getValues("zipcodeInput") !== "") {
                _body["shipping_address"]["zipcode"] =
                    getValues("zipcodeInput");
            } else {
                setShouldShowWarningDialog(true);
                setOpenPopDetails(true);
                return;
            }
        }
        setShouldShowWarningDialog(false);

        const patchLeadObj = {
            lead_id: leadId || "",
            body: _body,
        };
        patchLeadAction(patchLeadObj);
    };

    const handleClose = () => {
        setOpenPopDetails(false);
        setOpenPopup(false);
    };

    const inputChangeValidation = (e) => {
        setIsScreenLoading(true);

        const inputValue = e?.target?.value;
        const selectedOpt = lead_backoffice_status.find(
            (element) => element?.id === inputValue
        );
        const inputGlobalStatus = selectedOpt?.lead_backoffice_global_status_id;

        setSelectedOption(e);

        if (inputGlobalStatus === 3) {
            setOpenPopDetails(true);
            setCustomDialogContent(
                "Esta acción cerrará el lead actual. ¿Desea continuar?"
            );
            setIsScreenLoading(false);
        } else {
            inputChange(e);
        }
    };

    const inputChange = (e, param) => {
        const inputValue = e?.target?.value;
        const selectedOpt = lead_backoffice_status.find(
            (element) => element?.id === inputValue
        );

        const inputName = e?.target?.name;
        if (param) {
            if (param === "3") {
                setOpenPopDetails(false);
                setHiddenComponent("hidden-element");
                setLabelDd(selectedOpt?.name);
            }
        }

        setBackofficeStatusMethod();

        if (typeof patchBackofficeLeadStatus === "function" && inputValue) {
            patchBackofficeLeadStatus({
                lead_id: leadId,
                lead_backoffice_status_id: inputValue,
            });
        }

        if (inputValue === "") {
            switch (inputName) {
                case "backofficeStatusInput":
                    setBackofficeStatusShrink(false);
                    break;

                default:
                    break;
            }
        } else {
            switch (inputName) {
                case "backofficeStatusInput":
                    setValue(inputName, inputValue);
                    setLeadBackofficeStatusSelected(inputValue);
                    setBackofficeStatusShrink(true);
                    break;

                default:
                    break;
            }
        }
    };

    const copyToClipBoard = async () => {
        try {
            await navigator.clipboard.writeText(generatedUrl);
            setCopySuccess("¡Enlace copiado!");
        } catch (err) {
            setCopySuccess("No se ha copiado el enlace");
        }
    };

    const checkPaymentStatusMethod = () => {
        if (typeof getCheckPaymentStatus === "function")
            getCheckPaymentStatus({ lead_id: leadId });
    };

    const setBackofficeStatusMethod = () => {
        if (lead_backoffice_status_id) {
            const leadBackofficeStatusFilter = lead_backoffice_status?.filter(
                (boStatus) => boStatus.id === lead_backoffice_status_id
            );

            if (leadBackofficeStatusFilter?.length > 0) {
                setLeadBackofficeStatusSelected(lead_backoffice_status_id);
                setValue("backofficeStatusInput", lead_backoffice_status_id);

                setLeadBackofficeGlobalStatusId(
                    leadBackofficeStatusFilter[0]
                        .lead_backoffice_global_status_id
                );

                if (leadBackofficeGlobalStatusId) {
                    if (leadBackofficeGlobalStatusId === 3) {
                        setDisableTextField(true);
                    }
                }
            } else {
                setLeadBackofficeStatusSelected(0);
                setValue("backofficeStatusInput", 0);
            }
        }
    };

    useEffect(() => {
        getBackofficeStatus();

        return () => {
            if (typeof cleanBackofficeStatusAction === "function")
                cleanBackofficeStatusAction();

            if (typeof cleanCheckPaymentStatusAction === "function")
                cleanCheckPaymentStatusAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setHiddenComponent("hidden-element");

        const { payload, state } = lead || {};
        if (state === "success") {
            const { lead_backoffice_status, lead_global_backoffice_status_id } =
                payload || {};

            if (lead_global_backoffice_status_id === 3) {
                setDisableTextField(true);
                setLabelDd(lead_backoffice_status);
                setHiddenComponent("hidden-element");
            } else {
                setHiddenComponent("");
            }
        }
    }, [lead]);

    useEffect(() => {
        setBackofficeStatusMethod();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lead_backoffice_status]);

    useEffect(() => {
        setBackofficeStatusMethod();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lead_backoffice_status_id]);

    useEffect(() => {
        const { payload, state } = backofficeLeadStatus || {};

        if (state === "error") {
            const { data } = payload.response || {};
            const { detail, title } = data || {};

            setOpen(true);
            setResponseTitle(title);
            setLeadBackofficeStatusSelected("");
            setResponseDescription(
                <Grid container>
                    {" "}
                    <Grid item xs={12}>
                        <p>
                            <span></span>
                            <span>
                                <b>{detail || ""}</b>
                            </span>
                        </p>
                    </Grid>
                </Grid>
            );
            setResponseCls("error-modal");
        }

        setIsScreenLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backofficeLeadStatus]);

    useEffect(() => {
        const { payload, state } = checkPaymentStatus || {};

        if (state === "error") {
            const { detail, title } = payload || {};

            setOpen(true);

            setResponseTitle(title);
            setResponseDescription(
                <Grid container>
                    {" "}
                    <Grid item xs={12}>
                        <p>
                            <span></span>
                            <span>
                                <b>{detail || ""}</b>
                            </span>
                        </p>
                    </Grid>
                </Grid>
            );
            setResponseCls("error-modal");
            // setResponseIcon(<ErrorOutlineIcon />);
        } else if (
            checkPaymentStatus?.status >= 200 &&
            checkPaymentStatus?.status < 300
        ) {
            const { authorization_number, operation_id, status } =
                payload || {};

            setOpen(true);

            setResponseTitle(
                status !== false
                    ? "Confirmación de pago"
                    : payload?.lead_status?.lead_status
                      ? payload?.lead_status?.lead_status
                      : "Error"
            );
            setResponseDescription(
                <Grid container>
                    {" "}
                    {status !== false ? (
                        <Grid item xs={12}>
                            <p>
                                <span>Número de autorización: </span>
                                <span>
                                    <b>{authorization_number || ""}</b>
                                </span>
                            </p>
                            <p>
                                <span>ID de operación: </span>
                                <span>
                                    <b>{operation_id || ""}</b>
                                </span>
                            </p>
                        </Grid>
                    ) : (
                        payload?.message
                    )}
                </Grid>
            );
            setResponseCls("success-modal");
            setResponseIcon();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkPaymentStatus]);

    useEffect(() => {
        if (patchLeadObj?.status >= 300) {
            setOpenPopDetails(true);
        }
    }, [patchLeadObj]);

    useEffect(() => {
        if (shouldShowWarningDialog) {
            setCustomDialogContent(
                <h4>Por favor complete todos los campos de dirección.</h4>
            );
            setOpenPopDetails(true);
        } else if (patchLeadObj?.status === 200) {
            setCustomDialogContent(<h4>Se guardó correctamente</h4>);
        } else if (patchLeadObj?.status !== undefined) {
            setCustomDialogContent(
                <h4>
                    Ocurrió un problema al procesar tu solicitud:{" "}
                    {patchLeadObj?.payload?.detail}
                </h4>
            );
        }

        setOpenPopDetails(
            shouldShowWarningDialog || patchLeadObj?.status !== undefined
        );

        return () => {
            setCustomDialogContent(null);
            setOpenPopDetails(false);
        };
    }, [patchLeadObj, shouldShowWarningDialog]);

    ////

    return (
        <Grid container className="lead-actions-container">
            <Grid item xs={12} className="lead-actions">
                <Grid container display={"flex"} alignItems={"center"}>
                    <Box mt={2} width="100%">
                        {" "}
                        <Grid item xs={12}>
                            <Button
                                variant="outlined"
                                startIcon={<SaveIcon />}
                                className="save"
                                onClick={saveDraft}
                                style={{ width: "100%" }}
                            >
                                Guardar cambios
                            </Button>
                        </Grid>
                    </Box>
                </Grid>
                <br></br>
                {hiddenComponent === "hidden-element" ? (
                    <div>{labelDd}</div>
                ) : (
                    <TextField
                        disabled={disableTextField}
                        select
                        fullWidth
                        variant="outlined"
                        label=""
                        placeholder="Estatus"
                        id="backofficeStatusInput"
                        name="backofficeStatusInput"
                        value={leadBackofficeStatusSelected}
                        {...register(
                            "backofficeStatusInput",
                            registerOptions?.backofficeStatusInput
                        )}
                        // defaultValue={0}
                        onChange={(e) => inputChangeValidation(e)}
                        InputLabelProps={{
                            shrink: backofficeStatusShrink,
                        }}
                        className="m-tb-20 form-control data-input"
                        sx={{
                            mb: 2,
                            "& .MuiOutlinedInput-root:hover": {
                                "& > fieldset": {
                                    borderColor: "#6C63FF",
                                },
                            },
                        }}
                    >
                        <MenuItem key={0} value={0}>
                            {"Seleccione un estatus"}
                        </MenuItem>
                        {lead_backoffice_status?.map((option) => (
                            <MenuItem key={option?.id} value={option?.id || 0}>
                                {option?.name}
                            </MenuItem>
                        ))}
                    </TextField>
                )}

                {/* <Grid item xs={12} className="m-t-15">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Fecha"
                            sx={{
                                width: "100%",
                                background: "#fff",
                                border: "#E5E5E5",
                                color: "#441556",
                                "& .MuiInputBase-input": {
                                    "&:focus": {
                                        borderColor: "#6C63FF",
                                        boxShadow:
                                            "0 0 0 0.2rem rgba(0,123,255,.25)",
                                    },
                                },
                                "& .MuiOutlinedInput-root:hover": {
                                    "& > fieldset": {
                                        borderColor: "#6C63FF",
                                    },
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Grid> */}

                <Grid container>
                    <Grid container className="bottom-container">
                        {generatedUrl !== "" ? (
                            <>
                                <Grid item xs={12}>
                                    <h2>Enlace de pago</h2>
                                    <div className="link-box">
                                        <p>{generatedUrl}</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="button-container">
                                        <Button
                                            startIcon={<ContentPasteIcon />}
                                            onClick={() => copyToClipBoard()}
                                        >
                                            Copiar enlace
                                        </Button>
                                        <p className="copy-success">
                                            {copySuccess}
                                        </p>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            className="confirm-btn"
                                            onClick={checkPaymentStatusMethod}
                                        >
                                            Confirmar pago
                                        </Button>
                                    </div>
                                </Grid>
                            </>
                        ) : null}
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                className="main-btn"
                            >
                                Generar enlace
                            </Button>
                        </Grid>
                    </Grid>
                    <CustomDialog
                        title="Notificación"
                        content={customDialogContent}
                        onClose={handleClose}
                        open={openPopup}
                    />

                    <CustomDialog
                        title="Confirmación"
                        onClose={handleClose}
                        open={openPopDetails}
                        extraButtons={[
                            {
                                label: "Confirmar",
                                onClick: () => inputChange(selectedOption, "3"),

                                variant: "text",
                            },
                            {
                                label: "Cancelar",
                                onClick: handleClose,
                                variant: "contained",
                            },
                        ]}
                        showCloseButton={false}
                        fullWidth={true}
                        maxWidth={"sm"}
                        content={customDialogContent}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ActionsLeadDetail;
