import { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import { SessionContext } from "_models/context/app";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { getSpeed } from "../../../../../../_controllers/helpers/getSpeed";

export const CardOffersHook = () => {
    const sessionContext = useContext(SessionContext);
    const { typeServiceSelect } = sessionContext || {};

    const colorsList = ["#6D39EF", "#FF28DF", "#00F5FF"];

    const CenteredWhitePaper = styled(Paper)(({ theme }) => ({
        backgroundColor: "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(0),
        textAlign: "center",
        color: theme.palette.text.secondary,
    }));

    const PaddedGreyPaper = styled(Paper)(({ theme }) => ({
        backgroundColor: "#FBFBFB",
        ...theme.typography.body2,
        padding: theme.spacing(2),
        color: "050505",
        border: "1px solid #DEDEDE",
    }));

    const filterServiceType = (serviceType, offerName) => {
        offerName = offerName.toLowerCase();

        if (serviceType === "calls") {
            return offerName.includes("min");
        } else if (serviceType === "sms") {
            return offerName.includes("sms");
        } else if (serviceType === "data") {
            return !offerName.includes("min") && !offerName.includes("sms");
        }
    };
    const stringToDate = (dateString) => {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
        const hours = dateString.substring(8, 10);
        const minutes = dateString.substring(10, 12);
        const secconds = dateString.substring(12, 14);

        // Date.parse
        const date =
            year +
            "/" +
            month +
            "/" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            secconds;
        const dateFormat = new Date(date);

        const dateFormatMoment = moment(dateFormat).format("D MMMM YYYY ");

        return dateFormatMoment;
    };
    const daysFrom = (startDate, endDate) => {
        const b = moment(endDate);

        const today = moment.now();
        //var c = b.from(a);
        const c = b.diff(today, "days");

        return c;
    };

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    //TODO funcionalidad de grafica

    const testData = [
        { bgcolor: "#663CE6", completed: 60 },
        // { bgcolor: "#663CE6", completed: 30 },
        // { bgcolor: "#6DF1FE", completed: 53 },
    ];

    const getSpeedValue = getSpeed;

    return {
        testData,
        handleExpandClick,
        expanded,
        setExpanded,
        daysFrom,
        stringToDate,
        filterServiceType,
        PaddedGreyPaper,
        CenteredWhitePaper,
        colorsList,
        typeServiceSelect,
        getSpeedValue,
    };
};
