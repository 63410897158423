import React from "react";
import { Grid, Typography } from "@mui/material";
import { getTrackingDetailsToku } from "./data/DetailsToku";
import { getTrackingDetailsSTP } from "./data/DetailsSTP";
import { getIdentifierDetails } from "./data/DetailsNetPay";
import { getResponseDetails } from "./data/DetailsHistoryPrepaid";
import { getPaymentDetails } from "./data/DetailPurchase";
import { getAuthorizationDetails } from "./data/DetailsOpenPay";

const titleStyle = { color: "#7A3C92" };
const spanStyle = { fontSize: 15, wordWrap: "break-word" };
const valueStyle = {
    textAlign: "right",
    marginTop: "5px",
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

const OrderIdentifiersGrid = ({ orderDetails, title, type }) => {
    let items = [];

    if (type === "detailsSTP") {
        items = getTrackingDetailsSTP(orderDetails);
    } else if (type === "detailsToku") {
        items = getTrackingDetailsToku(orderDetails);
    } else if (type === "detailsNetPay") {
        items = getIdentifierDetails(orderDetails);
    } else if (type === "detailsHistoryPrepaid") {
        items = getResponseDetails(orderDetails);
    } else if (type === "detailPurchase") {
        items = getPaymentDetails(orderDetails);
    } else if (type === "detailsOpenPay") {
        items = getAuthorizationDetails(orderDetails);
    }

    return (
        <Grid container className="device-info">
            <Grid item xs={12}>
                <div>
                    <span style={titleStyle}>{title}</span>
                </div>
            </Grid>

            <Grid item xs={12}>
                {items.map((item, index) => (
                    <div
                        key={index}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "5px",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        <span style={spanStyle}>{item.label}:</span>
                        <Typography variant="body1" style={valueStyle}>
                            {item.value || "N/A"}
                        </Typography>
                    </div>
                ))}
            </Grid>
        </Grid>
    );
};

export default OrderIdentifiersGrid;
