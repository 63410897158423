import React from "react";
import { CircularProgress } from "@mui/material";
import StoreDetails from "utils/componentGenericStore/StoreDetails";
import CustomDialog from "widgets/component/CustomDialog";

const StoreDetailsDialog = ({
    loading,
    responseIpbDirectoryModerationDetail,
    isDialogOpenDetails,
    handleClose,
    showExtraButtons,
    handleRejectStore,
    handleAcceptStore,
    ActionStore,
    isRejecting,
}) => (
    <CustomDialog
        fullWidth
        title={
            responseIpbDirectoryModerationDetail?.response?.payment_info ===
            null
                ? "No hay datos"
                : "Detalle de la tienda"
        }
        content={
            loading ? (
                <CircularProgress />
            ) : (
                <StoreDetails
                    isRejecting={isRejecting}
                    ActionStore={ActionStore}
                    storeData={responseIpbDirectoryModerationDetail?.response}
                />
            )
        }
        open={isDialogOpenDetails}
        onClose={handleClose}
        maxWidth="md"
        extraButtons={
            ActionStore !== "success" && showExtraButtons
                ? [
                      {
                          label: "Rechazar tienda",
                          variant: "contained",
                          onClick: handleRejectStore,
                          buttonColor: "#D63030",
                          textColor: "white",
                      },
                      {
                          label: "Aceptar tienda",
                          variant: "contained",
                          onClick: handleAcceptStore,
                          buttonColor: "#1E9B61",
                          textColor: "white",
                      },
                  ]
                : []
        }
    />
);

export default StoreDetailsDialog;
