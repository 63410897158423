import React from "react";

const ErrorMessageComponent = (props) => {
    let error_message = "";
    const { errorType, errorParams } = props;

    if (typeof errorParams !== "undefined") {
        if (typeof errorParams.errors !== "undefined") {
            if (typeof errorType !== "undefined") {
                if (typeof errorParams.errors[errorType] !== "undefined") {
                    const { message } = errorParams.errors[errorType];
                    if (typeof message !== "undefined") {
                        error_message = message;
                    }
                }
            }
        }
    }

    if (error_message !== "") {
        return (
            <span className={"error-container"}>
                <h5 className={"error-text"}>{error_message}</h5>
            </span>
        );
    } else {
        return <span></span>;
    }
};

export default ErrorMessageComponent;
