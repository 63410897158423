import React from "react";
import { TextField, Grid } from "@mui/material";
import GenericAddButton from "widgets/GenericAddButton/GenericAddButton";
import SearchIcon from "@mui/icons-material/Search";

const FiltroSTP = ({ handleSubmit, onSubmit, register, errors }) => {
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} lg={2}>
                    <TextField
                        fullWidth
                        label="*Desde"
                        type="date"
                        {...register("from_date")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={Boolean(errors.from_date)}
                        helperText={errors.from_date?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <TextField
                        fullWidth
                        label="*Hasta"
                        type="date"
                        {...register("to_date")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={Boolean(errors.to_date)}
                        helperText={errors.to_date?.message}
                    />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <TextField
                        fullWidth
                        label="*Referencia"
                        {...register("reference")}
                        error={Boolean(errors.reference)}
                        helperText={errors.reference?.message}
                    />
                </Grid>

                <Grid item xs={12} lg={2}>
                    <TextField
                        fullWidth
                        label="*Clave STP"
                        {...register("beneficiary_account")}
                        error={Boolean(errors.beneficiary_account)}
                        helperText={errors.beneficiary_account?.message}
                    />
                </Grid>

                <Grid item xs={12} lg={1}>
                    <TextField
                        fullWidth
                        label="Monto"
                        type="number"
                        {...register("amount")}
                        error={Boolean(errors.amount)}
                        helperText={errors.amount?.message}
                        onWheel={(e) => e.target.blur()}
                    />
                </Grid>

                <Grid item xs={12} lg={1}>
                    <GenericAddButton
                        buttonText="Buscar"
                        backgroundColor="#4C2484"
                        size="medium"
                        isSubmit
                        icon={<SearchIcon />}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default FiltroSTP;
