/* eslint-disable no-undef */
const CurrencyFormatter = ({ amount }) => {
    if (typeof Intl === "undefined") {
        throw new Error("Intl is not available in this environment.");
    }

    const formattedAmount = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);

    return formattedAmount;
};
/* eslint-enable no-undef */

export default CurrencyFormatter;
