import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";

import { LoadersContext } from "_models/context/app";

export const useMonthlySalesReportContainerHook = (props) => {
    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    const {
        cleanGetSalesMonthlyAction,
        getSalesMonthlyAction,
        getSalesMonthly,
    } = props || {};

    useEffect(() => {}, []);

    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const [filters, setFilters] = useState({});

    const [fromTime, setFromTime] = useState(
        moment().startOf("month").format()
    );
    const [toTime, setToTime] = useState(moment().format());

    const [displayDatepickers, setDisplayDatepickers] = useState(
        "displayDatepickers hidden"
    );

    const [comparativeHeaders, setComparativeHeaders] = useState("MoM");
    const [comparativeColumns, setComparativeColumns] = useState([]);
    const [comparativeRows, setComparativeRows] = useState([]);

    const [cells, setCells] = useState([]);

    const [headers, setHeaders] = useState([]);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    const [data, setData] = useState([]);
    const [noDataForGrid, setNoDataForGrid] = useState([]);

    useEffect(() => {
        const { loaded } = getSalesMonthly || {};

        if (loaded) {
            setIsScreenLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSalesMonthly]);

    useEffect(() => {
        // Format the dates in "YYYY-MM-DD" format
        const formattedFromTime = moment(fromTime).format("YYYY-MM-DD");
        const formattedToTime = moment(toTime).format("YYYY-MM-DD");

        // Set the formatted dates back into the state
        setFromTime(formattedFromTime);
        setToTime(formattedToTime);

        // getLoadedMethods();
    }, [fromTime, toTime]);

    const getLoadedMethods = () => {
        getSalesMonthlyAction({
            from_time: fromTime,
            to_time: toTime,
        });
    };

    const loadData = () => {
        setIsScreenLoading(true);
        getLoadedMethods();
    };

    const parentProperties = {
        form: {
            watch,
            reset,
            control,
            setValue,
            getValues,
            register,
            handleSubmit,
            setError,
            errors,
        },
        redux: {
            cleanGetSalesMonthlyAction,
            getSalesMonthlyAction,
            getSalesMonthly,
        },
        states: {
            filters,
            setFilters,
            fromTime,
            setFromTime,
            toTime,
            setToTime,

            setIsScreenLoading,

            displayDatepickers,
            setDisplayDatepickers,

            comparativeHeaders,
            setComparativeHeaders,
            comparativeColumns,
            setComparativeColumns,
            comparativeRows,
            setComparativeRows,

            cells,
            setCells,

            headers,
            setHeaders,
            columns,
            setColumns,
            rows,
            setRows,

            data,
            setData,
            noDataForGrid,
            setNoDataForGrid,
        },
        methods: {
            loadData,
        },
    };

    return { parentProperties };
};
