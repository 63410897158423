import React from "react";
import { CircularProgress, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import CustomDialog from "widgets/component/CustomDialog";

const ConfirmationDialog = ({
    dialogTitle,
    dialogContent,
    isRejecting,
    control,
    rejectionReason,
    dialogLoading,
    isCustomDialogOpen,
    setIsCustomDialogOpen,
    dialogButtonLabel,
    dialogButtonColor,
    handleSubmit,
    handleConfirm,
    reset,
}) => (
    <CustomDialog
        title={dialogTitle}
        content={
            <>
                {dialogContent}
                {isRejecting && (
                    <Controller
                        name="rejectionReason"
                        control={control}
                        defaultValue={rejectionReason}
                        rules={{ required: "Este campo es obligatorio" }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                label="Motivo del rechazo"
                                fullWidth
                                multiline
                                rows={4}
                                error={Boolean(error)}
                                helperText={error ? error.message : null}
                            />
                        )}
                    />
                )}
                {dialogLoading && <CircularProgress />}
            </>
        }
        open={isCustomDialogOpen}
        onClose={() => {
            setIsCustomDialogOpen(false);
            reset();
        }}
        fullWidth
        maxWidth="sm"
        extraButtons={[
            {
                label: "Cancelar",
                onClick: () => {
                    setIsCustomDialogOpen(false);
                    reset();
                },
                buttonColor: "primary",
                textColor: "#595959",
            },
            {
                label: dialogButtonLabel,
                variant: "contained",
                onClick: handleSubmit(handleConfirm),
                buttonColor: dialogButtonColor,
                textColor: "white",
            },
        ]}
    />
);

export default ConfirmationDialog;
