import React from "react";
import { Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import ErrorIcon from "@mui/icons-material/Error";

const MenuImeiInfoCard = ({ label, value, isError = false }) => (
    <Card
        style={{
            margin: "10px 0",
            border: "2px",
            backgroundColor: "#FAFAFA",
        }}
    >
        <CardContent>
            <Typography
                variant="h6"
                color={isError ? "error" : "textSecondary"}
            >
                {label}
            </Typography>
            {isError ? (
                <Alert severity="error" icon={<ErrorIcon />} sx={{ mt: 1 }}>
                    {value}
                </Alert>
            ) : Array.isArray(value) ? (
                <ul style={{ listStyleType: "none", padding: 0 }}>
                    {value.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            ) : (
                <Typography variant="body1">{value}</Typography>
            )}
        </CardContent>
    </Card>
);

const MenuImeiInfoWidget = ({ data, dataError }) => {
    const { deviceFeatures, imei } = data || {};

    return (
        <Grid container spacing={3} className="device-info-container">
            <Grid item xs={12} md={12}>
                <MenuImeiInfoCard
                    label="IMEI"
                    value={dataError ? dataError : imei?.imei}
                    isError={Boolean(dataError)}
                />
            </Grid>
            {!dataError && (
                <>
                    <Grid item xs={12} md={12}>
                        <MenuImeiInfoCard
                            label="Estatus y Homologado"
                            value={[
                                `Homologado:${imei?.homologated} `,
                                `Bloqueado: ${imei?.blocked}`,
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MenuImeiInfoCard
                            label="Marca y Modelo"
                            value={[
                                `Marca:  ${deviceFeatures?.brand}`,
                                `Modelo: ${deviceFeatures?.model}`,
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MenuImeiInfoCard
                            label="Soporte Banda28, VoLTE, eSIM"
                            value={[
                                `Compatible con banda28: ${deviceFeatures?.band28}`,
                                `Soporta VoLTE: ${deviceFeatures?.volteCapable}`,
                                `Soporta ESIM: ${imei?.soportaESIM}`,
                            ]}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default MenuImeiInfoWidget;
