// Import: Middleware Action & Custom Types
import {
    CLEAN_TRANSACTIONS_TOKU,
    CLEAN_TRANSACTIONS_TOKU_ERROR,
} from "_models/redux/types";

// Action function
export const cleanTransactionsTokuClean = () => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        dispatch({
            type: CLEAN_TRANSACTIONS_TOKU,
            payload: undefined,
        });
    } catch (e) {
        dispatch({
            type: CLEAN_TRANSACTIONS_TOKU_ERROR,
            payload: undefined,
        });
    }
};
