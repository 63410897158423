import React from "react";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import {
    cardStyles,
    boxStyles,
    typographyBodyStyles,
    typographyTitleStyles,
    gridItemStyles,
} from "../styles/styles";

const weekDays = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
];

const formatTime = (time) => time.slice(0, 5);

const TimeStore = ({ storeData }) => (
    <Grid container justifyContent="flex-start" margin={3}>
        <Grid item xs={12} sm={6} lg={6} sx={gridItemStyles}>
            <Card sx={cardStyles}>
                <CardContent>
                    <Box sx={boxStyles}>
                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={typographyTitleStyles}
                        >
                            <strong>Horario</strong>
                        </Typography>
                    </Box>
                    {storeData?.opening_hours?.map((hours, index) => (
                        <Typography
                            key={index}
                            variant="body1"
                            sx={typographyBodyStyles}
                        >
                            <strong>{weekDays[hours.week_day - 1]}:</strong>{" "}
                            {hours.is_24_hours
                                ? "Abierto 24 horas"
                                : `${formatTime(hours.opening_time)} - ${formatTime(hours.closing_time)}`}
                        </Typography>
                    ))}
                </CardContent>
            </Card>
        </Grid>
    </Grid>
);

export default TimeStore;
