import React from "react";
import { Grid, Button } from "@mui/material";

const FraudPrevention = ({
    order_details,
    handleOpenPopUp,
    fraudStyle,
    chargebackStyle,
}) => {
    const userPermissions = localStorage.getItem("user_permissions");

    const hasPermissions = userPermissions.includes("CHARGEBACK");

    return (
        <>
            {hasPermissions && (
                <Grid container className="device-info">
                    <Grid item xs={12}>
                        <h2>Prevención</h2>
                    </Grid>
                    <Grid
                        container
                        alignItems="center"
                        style={{ marginBottom: "20px" }}
                    >
                        <Grid item xs={6}>
                            <div>Fraude</div>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            {order_details?.is_fraud ? (
                                <p style={fraudStyle}>Reportado</p>
                            ) : (
                                <Button
                                    onClick={() => handleOpenPopUp("fraud")}
                                    style={{
                                        backgroundColor: "#ede7f6",
                                        color: "#6a1b9a",
                                        textTransform: "none",
                                        width: "100px",
                                    }}
                                >
                                    Reportar
                                </Button>
                            )}
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        alignItems="center"
                        style={{ marginBottom: "20px" }}
                    >
                        <Grid item xs={6}>
                            <div>Contracargo</div>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            {order_details?.is_chargeback ? (
                                <p style={chargebackStyle}>Reportado</p>
                            ) : (
                                <Button
                                    onClick={() =>
                                        handleOpenPopUp("chargeback")
                                    }
                                    style={{
                                        backgroundColor: "#ede7f6",
                                        color: "#6a1b9a",
                                        textTransform: "none",
                                        width: "100px",
                                    }}
                                >
                                    Reportar
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default FraudPrevention;
