import { useState, useCallback } from "react";

export const useStoreDetails = ({ storeData }) => {
    const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isMapDialogOpen, setIsMapDialogOpen] = useState(false);

    const handleImageClick = useCallback((image) => {
        setSelectedImage(image);
        setIsImageDialogOpen(true);
    }, []);

    const handleCloseImageDialog = useCallback(() => {
        setIsImageDialogOpen(false);
        setSelectedImage(null);
    }, []);

    const handleOpenMapDialog = useCallback(() => {
        setIsMapDialogOpen(true);
    }, []);

    const handleCloseMapDialog = useCallback(() => {
        setIsMapDialogOpen(false);
    }, []);

    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const mapContainerStyle = {
        width: "100%",
        height: "400px",
    };

    return {
        googleMapsApiKey,
        isImageDialogOpen,
        selectedImage,
        handleImageClick,
        handleCloseImageDialog,
        isMapDialogOpen,
        handleOpenMapDialog,
        handleCloseMapDialog,
        mapContainerStyle,
    };
};
