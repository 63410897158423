import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";
import CurrencyFormatter from "utils/helpers/CurrencyFormatter";

export const useListSimIpb = ({
    listSimIpbAction,
    listSimIpb,
    cleanListSimIpbClean,
    detailListSimIpbAction,
    detailListSimIpb,
    cleanDetailListSimIpbAction,
    transactionId,
    onClose,
}) => {
    const { payload } = listSimIpb || {};
    const { response } = detailListSimIpb || {};
    const { rows, pagination } = payload || {};

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState(false);
    const [isDialogOpenDetails, setIsDialogOpenDetails] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [action, setAction] = useState(null);
    const [error, setError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (transactionId) {
            setIsDialogOpenDetails(true);
            detailListSimIpbAction(transactionId);
        }
    }, [transactionId]);

    useEffect(() => {
        if (response?.response?.status > 200) {
            setDialogMessage("Error");
            setDialogMessage(response?.response?.data?.detail);
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [response]);

    useEffect(() => {
        if (listSimIpb?.status === 200) {
            setLoading(false);
        } else if (listSimIpb?.payload?.response?.status > 200) {
            setDialogMessage(listSimIpb?.payload?.response?.data?.detail);
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [listSimIpb]);

    const onSubmit = (data) => {
        /* eslint-disable no-unused-vars */
        const filteredData = Object.fromEntries(
            Object.entries(data).filter(
                ([_, value]) => value !== undefined && value !== ""
            )
        );
        /* eslint-enable no-unused-vars */
        setLoading(true);
        setPage(1);
        setSearchParams(filteredData);
        setIsSubmitted(true);
        setErrorMessage("");
    };

    useEffect(() => {
        if (isSubmitted || page !== 1 || size !== 10) {
            /* eslint-disable no-unused-vars */
            const filteredSearchParams = Object.fromEntries(
                Object.entries(searchParams).filter(
                    ([_, value]) => value !== undefined && value !== ""
                )
                /* eslint-enable no-unused-vars */
            );

            setLoading(true);
            listSimIpbAction(page, size, filteredSearchParams)
                .then(() => {
                    setLoading(false);
                })
                .catch(() => {
                    console.error("listSimIpbAction error:", error);
                    setLoading(false);
                });
        }
        return () => {
            cleanListSimIpbClean();
        };
    }, [
        page,
        size,
        searchParams,
        listSimIpbAction,
        cleanListSimIpbClean,
        isSubmitted,
        error,
    ]);

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangePageSize = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setSize(newSize);
        setPage(1);
    };

    useEffect(() => {
        if (transactionId) {
            const filteredSearchParams = Object.fromEntries(
                Object.entries(searchParams).filter(
                    ([_, value]) => value !== undefined && value !== ""
                )
                /* eslint-enable no-unused-vars */
            );
            setIsSubmitted(true);
            setLoading(true);
            setIsDialogOpenDetails(true);
            detailListSimIpbAction(transactionId);
            listSimIpbAction(page, size, filteredSearchParams);
        }
    }, [transactionId]);

    const mappedRows =
        rows?.map((row) => ({
            ...row,
            id: `${row.id}`,
        })) || [];

    const handleIdButtonClick = (id) => {
        if (id) {
            setIsDialogOpenDetails(true);
            detailListSimIpbAction(id)
                .then(() => setLoading(false))
                .catch(() => {
                    setDialogMessage("Error");
                    setDialogMessage(
                        "Error al cargar los detalles de la transferencia."
                    );
                    setIsDialogOpen(true);
                    setLoading(false);
                });

            return () => {
                cleanDetailListSimIpbAction();
            };
        }
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setIsDialogOpenDetails(false);
        cleanDetailListSimIpbAction();
        if (transactionId) {
            onClose();
        }
    };

    const handleOpenPopUp = () => {
        setAction(action);
    };

    const Columns = [
        {
            field: "id",
            headerName: "ID",
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    onClick={() => handleIdButtonClick(params.row.id)}
                >
                    {"Ver detalle"}
                </Button>
            ),
        },
        {
            field: "amount",
            headerName: "Cantidad",
            width: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => <CurrencyFormatter amount={params.value} />,
        },

        {
            field: "created",
            headerName: "Fecha DB",
            width: 250,
            headerAlign: "center",
            valueGetter: (params) => {
                return convertToMexicoTime(params.row.created);
            },
        },

        {
            field: "email",
            headerName: "Email",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: "Nombre del cliente",
            width: 220,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "order_number",
            headerName: "Número de orden",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "product_name",
            headerName: "Nombre del producto",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
    ];

    return {
        register,
        handleSubmit,
        errors,
        onSubmit,
        loading,
        page,
        size,
        handleChangePage,
        pagination: {
            ...pagination,
            handleChangePage,
            handleChangePageSize,
        },
        rows,
        mappedRows,
        Columns,
        isDialogOpen,
        setIsDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        setErrorMessage,
        setError,
        error,
        handleOpenPopUp,
    };
};
