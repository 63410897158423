import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../clientTickets/modal/new-inicidence";

export const CustomerProfileHeaderClientHook = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const { customerProfileReducer, directNumber } = props;
    const { customerProfile } = customerProfileReducer || {};
    const statusLine = customerProfile?.status?.subStatus;
    const { profile } = customerProfile || {};
    const {
        name,
        last_name,
        mothers_last_name,
        carrier_name,
        imsi,
        imei,
        icc_number,
        activated_at,
    } = profile || {};
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (profile !== undefined) setIsLoading(false);
    }, [profile]);

    const navigate = useNavigate();

    useEffect(() => {
        navigate(<Modal />, {
            state: { name, last_name, mothers_last_name, directNumber },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    useEffect(() => {
        if (directNumber !== undefined) {
            props.getCustomerProfile({
                directNumber: directNumber,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [directNumber]);

    const labelSimType =
        customerProfileReducer?.customerProfile?.profile
            ?.mobile_subscriber_sim_type_id;

    const seeLineStatus = () => {
        switch (statusLine?.toUpperCase()) {
            case "ACTIVE":
                return <p className="line-status">Línea activa</p>;
            case "IDLE":
                return <p className="line-status-Idle">Inactivo</p>;
            case "AOV":
                return (
                    <p className="line-status">
                        Activo con producto facturable
                    </p>
                );
            case "ACT":
                return (
                    <p className="line-status">
                        Activo sin producto facturable
                    </p>
                );
            case "SUP":
                return <p className="line-status-Suspendida">Suspendido</p>;
            case "BAR":
                return <p className="line-status-Barring">Barring</p>;

            case "PRE":
                return (
                    <p className="line-status-Predeactivate">Predesactivo</p>
                );

            case "DAC":
                return <p className="line-status-Suspendida">Desactivado</p>;

            case "PREACT":
                return <p className="line-status-Predeactivate">Pre activo </p>;

            default:
                return (
                    <h1 className="line-status">{statusLine?.toUpperCase()}</h1>
                );
        }
    };

    return {
        seeLineStatus,
        labelSimType,
        handleExpandClick,
        name,
        last_name,
        mothers_last_name,
        expanded,
        isLoading,
        directNumber,
        carrier_name,
        activated_at,
        imsi,
        imei,
        icc_number,
    };
};
