import axiosTokenInstance from "../../../services/api/backoffice";

import { CREATE_INCIDENCE, CREATE_INCIDENCE_ERROR } from "_models/redux/types";

// Action function
export const postCreateIncidence =
    (params = undefined) =>
    async (dispatch) => {
        // Endpoint: Try & Catch
        try {
            const response = await axiosTokenInstance.post(`/api/`, params);

            const { data } = response || {};

            dispatch({
                type: CREATE_INCIDENCE,
                payload: data || {},
            });
        } catch (e) {
            dispatch({
                type: CREATE_INCIDENCE_ERROR,
                payload: e || {},
            });
        }
    };
