import {
    CLEAN_GENERATE_INVOICES,
    CLEAN_GENERATE_INVOICES_ERROR,
} from "_models/redux/types";

export const cleanGenerateInvoices = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAN_GENERATE_INVOICES,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: CLEAN_GENERATE_INVOICES_ERROR,
            payload: [],
        });
    }
};
