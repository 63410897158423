import { Grid } from "@mui/material";

const LinksHeader = () => {
    return (
        <Grid container className="page-header">
            <Grid item xs={12}>
                <Grid container direction={"row"}>
                    <Grid item xs={4} textAlign={"start"}>
                        <h3>Links pendientes</h3>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LinksHeader;
