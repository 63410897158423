import {
    CLEAN_HANDLE_STORE_MODERATION,
    CLEAN_HANDLE_STORE_MODERATION_ERROR,
} from "_models/redux/types";

export const handleStoreModerationClean = () => async (dispatch) => {
    try {
        dispatch({
            payload: undefined,
            type: CLEAN_HANDLE_STORE_MODERATION,
        });
    } catch {
        dispatch({
            payload: undefined,
            type: CLEAN_HANDLE_STORE_MODERATION_ERROR,
        });
    }
};
