import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function TableComponent(props) {
    const { columns, rowsComponent } = props || {};

    return (
        <TableContainer component={Paper}>
            <Table
                stickyHeader
                sx={{ minWidth: 250 }}
                aria-label="simple table"
            >
                <TableHead>
                    <TableRow>
                        {columns?.map((element, index) => (
                            <TableCell key={index} align={element.headerAlign}>
                                {element.headerName}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>{rowsComponent}</TableBody>
            </Table>
        </TableContainer>
    );
}
