// Import: Middleware Action & Custom Types
import { CHANGE_NIR_CLEAN, CHANGE_NIR_CLEAN_ERROR } from "_models/redux/types";

// Action function
export const cleanChangeNir = () => async (dispatch) => {
    // Endpoint: Try & Catch

    try {
        dispatch({
            type: CHANGE_NIR_CLEAN,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: CHANGE_NIR_CLEAN_ERROR,
            payload: [],
        });
    }
};
