import React from "react";
import { connect } from "react-redux";
import {
    Avatar,
    Grid,
    TextareaAutosize,
    Stack,
    CircularProgress,
} from "@mui/material";
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment";
import { postCommentsTikets } from "_models/redux/request-comments-tickets/action";
import { listcommentsTikets } from "_models/redux/request-list-comments/action";
import { CommentTicketsHooks } from "../hooks/commentTicketsHooks";

const CommentTickets = (props) => {
    const {
        registerOptions,
        handleSubmit,
        errors,
        cancelData,
        onSubmit,
        register,
        message,
        setMessage,
        comment,
        ticketId,
        isLoading,
    } = CommentTicketsHooks(props);

    return (
        <Grid container className="comments">
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2>Comentarios y eventos del ticket</h2>
                <Grid item xs={12} className="comment-border">
                    <TextareaAutosize
                        {...register("message", registerOptions.message)}
                        minRows={1}
                        aria-label=""
                        placeholder="Agrega un comentario"
                        className="comment-box"
                        value={message}
                        onChange={(event) => {
                            setMessage(event.target.value);
                        }}
                    />
                </Grid>
                <span>
                    {errors?.message && (
                        <p className="required-incidence">
                            {" "}
                            {errors.message.message}
                        </p>
                    )}
                </span>
                <Stack direction="row" justifyContent="end" className="m-t-10">
                    <button onClick={cancelData} id="cancelBtn">
                        Cancelar
                    </button>
                    <Button themeColor={"primary"} type={"submit"}>
                        Guardar
                    </Button>
                </Stack>
            </form>
            <Grid item xs={12} className={"list-box m-t-20"}>
                {isLoading ? (
                    <CircularProgress />
                ) : comment.length > 0 ? (
                    comment.map((comment) => {
                        const viewName = comment?.name;
                        const viewLastname = comment?.last_name;
                        const created = moment
                            .unix(comment.created)
                            .format("DD/MM/YYYY");
                        return (
                            <Grid
                                container
                                className="row"
                                key={comment.id}
                                display={"flex"}
                            >
                                {comment.format_type !== "PLAIN_MESSAGE" ? (
                                    <Grid
                                        item
                                        xs={10}
                                        textAlign={"start"}
                                        display={"block"}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            display={"flex"}
                                            alignItems={"center"}
                                        >
                                            <Avatar className={"avatar"}>
                                                {viewName?.charAt(0) +
                                                    viewLastname?.charAt(0)}
                                            </Avatar>

                                            <p>
                                                <b className="user">
                                                    {viewName}
                                                </b>
                                            </p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div>
                                                <p>
                                                    {comment.message} {"  "}{" "}
                                                    <b> (#{ticketId})</b>{" "}
                                                </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid
                                        item
                                        xs={10}
                                        textAlign={"start"}
                                        display={"block"}
                                    >
                                        <p>
                                            {comment.data?.message}
                                            <b> (#{ticketId})</b>{" "}
                                        </p>
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={2}
                                    display={"flex"}
                                    justifyContent={"flex-end"}
                                    textAlign={"right"}
                                    className="events-date"
                                >
                                    <p>{created}</p>
                                </Grid>
                            </Grid>
                        );
                    })
                ) : (
                    <p>No hay comentarios y eventos del ticket</p>
                )}
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    commentTikets: state.commentTikets,
    listCommentTickets: state.listCommentTickets,
});
const mapDispatchToProps = {
    postCommentsTikets,
    listcommentsTikets,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentTickets);
