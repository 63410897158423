import axiosTokenInstance from "services/api/backoffice";
import {
    IPB_DIRECTORY_MODERATION_LISTING,
    IPB_DIRECTORY_MODERATION_LISTING_ERROR,
} from "_models/redux/types";

// Action function

export const ipbDirectoryModerationListingAction =
    (page = 1, size = 10, filters = {}) =>
    async (dispatch) => {
        try {
            const params = { page, size, ...filters };
            const response = await axiosTokenInstance.get(
                `/api/ipb/directory-stores/moderations/pendings?`,
                { params }
            );

            const { data, status } = response || {};

            dispatch({
                type: IPB_DIRECTORY_MODERATION_LISTING,
                payload: data || {},
                status: status || {},
                method: "get",
            });
        } catch (e) {
            dispatch({
                type: IPB_DIRECTORY_MODERATION_LISTING_ERROR,
                payload: e || {},
            });
        }
    };
