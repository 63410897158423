// TransactionDetailsDialog.js
import React from "react";
import { CircularProgress } from "@mui/material";
import CustomDialog from "widgets/component/CustomDialog";
import DataTableDetailOrder from "widgets/customDialogComponentDetails/componentDialog";
import { CircularProgressStyle } from "../style/style";

const TransactionDetailsDialog = ({
    open,
    onClose,
    loading,
    detailTransactionsOpenpay,
    handleOpenPopUp,
    setOpenActionFraud,
    setIdReverso,
    setActiveAction,
    setShouldReturnData,
    activeAction,
    shouldReturnData,
}) => (
    <CustomDialog
        fullWidth
        title={
            detailTransactionsOpenpay?.response?.payment_info === null
                ? "No hay datos"
                : "Transacción openpay"
        }
        content={
            loading ? (
                <CircularProgress sx={CircularProgressStyle.CircularProgress} />
            ) : (
                <DataTableDetailOrder
                    type="detailsOpenPay"
                    handleOpenPopUp={handleOpenPopUp}
                    setOpenActionFraud={setOpenActionFraud}
                    response={detailTransactionsOpenpay?.response}
                    idTransaction={
                        detailTransactionsOpenpay?.response?.transaction_type
                            ?.transaction_id
                    }
                    onClose={onClose}
                    setIdReverso={setIdReverso}
                    setActiveAction={setActiveAction}
                    setShouldReturnData={setShouldReturnData}
                    activeAction={activeAction}
                    shouldReturnData={shouldReturnData}
                />
            )
        }
        open={open}
        onClose={onClose}
        maxWidth="md"
        extraButtons={[
            {
                label: "Aceptar",
                variant: "contained",
                onClick: onClose,
                buttonColor: "purple",
                textColor: "white",
            },
        ]}
    />
);

export default TransactionDetailsDialog;
