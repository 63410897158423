import React from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { datagridStyles } from "../style/datagridStyle";
import CustomNoRowsOverlay from "widgets/datagrid/CustomNoRowsOverlay";
import CustomLinearProgress from "widgets/datagrid/CustomLinearProgress";

const DataGridComponent = ({
    columns,
    rows,
    datagridPageSize,
    rowCount,
    datagridPage,
    loadingDatagrid,
    changePage,
    changePageSize,
    getRowClassName,
}) => (
    <Box sx={{ height: "100%", maxWidth: "100%" }}>
        <DataGrid
            getRowClassName={getRowClassName}
            columns={columns}
            rows={rows}
            autoHeight={true}
            pagination
            pageSize={datagridPageSize}
            rowCount={rowCount}
            paginationMode="server"
            onPageChange={(newPage) => changePage(newPage)}
            onPageSizeChange={(newPageSize) => changePageSize(newPageSize)}
            rowsPerPageOptions={[50, 75, 100]}
            page={datagridPage}
            loading={loadingDatagrid}
            disableColumnMenu
            disableSelectionOnClick={true}
            checkboxSelection={false}
            overflow={true}
            sx={datagridStyles}
            components={{
                NoRowsOverlay: CustomNoRowsOverlay,
                NoResultsOverlay: CustomNoRowsOverlay,
                LoadingOverlay: CustomLinearProgress,
            }}
        />
    </Box>
);

export default DataGridComponent;
