import React from "react";
import { connect } from "react-redux";
import InvoiceEditForm from "modules/invoice-dit-page/components/InvoiceEditPage";
import { postEditLegalData } from "_models/redux/legal-Data-edit/action";
import { cleanEditLegalData } from "_models/redux/legal-Data-edit/clean";

const InvoiceEditPage = ({
    postEditLegalData,
    cleanEditLegalData,
    responseEditLegalData,
}) => {
    return (
        <div>
            <h1></h1>
            <InvoiceEditForm
                postEditLegalData={postEditLegalData}
                cleanEditLegalData={cleanEditLegalData}
                responseEditLegalData={responseEditLegalData}
            />
        </div>
    );
};
const mapStateToProps = (state) => ({
    responseEditLegalData: state?.responseEditLegalData,
});
const mapDispatchToProps = {
    postEditLegalData,
    cleanEditLegalData,
};
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceEditPage);
