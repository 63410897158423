import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { resetPasswordStatusAction } from "_models/redux/reset-password/statusAction";

const ResetPasswordValidation = (props) => {
    const params = useParams();
    const history = useNavigate();

    const [urlKey, setUrlKey] = useState("");
    const [urlToken, setUrlToken] = useState("");

    const { resetPasswordStatus, resetPasswordStatusAction } = props;

    useEffect(() => {
        const { token, key } = params || {
            token: undefined,
            key: undefined,
        };

        setUrlKey(key);
        setUrlToken(token);
        resetPasswordStatusAction({ token });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (typeof resetPasswordStatus.resetPasswordStatus === "undefined") {
            return false;
        }

        const { message, response } =
            resetPasswordStatus.resetPasswordStatus || {
                message: undefined,
                response: undefined,
                status: undefined,
            };

        const responseStatus =
            typeof response !== "undefined" ? response.status || "" : "";

        if (responseStatus === 404) {
            history("/reset-password-expired");
        } else if (message === "ok") {
            const redirectUrl =
                "/reset-password-success/" + urlToken + "/" + urlKey;
            history(redirectUrl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetPasswordStatus]);

    return <span></span>;
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    resetPasswordStatus: state.resetPasswordStatus,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    resetPasswordStatusAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordValidation);
